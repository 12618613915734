import piwa from 'piwa';

import type { Ref, MaybeRef } from 'vue';
import type { FetchOptions } from 'ofetch';
import type { HTTPMethod } from 'h3';

type UseApiFetchMethod<Method extends UseApiHTTPMethods> = <
  Path extends keyof ApiRoutes[Method],
>(
  ...args: UseApiArgs<Method, Path>
) => Promise<UseApiResponse<ApiResponseType<Method, Path>>>;

export interface UseApiReturn {
  /**
   * Fetch the api using GET method
   */
  get: UseApiFetchMethod<'GET'>;
  /**
   * Fetch the api using POST method
   */
  post: UseApiFetchMethod<'POST'>;
  /**
   * Fetch the api using PATCH method
   */
  patch: UseApiFetchMethod<'PATCH'>;
  /**
   * Fetch the api using PUT method
   */
  put: UseApiFetchMethod<'PUT'>;
  /**
   * Fetch the api using DELETE method
   */
  del: UseApiFetchMethod<'DELETE'>;
}

/**
 * High-level wrapper of $fetch
 */
export function useApi(): UseApiReturn {
  async function fetchApi<
    Method extends UseApiHTTPMethods,
    RoutePath extends keyof ApiRoutes[Method],
    ResponseType = ApiRoutes[Method][RoutePath],
  >(
    method: Method,
    routePath: RoutePath,
    fetchOptions: FetchOptions = {},
    fetchResponse: FetchResponse<ResponseType>
  ): Promise<void> {
    const query = computed(() => unref(fetchOptions?.query));

    async function _fetchApi(): Promise<void> {
      fetchResponse.loading.value = true;

      const { data: fetchData, error } = await piwa<
        CwApiResponse<ResponseType>,
        CwApiError
      >(
        $fetch<CwApiResponse<ResponseType>>(String(routePath), {
          ...fetchOptions,
          query: query.value,
          method,
          headers: process.server
            ? (useRequestHeaders(['cookie']) as Record<string, string>)
            : {},
          baseURL: '/api',
        })
      );

      if (!error) {
        fetchResponse.data.value = fetchData.data;
        fetchResponse.error.value = null;
        fetchResponse.statusCode.value = fetchData.statusCode;
        fetchResponse.message.value = fetchData.message ?? '';
      } else {
        const errorData = error.data;
        fetchResponse.data.value = null;
        fetchResponse.error.value = errorData?.data;
        fetchResponse.statusCode.value = error.statusCode;
        fetchResponse.message.value = error.message ?? '';
      }

      fetchResponse.loading.value = false;
    }

    await _fetchApi();
    watch(query, _fetchApi);
  }

  async function fetchAndReturn<T>(
    fetchApiFn: (fetchResponse: FetchResponse<T>) => Promise<void>,
    lazy = false,
    immediate = true
  ): Promise<UseApiResponse<T>> {
    const fetchResponse: FetchResponse<T> = {
      loading: ref(false),
      data: ref(),
      statusCode: ref(null),
      message: ref(null),
      error: ref(null),
    };

    if (immediate) {
      lazy ? fetchApiFn(fetchResponse) : await fetchApiFn(fetchResponse);
    }

    return {
      ...fetchResponse,
      refresh: () => fetchApiFn(fetchResponse),
    };
  }

  async function get<RoutePath extends keyof ApiRoutes['GET']>(
    ...args: UseApiArgs<'GET', RoutePath>
  ): Promise<UseApiResponse<ApiResponseType<'GET', RoutePath>>> {
    const [routePath, options] = args;
    const { lazy = false, immediate = true, ...fetchOptions } = options ?? {};

    return fetchAndReturn(
      fetchResponse => fetchApi('GET', routePath, fetchOptions, fetchResponse),
      lazy,
      immediate
    );
  }

  async function post<RoutePath extends keyof ApiRoutes['POST']>(
    ...args: UseApiArgs<'POST', RoutePath>
  ): Promise<UseApiResponse<ApiResponseType<'POST', RoutePath>>> {
    const [routePath, options] = args;
    const { lazy = false, immediate = true, ...fetchOptions } = options ?? {};
    return fetchAndReturn(
      fetchResponse => fetchApi('POST', routePath, fetchOptions, fetchResponse),
      lazy,
      immediate
    );
  }

  async function patch<RoutePath extends keyof ApiRoutes['PATCH']>(
    ...args: UseApiArgs<'PATCH', RoutePath>
  ): Promise<UseApiResponse<ApiResponseType<'PATCH', RoutePath>>> {
    const [routePath, options] = args;
    const { lazy = false, immediate = true, ...fetchOptions } = options ?? {};
    return fetchAndReturn(
      fetchResponse =>
        fetchApi('PATCH', routePath, fetchOptions, fetchResponse),
      lazy,
      immediate
    );
  }

  async function put<RoutePath extends keyof ApiRoutes['PUT']>(
    ...args: UseApiArgs<'PUT', RoutePath>
  ): Promise<UseApiResponse<ApiResponseType<'PUT', RoutePath>>> {
    const [routePath, options] = args;
    const { lazy = false, immediate = true, ...fetchOptions } = options ?? {};
    return fetchAndReturn(
      fetchResponse => fetchApi('PUT', routePath, fetchOptions, fetchResponse),
      lazy,
      immediate
    );
  }

  async function del<RoutePath extends keyof ApiRoutes['DELETE']>(
    ...args: UseApiArgs<'DELETE', RoutePath>
  ): Promise<UseApiResponse<ApiResponseType<'DELETE', RoutePath>>> {
    const [routePath, options] = args;
    const { lazy = false, immediate = true, ...fetchOptions } = options ?? {};
    return fetchAndReturn(
      fetchResponse =>
        fetchApi('DELETE', routePath, fetchOptions, fetchResponse),
      lazy,
      immediate
    );
  }

  return {
    get,
    post,
    patch,
    put,
    del,
  };
}

type UseApiHTTPMethods = Extract<
  HTTPMethod,
  'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
>;

type UseApiOptions<
  Method extends UseApiHTTPMethods,
  RoutePath extends keyof ApiRoutes[Method],
> = OmitNever<
  Omit<FetchOptions, 'method' | 'headers' | 'baseUrl' | 'query'> & {
    body: Method extends Extract<
      UseApiHTTPMethods,
      'POST' | 'PATCH' | 'PUT' | 'DELETE'
    >
      ? ApiBodyType<Method, RoutePath> extends object
        ? ApiBodyType<Method, RoutePath>
        : never
      : never;
    query?: MaybeRef<ApiQueryType<Method, RoutePath>>;
    lazy?: boolean;
    immediate?: boolean;
  }
>;

type UseApiArgs<
  Method extends UseApiHTTPMethods,
  RoutePath extends keyof ApiRoutes[Method],
> =
  Partial<UseApiOptions<Method, RoutePath>> extends UseApiOptions<
    Method,
    RoutePath
  >
    ? [routePath: RoutePath, options?: UseApiOptions<Method, RoutePath>]
    : [routePath: RoutePath, options: UseApiOptions<Method, RoutePath>];

type ApiBodyType<
  Method extends UseApiHTTPMethods,
  RoutePath extends keyof ApiRoutes[Method],
> = 'body' extends keyof ApiRoutes[Method][RoutePath]
  ? ApiRoutes[Method][RoutePath]['body']
  : void;

type ApiQueryType<
  Method extends UseApiHTTPMethods,
  RoutePath extends keyof ApiRoutes[Method],
> = 'query' extends keyof ApiRoutes[Method][RoutePath]
  ? ApiRoutes[Method][RoutePath]['query']
  : void;

type ApiResponseType<
  Method extends UseApiHTTPMethods,
  RoutePath extends keyof ApiRoutes[Method],
> = 'response' extends keyof ApiRoutes[Method][RoutePath]
  ? ApiRoutes[Method][RoutePath]['response']
  : void;

type UseApiResponse<T> = {
  statusCode: Ref<number | null>;
  message: Ref<string | null>;
  loading: Ref<boolean>;
  refresh: () => Promise<void>;
  data: Ref<CwApiResponse<T>['data']>;
  error: Ref<CwApiErrorData['data'] | null | undefined>;
};

type FetchResponse<T> = Pick<
  UseApiResponse<T>,
  'data' | 'statusCode' | 'message' | 'loading' | 'error'
>;
