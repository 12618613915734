import { createCwError } from '@/lib/helpers/errors';
import { createFlowStep } from '@/lib/helpers/flow';

export const createPass = createFlowStep<BenefitFlowPayload>(
  'createPass',
  async payload => {
    const api = useApi();

    const { data, error } = await api.post('/user/user-offers/pass', {
      body: {
        id: payload.userOffer.id,
      },
    });

    if (error.value) {
      throw createCwError(error.value?.errorCode);
    }

    return {
      ...payload,
      userOffer: {
        ...payload.userOffer,
        ...data.value?.benefit,
      },
    };
  }
);

export const syncPassStatus = createFlowStep<BenefitFlowPayload>(
  'syncPassStatus',
  async payload => {
    const { is3DsRequired } = payload.userOffer.payment;

    if (!is3DsRequired) return payload;

    const api = useApi();

    const { error: passError } = await api.post(
      '/user/user-offers/regularize',
      {
        body: {
          id: payload.userOffer.id ?? payload.userOffer.offerId,
        },
      }
    );

    if (passError.value) throw createCwError(passError.value.errorCode);

    return payload;
  }
);
