import { getCmsComponentData } from '@/lib/utils';

export function mapCmsInternalShowcaseDataToInternalShowcaseSettings(
  internalShowcaseRawData:
    | StrapiResponseData<InternalShowcaseContentType>
    | undefined
): InternalShowcaseSettings {
  const cmsData = internalShowcaseRawData?.attributes;

  return {
    features: getCmsComponentData(cmsData?.features),
  };
}
