import { hexToRgbString } from '@fifteen/shared-lib';

import { OTPMode } from '@/models/enums';
import { defaultTheme } from '@/config';
import { mergeObjects } from '@/lib/utils/objects';
import { getDefaultFont } from '@/lib/helpers/fonts';
import { getCmsComponentData } from '@/lib/utils';

import type { Theme } from '@/config';

export function mapCmsInternalSettingsDataToInternalSettings(
  internalSettingsRawData:
    | StrapiResponseData<InternalSettingsContentType>
    | undefined
): InternalSettings {
  const cmsData = internalSettingsRawData?.attributes;

  const hexColors: Partial<Theme> = {
    colorNeutralLight5: cmsData?.colors?.neutral?.light_5,
    colorNeutralLight4: cmsData?.colors?.neutral?.light_4,
    colorNeutralLight3: cmsData?.colors?.neutral?.light_3,
    colorNeutralLight2: cmsData?.colors?.neutral?.light_2,
    colorNeutralLight1: cmsData?.colors?.neutral?.light_1,
    colorNeutral: cmsData?.colors?.neutral?.base,
    colorNeutralDark1: cmsData?.colors?.neutral?.dark_1,
    colorNeutralDark2: cmsData?.colors?.neutral?.dark_2,
    colorNeutralDark3: cmsData?.colors?.neutral?.dark_3,
    colorNeutralDark4: cmsData?.colors?.neutral?.dark_4,
    colorNeutralDark5: cmsData?.colors?.neutral?.dark_5,
    colorPrimaryLight2: cmsData?.colors?.primary?.light_2,
    colorPrimaryLight1: cmsData?.colors?.primary?.light_1,
    colorPrimary: cmsData?.colors?.primary?.base,
    colorPrimaryDark1: cmsData?.colors?.primary?.dark_1,
    colorPrimaryDark2: cmsData?.colors?.primary?.dark_2,
    colorSecondaryLight2: cmsData?.colors?.secondary?.light_2,
    colorSecondaryLight1: cmsData?.colors?.secondary?.light_1,
    colorSecondary: cmsData?.colors?.secondary?.base,
    colorSecondaryDark1: cmsData?.colors?.secondary?.dark_1,
    colorSecondaryDark2: cmsData?.colors?.secondary?.dark_2,
    colorSuccessLight2: cmsData?.colors?.success?.light_2,
    colorSuccessLight1: cmsData?.colors?.success?.light_1,
    colorSuccess: cmsData?.colors?.success?.base,
    colorSuccessDark1: cmsData?.colors?.success?.dark_1,
    colorSuccessDark2: cmsData?.colors?.success?.dark_2,
    colorInfoLight2: cmsData?.colors?.info?.light_2,
    colorInfoLight1: cmsData?.colors?.info?.light_1,
    colorInfo: cmsData?.colors?.info?.base,
    colorInfoDark1: cmsData?.colors?.info?.dark_1,
    colorInfoDark2: cmsData?.colors?.info?.dark_2,
    colorWarningLight2: cmsData?.colors?.warning?.light_2,
    colorWarningLight1: cmsData?.colors?.warning?.light_1,
    colorWarning: cmsData?.colors?.warning?.base,
    colorWarningDark1: cmsData?.colors?.warning?.dark_1,
    colorWarningDark2: cmsData?.colors?.warning?.dark_2,
    colorDangerLight2: cmsData?.colors?.danger?.light_2,
    colorDangerLight1: cmsData?.colors?.danger?.light_1,
    colorDanger: cmsData?.colors?.danger?.base,
    colorDangerDark1: cmsData?.colors?.danger?.dark_1,
    colorDangerDark2: cmsData?.colors?.danger?.dark_2,
  };

  const rgbColors: Partial<Theme> = Object.entries(hexColors ?? []).reduce(
    (result, [colorKey, colorValue]) => {
      colorValue &&
        Object.assign(result, {
          [`${colorKey}Rgb`]: hexToRgbString(colorValue),
        });
      return result;
    },
    {}
  );

  const colors: Partial<Theme> = {
    ...hexColors,
    ...rgbColors,
  };

  const fontFamilies: Pick<
    InternalSettingsContentType,
    'font_primary' | 'font_secondary'
  > = {
    font_primary: cmsData?.font_primary,
    font_secondary: cmsData?.font_secondary,
  };

  const mapFontStyleToFont = fontMapper(cmsData?.font_styles, fontFamilies);

  // Fallback font Family
  const fontFamilyPrimary = cmsData?.font_primary?.name;
  const fontFamilySecondary = cmsData?.font_secondary?.name;

  const fonts: Partial<Theme> = {
    heading1FontFamily:
      mapFontStyleToFont('heading-1').fontFamily ?? fontFamilyPrimary,
    heading1FontWeight: mapFontStyleToFont('heading-1').fontWeight,
    heading1FontSizeMinPx: mapFontStyleToFont('heading-1').fontSizeMinPx,
    heading1FontSizeMaxPx: mapFontStyleToFont('heading-1').fontSizeMaxPx,
    heading1LetterSpacing: mapFontStyleToFont('heading-1').letterSpacing,
    heading1LineHeightMinPx: mapFontStyleToFont('heading-1').lineHeightMinPx,
    heading1LineHeightMaxPx: mapFontStyleToFont('heading-1').lineHeightMinPx,
    heading2FontFamily:
      mapFontStyleToFont('heading-2').fontFamily ?? fontFamilyPrimary,
    heading2FontWeight: mapFontStyleToFont('heading-2').fontWeight,
    heading2FontSizeMinPx: mapFontStyleToFont('heading-2').fontSizeMinPx,
    heading2FontSizeMaxPx: mapFontStyleToFont('heading-2').fontSizeMaxPx,
    heading2LetterSpacing: mapFontStyleToFont('heading-2').letterSpacing,
    heading2LineHeightMinPx: mapFontStyleToFont('heading-2').lineHeightMinPx,
    heading2LineHeightMaxPx: mapFontStyleToFont('heading-2').lineHeightMinPx,
    heading3FontFamily:
      mapFontStyleToFont('heading-3').fontFamily ?? fontFamilyPrimary,
    heading3FontWeight: mapFontStyleToFont('heading-3').fontWeight,
    heading3FontSizeMinPx: mapFontStyleToFont('heading-3').fontSizeMinPx,
    heading3FontSizeMaxPx: mapFontStyleToFont('heading-3').fontSizeMaxPx,
    heading3LetterSpacing: mapFontStyleToFont('heading-3').letterSpacing,
    heading3LineHeightMinPx: mapFontStyleToFont('heading-3').lineHeightMinPx,
    heading3LineHeightMaxPx: mapFontStyleToFont('heading-3').lineHeightMinPx,

    heading4FontFamily:
      mapFontStyleToFont('heading-4').fontFamily ?? fontFamilyPrimary,
    heading4FontWeight: mapFontStyleToFont('heading-4').fontWeight,
    heading4FontSizeMinPx: mapFontStyleToFont('heading-4').fontSizeMinPx,
    heading4FontSizeMaxPx: mapFontStyleToFont('heading-4').fontSizeMaxPx,
    heading4LetterSpacing: mapFontStyleToFont('heading-4').letterSpacing,
    heading4LineHeightMinPx: mapFontStyleToFont('heading-4').lineHeightMinPx,
    heading4LineHeightMaxPx: mapFontStyleToFont('heading-4').lineHeightMinPx,

    heading5FontFamily:
      mapFontStyleToFont('heading-5').fontFamily ?? fontFamilyPrimary,
    heading5FontWeight: mapFontStyleToFont('heading-5').fontWeight,
    heading5FontSizeMinPx: mapFontStyleToFont('heading-5').fontSizeMinPx,
    heading5FontSizeMaxPx: mapFontStyleToFont('heading-5').fontSizeMaxPx,
    heading5LetterSpacing: mapFontStyleToFont('heading-5').letterSpacing,
    heading5LineHeightMinPx: mapFontStyleToFont('heading-5').lineHeightMinPx,
    heading5LineHeightMaxPx: mapFontStyleToFont('heading-5').lineHeightMinPx,

    heading6FontFamily:
      mapFontStyleToFont('heading-6').fontFamily ?? fontFamilyPrimary,
    heading6FontWeight: mapFontStyleToFont('heading-6').fontWeight,
    heading6FontSizeMinPx: mapFontStyleToFont('heading-6').fontSizeMinPx,
    heading6FontSizeMaxPx: mapFontStyleToFont('heading-6').fontSizeMaxPx,
    heading6LetterSpacing: mapFontStyleToFont('heading-6').letterSpacing,
    heading6LineHeightMinPx: mapFontStyleToFont('heading-6').lineHeightMinPx,
    heading6LineHeightMaxPx: mapFontStyleToFont('heading-6').lineHeightMinPx,

    body1FontFamily:
      mapFontStyleToFont('body-1').fontFamily ?? fontFamilySecondary,
    body1FontWeight: mapFontStyleToFont('body-1').fontWeight,
    body1FontSizeMinPx: mapFontStyleToFont('body-1').fontSizeMinPx,
    body1FontSizeMaxPx: mapFontStyleToFont('body-1').fontSizeMaxPx,
    body1LetterSpacing: mapFontStyleToFont('body-1').letterSpacing,
    body1LineHeightMinPx: mapFontStyleToFont('body-1').lineHeightMinPx,
    body1LineHeightMaxPx: mapFontStyleToFont('body-1').lineHeightMinPx,

    body2FontFamily:
      mapFontStyleToFont('body-2').fontFamily ?? fontFamilySecondary,
    body2FontWeight: mapFontStyleToFont('body-2').fontWeight,
    body2FontSizeMinPx: mapFontStyleToFont('body-2').fontSizeMinPx,
    body2FontSizeMaxPx: mapFontStyleToFont('body-2').fontSizeMaxPx,
    body2LetterSpacing: mapFontStyleToFont('body-2').letterSpacing,
    body2LineHeightMinPx: mapFontStyleToFont('body-2').lineHeightMinPx,
    body2LineHeightMaxPx: mapFontStyleToFont('body-2').lineHeightMinPx,

    captionFontFamily:
      mapFontStyleToFont('caption').fontFamily ?? fontFamilySecondary,
    captionFontWeight: mapFontStyleToFont('caption').fontWeight,
    captionFontSizeMinPx: mapFontStyleToFont('caption').fontSizeMinPx,
    captionFontSizeMaxPx: mapFontStyleToFont('caption').fontSizeMaxPx,
    captionLetterSpacing: mapFontStyleToFont('caption').letterSpacing,
    captionLineHeightMinPx: mapFontStyleToFont('caption').lineHeightMinPx,
    captionLineHeightMaxPx: mapFontStyleToFont('caption').lineHeightMinPx,

    buttonFontFamily:
      mapFontStyleToFont('button').fontFamily ?? fontFamilyPrimary,
    buttonFontWeight: mapFontStyleToFont('button').fontWeight,
    buttonFontSizeMinPx: mapFontStyleToFont('button').fontSizeMinPx,
    buttonFontSizeMaxPx: mapFontStyleToFont('button').fontSizeMaxPx,
    buttonLetterSpacing: mapFontStyleToFont('button').letterSpacing,
    buttonLineHeightMinPx: mapFontStyleToFont('button').lineHeightMinPx,
    buttonLineHeightMaxPx: mapFontStyleToFont('button').lineHeightMinPx,

    overlineFontFamily:
      mapFontStyleToFont('overline').fontFamily ?? fontFamilySecondary,
    overlineFontWeight: mapFontStyleToFont('overline').fontWeight,
    overlineFontSizeMinPx: mapFontStyleToFont('overline').fontSizeMinPx,
    overlineFontSizeMaxPx: mapFontStyleToFont('overline').fontSizeMaxPx,
    overlineLetterSpacing: mapFontStyleToFont('overline').letterSpacing,
    overlineLineHeightMinPx: mapFontStyleToFont('overline').lineHeightMinPx,
    overlineLineHeightMaxPx: mapFontStyleToFont('overline').lineHeightMinPx,

    subtitle1FontFamily:
      mapFontStyleToFont('subtitle-1').fontFamily ?? fontFamilySecondary,
    subtitle1FontWeight: mapFontStyleToFont('subtitle-1').fontWeight,
    subtitle1FontSizeMinPx: mapFontStyleToFont('subtitle-1').fontSizeMinPx,
    subtitle1FontSizeMaxPx: mapFontStyleToFont('subtitle-1').fontSizeMaxPx,
    subtitle1LetterSpacing: mapFontStyleToFont('subtitle-1').letterSpacing,
    subtitle1LineHeightMinPx: mapFontStyleToFont('subtitle-1').lineHeightMinPx,
    subtitle1LineHeightMaxPx: mapFontStyleToFont('subtitle-1').lineHeightMinPx,

    subtitle2FontFamily:
      mapFontStyleToFont('subtitle-2').fontFamily ?? fontFamilySecondary,
    subtitle2FontWeight: mapFontStyleToFont('subtitle-2').fontWeight,
    subtitle2FontSizeMinPx: mapFontStyleToFont('subtitle-2').fontSizeMinPx,
    subtitle2FontSizeMaxPx: mapFontStyleToFont('subtitle-2').fontSizeMaxPx,
    subtitle2LetterSpacing: mapFontStyleToFont('subtitle-2').letterSpacing,
    subtitle2LineHeightMinPx: mapFontStyleToFont('subtitle-2').lineHeightMinPx,
    subtitle2LineHeightMaxPx: mapFontStyleToFont('subtitle-2').lineHeightMinPx,
  };

  const theme: Theme = mergeObjects(mergeObjects(defaultTheme, colors), fonts);

  return {
    homepage: cmsData?.homepage ?? '/',
    sharingDynamicLink: cmsData?.sharing_dynamic_link ?? '',
    leasingDynamicLink: cmsData?.leasing_dynamic_link ?? '',
    sharingAppStoreLink: cmsData?.sharing_app_store_link ?? '',
    sharingPlayStoreLink: cmsData?.sharing_play_store_link ?? '',
    leasingAppStoreLink: cmsData?.leasing_app_store_link ?? '',
    leasingPlayStoreLink: cmsData?.leasing_play_store_link ?? '',
    modules: {
      profile: cmsData?.modules?.profile,
      showcase: cmsData?.modules?.showcase,
      embedMap: cmsData?.modules?.embed_map,
      // Enabled, except if explicitly disabled on CMS
      accountSettings: cmsData?.modules?.account_settings ?? true,
      // Enabled, except if explicitly disabled on CMS
      communicationPreferences:
        cmsData?.modules?.communication_preferences ?? true,
    },
    services: {
      leasing: cmsData?.services?.leasing ?? false,
      sharing: cmsData?.services?.sharing ?? false,
      customerService: cmsData?.services?.customer_service ?? false,
    },
    features: {
      referral: cmsData?.features?.referral ?? false,
    },
    theme: theme,
    stationsMapConfig: {
      center: cmsData?.stations_map_config?.map_center?.center ?? undefined,
      initialFitBounds:
        cmsData?.stations_map_config?.initial_fit_bounds ?? true,
      fitBoundsPadding: cmsData?.stations_map_config?.fit_bounds_padding ?? 48,
      minZoom: cmsData?.stations_map_config?.min_zoom ?? 0,
      maxZoom: cmsData?.stations_map_config?.max_zoom ?? 20,
      initialZoom: cmsData?.stations_map_config?.initial_zoom ?? 13,
    },
    dynamicLinksConfigs: {
      sharing: dynamicLinkConfigMapper(cmsData?.dynamic_links_configs?.sharing),
      leasing: dynamicLinkConfigMapper(cmsData?.dynamic_links_configs?.leasing),
    },
    otpMode: cmsData?.otp_mode ?? OTPMode.Phone,
    identityFields: (cmsData?.identity_fields ?? []).map(getCmsComponentData),
    optInFields: (cmsData?.opt_in_fields ?? []).map(getCmsComponentData),
    countryCode: cmsData?.country_code ?? 'FR',
  };
}

function dynamicLinkConfigMapper(
  dynamicLinkConfig?: InternalSettingsDynamicLinkConfig
): DynamicLinkConfig {
  return {
    baseUrl: dynamicLinkConfig?.base_url ?? '',
    deepLinkBaseUrl: dynamicLinkConfig?.deep_link_base_url ?? '',
    iosAppId: dynamicLinkConfig?.ios_app_id ?? '',
    iosStoreId: dynamicLinkConfig?.ios_store_id ?? '',
    androidAppId: dynamicLinkConfig?.android_app_id ?? '',
  };
}

interface Font {
  fontFamily: string;
  fontWeight: string;
  fontSizeMinPx: string;
  fontSizeMaxPx: string;
  letterSpacing: string;
  lineHeightMinPx: string;
  lineHeightMaxPx: string;
}

export type FontStyle = Exclude<InternalSettingsFontStyle['style'], undefined>;
type FontStyleProperty = Exclude<
  InternalSettingsFontStyleProperty['name'],
  undefined
>;

function fontMapper(
  fontStyles?: InternalSettingsContentType['font_styles'],
  fonts?: Pick<InternalSettingsContentType, 'font_primary' | 'font_secondary'>
): (fontStyle: FontStyle) => Font {
  const fontsConfig: Partial<Record<FontStyle, Font>> = {};

  function mapFontStyleToFont(fontStyle: FontStyle): Font {
    if (fontsConfig[fontStyle]) {
      return fontsConfig[fontStyle] as Font;
    }

    const fontStyleConfig = (fontStyles ?? []).find(
      _fontStyle => _fontStyle.style === fontStyle
    );
    const fontStyleConfigProperties: Partial<
      Record<FontStyleProperty, string>
    > = (fontStyleConfig?.properties ?? []).reduce<
      Partial<Record<FontStyleProperty, string>>
    >((acc, property) => {
      if (property.name) {
        acc[property.name] = String(property.value);
      }
      return acc;
    }, {});

    fontsConfig[fontStyle] = {
      fontFamily:
        fonts?.[fontStyleConfig?.family ?? getDefaultFont(fontStyle)]?.name ??
        '',
      fontWeight: String(fontStyleConfig?.weight ?? ''),
      fontSizeMinPx: String(fontStyleConfigProperties.font_size_min_px ?? ''),
      fontSizeMaxPx: String(fontStyleConfigProperties?.font_size_max_px ?? ''),
      letterSpacing: String(fontStyleConfigProperties?.letter_spacing ?? ''),
      lineHeightMinPx: String(
        fontStyleConfigProperties?.line_height_min_px ?? ''
      ),
      lineHeightMaxPx: String(
        fontStyleConfigProperties?.line_height_max_px ?? ''
      ),
    };

    return fontsConfig[fontStyle] as Font;
  }

  return mapFontStyleToFont;
}
