export function mapCmsShowcaseDataToShowcaseSettings(
  showcaseRawData: StrapiResponseData<ShowcaseContentType> | undefined
): ShowcaseSettings {
  const cmsData = showcaseRawData?.attributes;

  return {
    sharingFaqItemIds: (cmsData?.help?.sharing_faq_items ?? [])
      .map(item => item.article_id ?? '')
      .filter(articleId => articleId !== ''),
    leasingFaqItemIds: (cmsData?.help?.leasing_faq_items ?? [])
      .map(item => item.article_id ?? '')
      .filter(articleId => articleId !== ''),
  };
}
