import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { watchImmediate } from '@vueuse/core';

import { Ampli, ampli } from '@/src/ampli';

import type { IdentifyProperties } from '@/src/ampli';
import type { WatchStopHandle } from 'vue';
import type { AxeptioCookiesChoices } from '@/types/axeptio';

interface UseUserTrackingReturn {
  /**
   * Event tracking instance
   */
  track: Ampli;
}

const userProperties = ref<IdentifyProperties>({});
const isInit = ref(false);

export function useUserTracking(
  choices?: Ref<AxeptioCookiesChoices>
): UseUserTrackingReturn {
  const config = useRuntimeConfig();
  const isConsented = computed(() => choices?.value?.amplitude);

  // Set up Pirsch tracking
  if (config.public.pirschTrackingCode) {
    useHead({
      script: [
        {
          src: 'https://api.pirsch.io/pa.js',
          defer: true,
          id: 'pianjs',
          'data-code': config.public.pirschTrackingCode,
        },
      ],
    });
  }

  // Set up Amplitude tracking
  onMounted(() => {
    if (!isInit.value) {
      watchImmediate(isConsented, () => {
        if (!ampli.isLoaded) {
          load();
        }

        if (!isConsented.value) {
          ampli.client.setOptOut(true);
          return;
        }

        const sessionReplayTracking = sessionReplayPlugin({
          sampleRate: 1,
        });
        ampli.client.add(sessionReplayTracking);

        ampli.client.setOptOut(false);
      });

      isInit.value = true;
    }

    function load(disabled = false): void {
      ampli.load({
        environment: 'fifteencustomerwebsite',
        disabled,
        client: {
          configuration: {
            serverZone: 'EU',
            defaultTracking: !disabled,
          },
        },
      });
    }

    if (choices?.value?.amplitude) {
      useUserIdentify();
    }
  });

  return {
    track: ampli,
  };
}

const userId = ref<string>();

/**
 * Set dynamically Amplitude user properties
 */
function useUserIdentify(): void {
  function syncProperty<I extends keyof IdentifyProperties>(
    property: I,
    value: Ref<IdentifyProperties[I]>
  ): { stop: WatchStopHandle } | void {
    if (property in userProperties.value) {
      return;
    }
    watchImmediate(value, newValue => {
      userProperties.value[property] = newValue;
      if (userId.value) {
        ampli.identify(userId.value, userProperties.value);
      }
    });
  }

  function setUserId(id: string | undefined): void {
    ampli.identify(id);
    userId.value = id;
  }
  const clientStore = useClientStore();
  const { clientState } = storeToRefs(clientStore);

  const userStore = storeToRefs(useUserStore());

  // We add a toRef here to ensure the watch source is not undefined (which happens sometimes with user for unknown reasons)
  watchImmediate(userStore.user, user => setUserId(user?.id));

  syncProperty(
    'serviceName',
    toRef(() => clientState.value.name)
  );

  const { locale } = useI18n();
  syncProperty('uiLocale', locale);
}
