<template lang="pug">
.FcwAlert(
  :style="style"
  :class="classes"
)
  FIcon(
    :name="props.icon"
    :color="iconColor"
    size="28px"
  )
  span.FcwAlert__text {{ props.text }}
  FButton.FcwAlert__closeButton(
    v-if="props.closable"
    icon
    ghost
    size="tiny"
    :color="iconColor"
    @click="hideAlert"
  )
    FIcon(name="close")
</template>

<style lang="stylus">
.FcwAlert
  position fixed
  display flex
  justify-content center
  align-items center
  min-height rem(56)
  padding rem(12) rem(16)
  width min-content
  gap rem(12)
  text-wrap nowrap
  margin auto
  max-width 'calc(100% - %s)' % rem(32)
  background var(--FcwAlert--backgroundColor)
  animation fromTop var(--FcwAlert--animationDuration) forwards
  opacity 0
  left 50%
  translate -50% 0
  top rem(12)
  border-radius rem(16)
  z-index 20

  +media-down('xs')
    text-wrap wrap
    justify-content space-between
    width 100%

  &--closed
    animation toTop var(--FcwAlert--animationDuration) forwards

.FcwAlert__text
  text-overflow ellipsis
  overflow hidden

@keyframes fromTop
  0%
    opacity 0
    transform translateY(-100%)

  100%
    opacity 1
    transform translateY(0)

@keyframes toTop
  0%
    opacity 1
    transform translateY(0)

  100%
    opacity 0
    transform translateY(-100%)
</style>

<script setup lang="ts">
import { getCssColor } from '@fifteen/design-system-vue';

import type { Icon, BaseColorDesignToken } from '@fifteen/design-system-vue';

export interface FcwAlertProps {
  /**
   * Text to display
   */
  text?: string;
  /**
   * Icon to display close to the text
   */
  icon?: Icon;
  /**
   * Color of the alert
   */
  type?: BaseColorDesignToken;
  /**
   * Add a button to close the alert
   */
  closable?: boolean;
  /**
   * Delay in ms after which the alert closes itself. Set to 0 to never closes the modal automatically.
   */
  duration?: number;
  /**
   * Alert visibility
   */
  isVisible?: boolean;
}

const _props = withDefaults(defineProps<FcwAlertProps>(), {
  text: '',
  icon: 'checkCircle',
  type: 'success',
  closable: true,
  duration: 5000,
  isVisible: false,
});

const props = ref<FcwAlertProps>(_props);
const eventProps = ref<FcwAlertProps>(_props);

// Reactivity on props is ensured by this watcher
watch(
  () => _props,
  () => (props.value = _props)
);
// We also sync props that are set through the event bus
watch(eventProps, () => (props.value = eventProps.value));

defineEmits<{
  (name: 'update:isVisible'): void;
}>();

const isVisible = useVModelProxy({
  props: _props,
  propName: 'isVisible',
});

// Prevent toTop animation if initial visibility if hidden
// eslint-disable-next-line vue/no-setup-props-destructure
const showAnimation = ref(_props.isVisible);
watch(isVisible, value => {
  if (value) {
    showAnimation.value = true;
  }
});

onMounted(() => {
  const bus = useEventBus<FcwAlertProps>('FcwAlert');
  bus.on((event: FcwAlertProps) => {
    eventProps.value = event;
    showAlert();
  });
});

watchImmediate(isVisible, newValue => {
  newValue &&
    props.value.duration !== 0 &&
    setTimeout(hideAlert, props.value.duration);
});

const classes = computed<VueClasses>(() => ({
  'FcwAlert--closed': !isVisible.value,
}));

const style = computed<Style>(() => ({
  '--FcwAlert--animationDuration': `${showAnimation.value ? 400 : 0}ms`,
  '--FcwAlert--backgroundColor': getCssColor(`${props.value.type}--light-2`),
}));

const iconColor = computed(() => `${props.value.type}--dark-1`);

/**
 * Show the alert
 */
function showAlert(): void {
  isVisible.value = true;
}

/**
 * Hide the alert
 */
function hideAlert(): void {
  isVisible.value = false;
}
</script>
