import { mapCmsSettingsDataToSettings } from '@/lib/cms-mappers';

/**
 * Returns settings data fetched from CMS (Strapi)
 */
export async function useCmsSettings(): UseCmsResponse<ClientSettings> {
  const { data, error, pending, refresh } = await useStrapi('settings', {
    offlineSSRFallback: true,
    watchLocale: true,
    queryParams: {
      populate: [
        'logo',
        'logo_light',
        'metropolis_logo',
        'metropolis_logo_light',
        'favicon',
        'sharing_app_device_mockup',
        'leasing_app_device_mockup',
        'social_networks',
        'seo.image',
        'help_center_links',
        'mobile_apps_overview.sharing.features',
        'mobile_apps_overview.sharing.device_mockup',
        'mobile_apps_overview.sharing.carousel_items.image',
        'mobile_apps_overview.leasing.features',
        'mobile_apps_overview.leasing.device_mockup',
        'mobile_apps_overview.leasing.carousel_items.image',
      ],
    },
  });

  const mappedData = computed(() =>
    mapCmsSettingsDataToSettings(data.value?.data)
  );

  return {
    data: mappedData,
    error,
    pending,
    refresh,
  };
}
