import { createFlow } from '@/lib/helpers/flow';
import * as flows from '@/lib/flows';

import type { CwError } from '@/lib/helpers/errors';

interface UseMonextReturn {
  /**
   * Confirm a Monext payment (3DS)
   * @param options.paymentId - Current payment ID, sent by the backend during a payment attempt
   */
  confirmPayment: (options: {
    paymentId: string;
  }) => Promise<{ error: CwError | null }>;
}

/**
 * Exposes Monext related functions
 */
export function useMonext(): UseMonextReturn {
  async function confirmPayment(options: {
    paymentId: string;
  }): Promise<{ error: CwError | null }> {
    const confirmMonextPaymentFlow =
      createFlow<MonextPaymentConfirmationFlowPayload>({
        paymentId: options.paymentId,
      });

    const { error } = await confirmMonextPaymentFlow(
      flows.getMonextChallenge,
      flows.renderMonextInvisibleChallengeIframe,
      flows.confirmAddMonextPaymentMethod,
      flows.renderMonextVisibleChallengeIframe,
      flows.finishAddMonextPaymentMethod
    );

    return { error };
  }

  return {
    confirmPayment,
  };
}
