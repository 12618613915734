import { defaultLocale as configDefaultLocale } from '@/config/locales';

export function mapCmsLocaleStatusToLocaleSettings(
  localeStatusRawData: StrapiResponseData<LocaleStatusContentType> | undefined
): LocaleSettings {
  const locales = (localeStatusRawData?.attributes.locales ?? [])
    .filter(locale => locale.isPublished)
    .map(locale => locale.code);
  const defaultLocale =
    localeStatusRawData?.attributes.default_locale ?? configDefaultLocale;

  return {
    defaultLocale,
    locales,
  };
}
