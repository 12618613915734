import resetStorePlugin from '@/store/plugins/reset-store';

export default defineNuxtPlugin({
  name: 'store-locales',
  async setup() {
    const { $pinia } = useNuxtApp();

    $pinia.use(resetStorePlugin);

    // Do not modify init order unless you know what you are doing
    await useGlobalStore($pinia).init();
    await useClientStore($pinia).initLocales();
  },
});
