import type { NavLink } from '@/components/organisms/FcwNavbar.vue';
import type { RouteLocationRaw } from 'vue-router';

export function mapCmsShowcaseNavigationToNavigation(
  navigationItems: NavigationItems
): NavLink[] {
  return (navigationItems ?? []).map(item => ({
    label: item.title,
    enabled: isItemEnabled(item),
    to: getItemRoute(item),
    submenu: mapCmsShowcaseNavigationToNavigation(item.items),
  }));
}

function isItemEnabled(item: NavigationItem): boolean {
  return (
    item.type === 'EXTERNAL' ||
    (item.type === 'INTERNAL' && item.related?.publishedAt !== null)
  );
}

function getItemRoute(item: NavigationItem): RouteLocationRaw {
  if (item.type === 'EXTERNAL') {
    return item.path;
  }

  if (item.type === 'INTERNAL' && item.related) {
    return mapContentTypeUidToRouteLocation(item.related);
  }

  return '';
}

/**
 * Maps the content type UID to a route location
 * @param relatedItem - Related item from the navigation item
 */
function mapContentTypeUidToRouteLocation(
  relatedItem: NonNullable<NavigationItem['related']>
): RouteLocationRaw {
  switch (relatedItem.__contentType) {
    case 'api::about.about':
      return { name: 'showcase-about' };
    case 'api::showcase-map.showcase-map':
      return { name: 'showcase-map' };
    case 'api::showcase-sharing.showcase-sharing':
      return { name: 'showcase-sharing' };
    case 'api::showcase-leasing.showcase-leasing':
      return { name: 'showcase-leasing' };
    case 'api::blog.blog':
      return { name: 'showcase-blog' };
    case 'api::showcase-help.showcase-help':
      return { name: 'showcase-help' };
    case 'api::showcase-home.showcase-home':
      return { name: 'showcase-home' };
    case 'api::blog-post.blog-post':
      return {
        name: 'showcase-blog-slug',
        params: { slug: relatedItem.slug ?? '' },
      };
    case 'api::dynamic-page.dynamic-page':
      return {
        name: 'showcase-slug',
        params: { slug: relatedItem.slug ?? '' },
      };
    default:
      return '';
  }
}
