<template lang="pug">
FButtonLink(
  v-if="location"
  v-bind="$props"
  :location="resolveLocalizedRoute(location)"
)
  slot
</template>

<script setup lang="ts">
import type { FButtonLinkProps } from '@fifteen/design-system-vue/router';

export type FcwButtonLinkProps = FButtonLinkProps;

defineProps<FcwButtonLinkProps>();

const { resolveLocalizedRoute } = useLocalizedRoute();
</script>
