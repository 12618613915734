import { getErrorTranslationKey } from '@/lib/helpers/errors';

import type { UseAlertOptions } from './useAlert';
import type { I18nData } from '@/modules/i18n/types';

interface UseErrorAlertOptions extends Omit<UseAlertOptions, string> {
  /**
   * Error context
   */
  context: ErrorContext;
  /**
   * Named interpolation to use variable in the `t` function
   */
  i18nData?: I18nData;
}

/**
 * Display FcwAlert component in an error context, with the error and props passed as arguments
 * @param code - Error code or error
 * @param options - Options to customize alert behavior
 */
export function useErrorAlert(
  codeOrError: ErrorPayload,
  options?: UseErrorAlertOptions
): void {
  const _codeOrError = unref(codeOrError);

  const errorCode =
    typeof _codeOrError === 'object' && 'errorCode' in _codeOrError
      ? _codeOrError.errorCode
      : _codeOrError;
  const errorText = getErrorTranslationKey(errorCode, options?.context);

  const { t } = useClientI18n();

  useAlert({
    text: t(errorText, options?.i18nData ?? {}) + ` (${errorCode})`,
    type: 'danger',
    icon: 'crossCircle',
    ...options,
  });
}
