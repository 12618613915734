import { joinURL } from 'ufo';
import { piwa } from 'piwa';

import { PaymentMethodType } from '@/fifteen-sdk/enums';
import { createCwError } from '@/lib/helpers/errors';
import { createFlowStep } from '@/lib/helpers/flow';
import { CwAppErrorCode } from '@/models/enums';

export const addStripePaymentMethod =
  createFlowStep<StripePaymentMethodFlowPayload>(
    'addStripePaymentMethod',
    async payload => {
      const api = useApi();
      const { data, error } = await api.post('/user/payments/payment-methods', {
        body: {
          payment_method_id: payload.paymentMethodId,
        },
      });

      if (error.value) {
        throw createCwError(error.value?.errorCode);
      }

      return {
        ...payload,
        secret: data?.value?.secret ?? '',
      };
    }
  );

export const confirmAddStripePaymentMethod =
  createFlowStep<StripePaymentMethodFlowPayload>(
    'confirmAddStripePaymentMethod',
    async payload => {
      if (!payload.secret) return payload;
      const stripe = await useStripe();

      const result = await stripe.value?.confirmCardSetup(payload.secret, {
        payment_method: payload.paymentMethodId,
      });

      if (result?.error) {
        throw createCwError(CwAppErrorCode.StripeAddPaymentCardFailed);
      }

      return payload;
    }
  );

export const createStripePaypalPaymentMethod =
  createFlowStep<StripePaymentMethodFlowPayload>(
    'createStripePaypalPaymentMethod',
    async payload => {
      const stripe = await useStripe();

      if (!stripe.value) {
        throw createCwError(CwAppErrorCode.StripeInstanciationFailed);
      }

      const { data, error } = await piwa(
        stripe.value.createPaymentMethod({
          type: 'paypal',
        })
      );

      if (error) {
        throw createCwError(CwAppErrorCode.StripeAddPaymentCardFailed);
      }

      return {
        ...payload,
        paymentMethodId: data?.paymentMethod?.id,
      };
    }
  );

export const addStripePaypalPaymentMethod =
  createFlowStep<StripePaymentMethodFlowPayload>(
    'addStripePaypalPaymentMethod',
    async payload => {
      const api = useApi();
      const { data, error } = await api.post('/user/payments/payment-methods', {
        body: {
          payment_method_id: payload.paymentMethodId,
          type: PaymentMethodType.Paypal,
        },
      });

      if (error.value) {
        throw createCwError(error.value?.errorCode);
      }

      return {
        ...payload,
        secret: data?.value?.secret ?? '',
      };
    }
  );

export const confirmAddStripePaypalSetup =
  createFlowStep<StripePaymentMethodFlowPayload>(
    'confirmAddStripePaypalSetup',
    async payload => {
      if (!payload.secret || !payload.paypalReturnUrl) return payload;

      const stripe = await useStripe();

      const result = await stripe.value?.confirmPayPalSetup(payload.secret, {
        payment_method: payload.paymentMethodId,
        return_url: joinURL(window.origin, payload.paypalReturnUrl),
      });

      if (result?.error) {
        throw createCwError(CwAppErrorCode.StripeAddPaymentCardFailed);
      }

      return payload;
    }
  );
