import { enGB } from 'date-fns/locale';

import { defaultLocale, supportedLocalesConfig } from '@/config/locales';

import type { Locale } from 'date-fns';

/**
 * Returns a date-fns locale object
 * @param locale - ISO locale following the ISO-639-1 and ISO-639-2 standards
 */
export function getLocaleDateFns(locale: ISOLocale): Locale {
  const dateFnsLocale = supportedLocalesConfig.find(
    supportedLocale => supportedLocale.locale === locale
  )?.dateFns;
  return dateFnsLocale ?? enGB;
}

/**
 * Returns the locale fallback
 * @param locale - ISO locale following the ISO-639-1 and ISO-639-2 standards
 */
export function getLocaleFallback(locale: ISOLocale): ISOLocale {
  const localeFallback = supportedLocalesConfig.find(
    supportedLocale => supportedLocale.locale === locale
  )?.fallback;
  return localeFallback ?? defaultLocale;
}

/**
 * Returns the locale flag
 * @param locale - ISO locale following the ISO-639-1 and ISO-639-2 standards
 */
export function getFlagFromLocale(locale: ISOLocale): FlagCode | undefined {
  const flag = supportedLocalesConfig.find(
    supportedLocale => supportedLocale.locale === locale
  )?.flag;
  return flag;
}

/**
 * Returns the flag of a locale
 * @param locale - ISO locale following the ISO-639-1 and ISO-639-2 standards
 */
export function getLocaleFromFlag(flagCode: FlagCode): ISOLocale | undefined {
  const locale = supportedLocalesConfig.find(
    supportedLocale => supportedLocale.flag === flagCode
  )?.locale;
  return locale;
}
