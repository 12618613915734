import { formatMessage } from '../../lib';

import { defaultLocale } from '@/config/locales';

import type { I18nData, I18nMessages } from '../../types';

export interface UseI18nReturn {
  t(key: string): string;
  t(key: string, quantity: number): string;
  t(key: string, quantity: number, i18nData: I18nData): string;
  t(key: string, i18nData: I18nData): string;
  t(key: string, i18nData: I18nData, quantity: number): string;
  setLocaleMessages(locale: ISOLocale, messages: I18nMessages): void;
  hasLocaleMessages(locale: ISOLocale): boolean;
  locale: Ref<ISOLocale>;
}

/**
 * Composable exposing `t` and `setLocaleMessage` methods with the same behavior as vue-i18n
 */
export function useI18n(): UseI18nReturn {
  const locale = useState<ISOLocale>('useI18n.locale', () => defaultLocale);

  const i18nMessages = useState<Record<string, I18nMessages>>(
    'useI18n.i18nMessages',
    () => ({})
  );

  function t(key: string): string;
  function t(key: string, i18nData: I18nData): string;
  function t(key: string, quantity: number): string;
  function t(key: string, quantity: number, i18nData: I18nData): string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function t(key: string, ...args: any[]): string {
    const message = i18nMessages.value[locale.value]?.[key];
    // Handle missing translations
    if (message === undefined) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(
          `[i18n] Missing key "${key}" for locale "${locale.value}"`
        );
      }
      return key;
    }
    // Get interpolation data option
    const i18nData =
      typeof args[0] === 'object'
        ? args[0]
        : typeof args[0] === 'object'
        ? args[1]
        : {};
    // Get pluralization option
    const quantity =
      typeof args[0] === 'number'
        ? args[0]
        : typeof args[1] === 'number'
        ? args[1]
        : undefined;
    return formatMessage(message, i18nData, quantity);
  }

  function setLocaleMessages(locale: ISOLocale, messages: I18nMessages): void {
    i18nMessages.value[locale] = messages;
  }

  function hasLocaleMessages(locale: ISOLocale): boolean {
    return !!i18nMessages.value[locale];
  }

  return {
    locale,
    t,
    setLocaleMessages,
    hasLocaleMessages,
  };
}
