<template lang="pug">
.FcwNavbarCta
  FcwButtonLink(
    v-if="clientState.modules.profile"
    :location="{ name: 'profile' }"
    :color="inverted ? 'neutral--light-5' : 'primary'"
    :background="inverted ? 'primary' : 'neutral--light-5'"
  )
    span {{ t('common__my_profile') }}
    FIcon(name="user")
  FButton(
    v-else-if="clientState.sharingAppStoreLink || clientState.sharingPlayStoreLink"
    :color="inverted ? 'neutral--light-5' : 'primary'"
    :background="inverted ? 'primary' : 'neutral--light-5'"
    @click="isMobileAppStoresModalVisible = !isMobileAppStoresModalVisible"
  ) {{ t('common__download_app') }}
</template>

<style lang="stylus"></style>

<script lang="ts" setup>
export interface FcwNavbarCtaProps {
  /**
   * Whether the CTA background color and text color are inverted
   */
  inverted?: boolean;
}

defineProps<FcwNavbarCtaProps>();

const { t } = useClientI18n();
const { clientState } = storeToRefs(useClientStore());
const { isMobileAppStoresModalVisible } = storeToRefs(useGlobalStore());
</script>
