import { mapCmsShowcaseDataToShowcaseSettings } from '@/lib/cms-mappers';

/**
 * Returns showcase settings data fetched from CMS (Strapi)
 */
export async function useCmsShowcaseSettings(): UseCmsResponse<ShowcaseSettings> {
  const { data, error, pending, refresh } = await useStrapi('showcase', {
    queryParams: {
      populate: ['help.sharing_faq_items', 'help.leasing_faq_items'],
    },
    watchLocale: true,
  });

  const mappedData = computed(() =>
    mapCmsShowcaseDataToShowcaseSettings(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
