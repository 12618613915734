import { createCwError } from '@/lib/helpers/errors';
import { createFlowStep } from '@/lib/helpers/flow';
import { CwAppErrorCode } from '@/models/enums';
import { BenefitStatus } from '@/fifteen-sdk/enums';

export const createSubscription = createFlowStep<BenefitFlowPayload>(
  'createSubscription',
  async payload => {
    const api = useApi();

    if (!payload.userOffer.isSubscription) {
      throw createCwError(CwAppErrorCode.InternalIssue);
    }

    const { data, error: subscriptionError } = await api.post(
      '/user/user-offers/subscriptions',
      {
        body: {
          offer_id: payload.userOffer.id,
          offer_type: payload.userOffer.type,
          pickup_type: payload.elements?.productRetrieval ?? undefined,
          place_id: payload.elements?.deliveryAddress?.placeId,
          google_places_session:
            payload.elements?.deliveryAddress?.googlePlacesSession,
        },
      }
    );

    if (subscriptionError.value) {
      throw createCwError(subscriptionError.value.errorCode);
    }

    return {
      userOffer: {
        ...payload.userOffer,
        ...data.value?.benefit,
      },
      elements: payload.elements,
    };
  }
);

/**
 * Update benefit details
 */
export const updateSubscription = createFlowStep<BenefitFlowPayload>(
  'updateSubscription',
  async payload => {
    const api = useApi();

    const { error: updatedBenefitError } = await api.patch(
      '/user/user-offers/subscriptions',
      {
        body: {
          benefit_id: payload.userOffer.id,
          offer_type: payload.userOffer.type,
          pickup_type: payload.elements?.productRetrieval ?? undefined,
          place_id: payload.elements?.deliveryAddress?.placeId,
          google_places_session:
            payload.elements?.deliveryAddress?.googlePlacesSession,
        },
      }
    );

    if (updatedBenefitError.value) {
      throw createCwError(updatedBenefitError.value.errorCode);
    }

    return payload;
  }
);

export const cancelSubscription = createFlowStep<BenefitFlowPayload>(
  'cancelSubscription',
  async payload => {
    const api = useApi();

    const { error } = await api.post('/user/user-offers/subscriptions/cancel', {
      body: {
        benefit_id: payload.userOffer.id,
      },
    });

    if (error.value) {
      throw createCwError(error.value.errorCode);
    }

    return payload;
  }
);

/**
 * This function has two purposes:
 * 1. It returns the payment secret of the benefit
 * 2. It syncs the benefit status
 */
export const syncSubscriptionStatus = createFlowStep<BenefitFlowPayload>(
  'syncSubscriptionStatus',
  async payload => {
    const api = useApi();

    const { error: subscriptionError, data } = await api.post(
      '/user/user-offers/regularize',
      {
        body: {
          id: payload.userOffer.id ?? payload.userOffer.offerId,
        },
      }
    );

    if (subscriptionError.value) {
      throw createCwError(subscriptionError.value.errorCode);
    }

    return {
      userOffer: {
        ...payload.userOffer,
        ...data.value?.userOffer,
      },
      elements: payload.elements,
    };
  }
);

export const regularizeSubscription = createFlowStep<BenefitFlowPayload>(
  'regularizeSubscription',
  async payload => {
    const api = useApi();

    const status = payload.userOffer.status ?? BenefitStatus.NotSet;

    // Only user offers (benefits) in pending status can be regularized
    if (
      status !== BenefitStatus.PendingFirstPayment &&
      status !== BenefitStatus.NeedsPayment
    ) {
      return payload;
    }

    const paymentMethodId = payload.elements?.paymentMethodId;
    const { data, error } = await api.post('/user/user-offers/regularize', {
      body: {
        id: payload.userOffer.id,
        ...(paymentMethodId && {
          payment_method_id: paymentMethodId,
        }),
      },
    });

    if (error.value) {
      throw createCwError(error.value.errorCode);
    }

    return {
      userOffer: {
        ...payload.userOffer,
        ...data.value?.userOffer,
      },
      elements: payload.elements,
    };
  }
);
