<template lang="pug">
.FcwMobileMenu(:class="mobileMenuClasses")
  FButton.FcwMobileMenu__closeButton(
    outlined
    icon
    color="neutral--light-5"
    background="neutral--light-5"
    @click="showMenu = false"
  )
    FIcon(name="close")
  .FcwMobileMenu__menu
    ul.FcwMobileMenu__items(v-show="showMenu")
      li.FcwMobileMenu__item(
        v-for="(link, index) in links"
        :key="index"
        @click="showMenu = false"
      )
        template(v-if="link.submenu && link.submenu.length > 0")
          .FcwMobileMenu__mobileSubLink {{ link.label }}
          ul.FcwMobileMenu__submenu
            li.FcwMobileMenu__submenuItem(
              v-for="submenu in link.submenu"
              :key="submenu.label"
            )
              FcwRouterLink.FcwMobileMenu__mobileLink(
                :location="submenu.to"
                rel="noopener"
                color="neutral--light-4"
                hover-color="primary--dark-2"
                hide-arrow
              )
                span {{ submenu.label }}
                FIcon.FcwMobileMenu__link__externalLinkIcon(
                  v-if="isExternalUrl(String(submenu.to))"
                  name="externalLink"
                  size="16px"
                )

        FcwRouterLink.FcwMobileMenu__mobileLink(
          v-else
          color="neutral--light-5"
          hover-color="primary--dark-2"
          :location="link.to"
          rel="noopener"
          hide-arrow
        )
          span {{ link.label }}
          FIcon.FcwMobileMenu__link__externalLinkIcon(
            v-if="isExternalUrl(String(link.to))"
            name="externalLink"
            size="20px"
          )
    .FcwMobileMenu__actions.FcwMobileMenu__item(v-show="showMenu")
      slot
</template>

<style lang="stylus">
.FcwMobileMenu
  width rem(320)
  height 100%
  position fixed
  display flex
  justify-content space-between
  flex-direction column
  top rem(8)
  left rem(8)
  z-index -1
  opacity 0
  gap rem(64)
  pointer-events none

  &::before
    content ''
    position fixed
    background var(--color--primary)
    top rem(16)
    left rem(16)
    border-radius rem(24)
    transition transform 1000ms
    transform scale(1)
    height rem(56)
    width rem(56)

  +media-down('md')
    display flex
    flex-direction column
    align-items flex-start

  &--visible
    padding rem(96) rem(24) rem(24)
    width 100%
    height 100%
    top 0
    left 0
    opacity 1
    z-index 1
    pointer-events all

    &::before
      content ''
      position fixed
      background var(--color--primary)
      top rem(16)
      left rem(16)
      transform scale(60)
      z-index -1

    .FcwMobileMenu__actions
      animation showItem 0.5s 0.2s forwards

    .FcwMobileMenu__items
      .FcwMobileMenu__item
        animation translateItem 0.7s cubic-bezier(0.15, 0.75, 0.1, 1) forwards, showItem 0.7s forwards

        for num in 1 .. 20
          &:nth-child({num})
            animation-delay (num * 40) ms

.FcwMobileMenu__item
  display block
  text-align left
  color var(--color--neutral--light-5)

.FcwMobileMenu__items
  overflow-x hidden

  .FcwMobileMenu__item
    opacity 0
    transform translateX(rem(40))

@keyframes translateItem
  from
    transform translateX(rem(40))

  to
    transform translateX(rem(0))

@keyframes showItem
  from
    opacity 0

  to
    opacity 1

.FcwMobileMenu__menu
  display flex
  flex-direction column
  height 100%
  width 100%
  justify-content space-between
  gap rem(16)
  overflow auto

.FcwMobileMenu__items
  display flex
  flex-direction column
  gap rem(4)

.FcwMobileMenu__mobileLink
  use-font('heading-5')
  margin-bottom rem(8)

.FcwMobileMenu__mobileSubLink
  use-font('heading-6')
  margin-bottom rem(8)
  opacity 0.8

.FcwMobileMenu__closeButton
  position fixed
  top rem(24)
  left rem(24)

.FcwMobileMenu__submenu
  display flex
  flex-direction column
  padding-left rem(16)
  color var(--color--neutral--light-4)

.FcwMobileMenu__actions
  bottom rem(40)
  left rem(40)
  right rem(40)
  opacity 0

.FcwMobileMenu__link__externalLinkIcon
  margin-left rem(8)
</style>

<script setup lang="ts">
import { isExternalUrl } from '@/lib/helpers/urls';

import type { NavLink } from '@/components/organisms/FcwNavbar.vue';

interface FcwMobileMenuProps {
  /**
   * Whether to show the mobile menu
   */
  modelValue: boolean;
  /**
   * Whether the navbar is plain
   */
  isPlain: boolean;
  /**
   * The links to display in the mobile menu
   */
  links: NavLink[];
}

const props = defineProps<FcwMobileMenuProps>();

useBodyScrollLock(computed(() => props.modelValue));

const showMenu = useVModelProxy({ props });

const { isMdAndDown } = useFBreakpoints();
watch(isMdAndDown, value => {
  if (!value) showMenu.value = false;
});

const mobileMenuClasses = computed<VueClasses>(() => ({
  'FcwMobileMenu--visible': showMenu.value,
}));
</script>
