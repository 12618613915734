<template lang="pug">
nav.FcwNavbar(:class="classes")
  FButton.FcwNavbar__burgerButton(
    v-if="links.length"
    icon
    :color="isPlain ? 'primary' : 'neutral--light-5'"
    :background="isPlain ? 'primary' : 'neutral--light-5'"
    ghost
    @click="showMenu = true"
  )
    FIcon(name="hamburger")

  FcwMobileMenu(
    v-model="showMenu"
    :is-plain="isPlain"
    :links="links"
  )
    slot(
      name="actions-mobile"
      :is-plain="isPlain"
    )
  .FcwNavbar__menuStart
    FcwRouterLink.FcwNavbar__clientLogo(
      :location="homepage"
      hide-arrow
      @click="showMenu = false"
    )
      FcwClientLogo(
        :variant="isPlain && !showMenu ? 'default' : 'light'"
        :color="isPlain && !showMenu ? 'primary' : 'neutral--light-5'"
      )
    FcwChip.FcwNavbar__previewChip(
      v-if="isPreview"
      :label="t('common__preview_mode')"
    )

  .FcwNavbar__menuEnd
    ul.FcwNavbar__items
      li.FcwNavbar__item(
        v-for="(link, index) in links"
        :key="index"
        :aria-haspopup="link.submenu ? 'listbox' : false"
        :aria-expanded="link.submenu && focusedLinkIndex === index"
      )
        template(v-if="link.submenu && link.submenu.length > 0")
          span.FcwNavbar__link(
            color="neutral--light-5"
            tabindex="0"
            hide-arrow
          ) {{ link.label }}

          ul.FcwNavbar__submenu(
            v-if="link.submenu"
            aria-labelledby="FcwNavbarLink"
          )
            li.FcwNavbar__submenuItem(
              v-for="(submenu, subIndex) in link.submenu"
              :key="subIndex"
            )
              FcwRouterLink(
                color="neutral--dark-5"
                :location="submenu.to"
                rel="noopener"
                hide-arrow
                @blur="subIndex === (link?.submenu?.length ?? 0) - 1 ? (focusedLinkIndex = -1) : ''"
              )
                span {{ submenu.label }}
                FIcon.FcwNavbar__link__externalLinkIcon(
                  v-if="isExternalUrl(String(submenu.to))"
                  size="20px"
                  name="externalLink"
                )

        FcwRouterLink.FcwNavbar__link(
          v-else
          color="neutral--light-5"
          :location="link.to"
          rel="noopener"
          hide-arrow
          @focus="focusedLinkIndex = index"
        )
          span {{ link.label }}
          FIcon.FcwNavbar__link__externalLinkIcon(
            v-if="isExternalUrl(String(link.to))"
            size="20px"
            name="externalLink"
          )

      .FcwNavbar__actions(v-if="$slots['actions']")
        slot(
          name="actions"
          :is-plain="isPlain"
        )
</template>

<style lang="stylus">
.FcwNavbar
  display flex
  justify-content space-between
  align-items center
  padding rem(16) rem(40)
  position fixed
  z-index 3
  top 0
  left 0
  right 0
  height rem(96)
  gap rem(40)
  transition background-color 200ms ease

  +media-down('md')
    height rem(80)
    padding rem(16) rem(24)

  .FcwNavbar__link
    color var(--color--neutral--light-5)
    padding rem(8) 0

    &:hover
      color var(--color--neutral--light-5)

  &--plain
    background-color var(--color--neutral--light-5)
    border-bottom-left-radius rem(16)
    border-bottom-right-radius rem(16)
    box-shadow 0 0.5px 14px rgba(0, 0, 0, 0.04)

    .FcwNavbar__link
      color var(--color--neutral--dark-5)

      &:hover
        color var(--color--primary)

.FcwNavbar__burgerButton
  display none

  +media-down('md')
    display block

.FcwNavbar__clientLogo
  display flex

  +media-down('md')
    z-index 3

.FcwNavbar__menuStart
  display flex
  align-items center
  flex-shrink 0
  gap rem(16)

  +media-down('md')
    .FcwNavbar__clientLogo
      order 2

.FcwNavbar__menuEnd
  display flex
  justify-content flex-end
  max-width rem(960)

  +media-down('md')
    display none

.FcwNavbar__link
  use-font('heading-6')

.FcwNavbar__subLink
  use-font('caption')

.FcwNavbar__mobileSubLink
  use-font('heading-6')
  margin-bottom rem(8)

.FcwNavbar__items
  display flex
  align-items center

.FcwNavbar__item
  margin rem(0) rem(16)
  white-space nowrap
  position relative
  cursor pointer

  .FcwNavbar__submenu
    clip rect(1px, 1px, 1px, 1px) // hide the content
    width max-content
    color var(--color--neutral--dark-5)
    padding rem(12) rem(20)
    margin-top rem(8)
    position absolute
    top 100%
    left 0
    background-color var(--color--neutral--light-5)
    border-radius rem(16)
    elevation(1)
    display flex
    flex-direction column
    gap rem(4)

    &::before
      content ''
      position absolute
      height rem(16)
      width 100%
      top rem(-16)
      left 0

  .FcwNavbar__mobileSubmenuItem
    display block
    margin-left 0
    margin-right 0

  &:hover,
  &:focus-within
    .FcwNavbar__submenu
      animation slideDown 300ms ease
      clip auto

.FcwNavbar__actions
  display flex
  align-items center

  > *
    margin 0 rem(16)

@keyframes slideDown
  from
    transform translateY(rem(-8))
    opacity 0
    overflow hidden

  to
    transform translateY(0)
    opacity 1
    overflow visible

.FcwNavbar__link__externalLinkIcon
  margin-left rem(8)
</style>

<script lang="ts" setup>
import { useFBreakpoints } from '@fifteen/design-system-vue';

import { isExternalUrl } from '@/lib/helpers/urls';

import type { RouteLocationRaw } from 'vue-router';

export interface NavLink {
  /**
   * Displayed label of the link
   */
  label: string;
  /**
   * Whether the link is available
   */
  enabled?: boolean;
  /**
   * Link location
   */
  to?: RouteLocationRaw;
  /**
   * Menu items submenu links
   */
  submenu?: Omit<NavLink[], 'submenu'>;
}

export interface FcwNavbarProps {
  /**
   * Array of links
   */
  links?: NavLink[];
  /**
   * Whether the background is plain with dark text color, of transparent with light text color
   */
  plain?: boolean;
  /**
   * Threshold at which nav goes to plain background. Number from 0 to 1, 1 equals to 100vh
   */
  plainThreshold?: number;
}

const { isMdAndDown } = useFBreakpoints();
const { isPreview } = useGlobalStore();

const { t } = useClientI18n();

const props = withDefaults(defineProps<FcwNavbarProps>(), {
  plain: false,
  plainThreshold: 1,
  links: () => [],
});

const showMenu = ref(false);
const focusedLinkIndex = ref<number | null>(null);
const clientStore = useClientStore();
const { homepage } = storeToRefs(clientStore);

const { y } = useWindowScroll({});
const { height } = useWindowSize();

const isPlain = computed(() => {
  const isMainSection = y.value < (height.value - 80) * props.plainThreshold;
  return (
    props.plain ||
    !isMainSection ||
    (!isMainSection && isMdAndDown.value && showMenu.value)
  );
});

const classes = computed<VueClasses>(() => ({
  'FcwNavbar--plain': isPlain.value,
}));
</script>
