<template lang="pug">
.FcwChip(:style="style") {{ label }}
</template>

<style lang="stylus">
.FcwChip
  background var(--FcwChip--backgroundColor)
  width fit-content
  border-radius rem(16)
  text-align center
  use-font('caption')
  color var(--FcwChip--textColor)
  padding rem(4) rem(8)
</style>

<script setup lang="ts">
export interface FcwChipProps {
  /**
   * Text of the chip
   */
  label: string;
  /**
   * Color of the chip
   */
  color?: BaseColorDesignToken;
  /**
   * Size of the chip
   */
  size?: 'small' | 'medium';
}

const props = withDefaults(defineProps<FcwChipProps>(), {
  color: 'primary',
  size: 'medium',
});

const style = computed(
  (): Style => ({
    '--FcwChip--textColor': getCssColor(
      props.color === 'neutral'
        ? 'neutral--dark-3'
        : getCssColor(props.color + '--dark-2')
    ),
    '--FcwChip--backgroundColor': getCssColor(props.color + '--light-2'),
  })
);
</script>
