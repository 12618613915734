import { mapCmsImageToImageProps } from '@/lib/cms-mappers/common.mappers';
import { ServiceType } from '@/models/enums';

import type { CurrencyCode } from '@fifteen/shared-lib';

export function mapCmsProfileDataToProfileSettings(
  profileRawData: StrapiResponseData<ProfileContentType> | undefined
): ProfileSettings {
  const cmsData = profileRawData?.attributes;

  return {
    discoverServices: (cmsData?.discover_services ?? []).map(service => ({
      serviceType: service.type ?? ServiceType.Sharing,
      steps: (service.steps ?? []).map(step => ({
        title: step.title ?? '',
        text: step.description ?? '',
        images: (step.images?.data ?? []).map(image =>
          mapCmsImageToImageProps({ data: image }, 'medium')
        ),
      })),
    })),
    featuredOffer: {
      name: cmsData?.featured_offer?.name ?? '',
      context:
        (cmsData?.featured_offer?.type as ServiceType | 'other' | undefined) ??
        'other',
      plan: {
        price: cmsData?.featured_offer?.price ?? 0,
        interval: cmsData?.featured_offer?.interval ?? '',
        currency:
          cmsData?.featured_offer?.currency?.toUpperCase() as CurrencyCode,
      },
      ruleTexts: cmsData?.featured_offer?.rules?.split('\n') ?? [],
      ctaText: cmsData?.featured_offer?.cta_text ?? '',
      ctaLink: cmsData?.featured_offer?.cta_link ?? '',
      summary: cmsData?.featured_offer?.summary ?? '',
    },
  };
}
