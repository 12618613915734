import { notNullish } from '@fifteen/shared-lib';

import type {
  FcwFooterProps,
  FooterLink,
  FooterLinkWithIcon,
} from '@/components/organisms/FcwFooter.vue';
import type { ComputedRef } from 'vue';

/**
 * Check if footer link is enabled
 * @param item - footer link
 */
function isEnabled(item: FooterLink): boolean {
  return item.enabled ?? false;
}

/**
 * Returns FcwFooter props, filtered on enabled services
 */
export async function useClientFooter(
  layout: 'showcase' | 'minimal' | 'auto' = 'auto'
): Promise<ComputedRef<FcwFooterProps>> {
  const { t } = useClientI18n();
  const clientStore = useClientStore();
  const { clientState } = storeToRefs(clientStore);

  const autoLayout = clientState.value.modules.showcase
    ? 'showcase'
    : 'minimal';
  const activeLayout = layout === 'auto' ? autoLayout : layout;

  const { pages } = clientState.value;

  const { data: blogPosts } =
    activeLayout === 'showcase'
      ? await useCmsBlogPosts({
          pageSize: 4,
          fields: ['title', 'slug'],
        })
      : { data: ref({ data: [] }) };

  const showcaseProps = computed<FcwFooterProps>(() => ({
    metropolisLogoLight: {
      src: clientState.value.metropolisLogoLightSrc ?? '',
      alt: `${clientState.value.name} logo`,
    },
    customerServiceTitle: t('footer__customer_service__title'),
    customerServiceLinks: (
      [
        {
          icon: 'supportHelp',
          label: clientState.value.phoneNumber,
          location: `tel:${clientState.value.phoneNumber}`,
          type: 'tel',
        },
        {
          icon: 'mail',
          label: clientState.value.email,
          location: `mailto:${clientState.value.email}`,
          type: 'mailto',
        },
        {
          icon: 'clock',
          label: clientState.value.openingHours,
        },
      ] as FooterLinkWithIcon[]
    ).filter(customerServiceLink => !!customerServiceLink.label),
    editorText: t('footer__powered_by'),
    servicesTitle: t('footer__our_services__title'),
    serviceLinks: [
      {
        enabled: pages.showcaseSharing,
        label: t('footer__sharing'),
        location: { name: 'showcase-sharing' },
      },
      {
        enabled: pages.showcaseLeasing,
        label: t('footer__leasing'),
        location: { name: 'showcase-leasing' },
      },
    ].filter(isEnabled),
    blogPostTitle: t('footer__blog__title'),
    blogPostLinks: blogPosts.value?.data
      .map(blogPost => ({
        enabled: pages.showcaseBlog,
        label: blogPost.attributes?.title ?? '',
        location: {
          name: `showcase-blog-slug`,
          params: { slug: blogPost.attributes?.slug ?? '' },
        },
      }))
      .filter(isEnabled),
    practicalInfoTitle: t('footer__practical_info__title'),
    practicalInfoLinks: [
      {
        enabled: pages.showcaseHelp,
        label: t('footer__faq'),
        location: { name: 'showcase-help' },
      },
      {
        enabled: true,
        label: t('footer__privacy_policy'),
        location: { name: 'privacy-policy' },
      },
      {
        enabled: true,
        label: t('footer__terms_and_conditions'),
        location: { name: 'terms-and-conditions' },
      },
    ].filter(isEnabled),
    address: clientState.value.address,
    openingHours: clientState.value.openingHours,
    socialLinksTitle: t('footer__find_us_on__title'),
    socialLinks: (clientState.value.socialNetworks ?? [])
      .map(socialNetwork => {
        if (!socialNetwork.name || !socialNetwork.url) return;
        return {
          icon: socialNetwork.name,
          location: socialNetwork.url,
        };
      })
      .filter(notNullish),
    bottomLineLinks: [
      {
        label: t('footer__legal_notice'),
        location: { name: 'legal-notice' },
      },
    ],
  }));

  const minimalProps = computed<FcwFooterProps>(() => ({
    bottomLineLinks: [
      {
        label: t('footer__privacy_policy'),
        location: { name: 'privacy-policy' },
        external: true,
      },
      {
        label: t('footer__terms_and_conditions'),
        location: { name: 'terms-and-conditions' },
        external: true,
      },
      {
        label: t('footer__legal_notice'),
        location: { name: 'legal-notice' },
        external: true,
      },
    ],
    editorText: t('footer__powered_by'),
  }));

  return computed<FcwFooterProps>(() =>
    activeLayout === 'showcase' ? showcaseProps.value : minimalProps.value
  );
}
