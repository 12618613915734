<template lang="pug">
Teleport(
  to="body"
  :disabled="inline"
)
  Transition(
    name="FcwModal__transition"
    :duration="200"
  )
    .FcwModal(
      v-show="isVisible"
      :class="modalClass"
      :style="style"
    )
      .FcwModal__overlay(@mousedown="!persistent && closeModal()")
        FcwCard.FcwModal__raw(
          v-if="raw"
          @mousedown.stop="null"
        )
          slot(:close="closeModal")
        FcwTile.FcwModal__tile(
          v-else
          @mousedown.stop="null"
        )
          template(#header)
            .FcwModal__header
              FIcon.FcwModal__icon_danger(
                v-if="titleIcon"
                :name="titleIcon"
                color="primary"
                size="40"
              )
              h4.FcwModal__title(v-if="title") {{ title }}
              slot(
                v-else
                name="header"
              )
              FButton.FcwModal__headerCloseButton(
                v-if="closable"
                icon
                ghost
                @click="closeModal"
              )
                FIcon(
                  name="crossCircle"
                  size="24"
                )
          slot(:close="closeModal")
          .FcwModal__actions(v-if="$slots['actions']")
            slot(
              name="actions"
              :close="closeModal"
            )
</template>

<style lang="stylus">
.FcwModal__overlay
  display flex
  justify-content center
  align-items center
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background 'rgba(%s, 0.6)' % var(--color--neutral--dark-4--rgb)
  z-index 10

  > *
    max-height 100vh

.FcwModal__raw,
.FcwModal__tile
  min-width var(--FcwModal--width)
  width min-content

.FcwModal__tile
  overflow-y auto
  overflow-x hidden

.FcwModal__tile,
.FcwModal__raw
  overflow auto

.FcwModal__header
  display flex
  justify-content space-between
  gap rem(8)

.FcwModal__title
  color var(--color--primary)
  overflow hidden
  text-overflow ellipsis

.FcwModal__headerCloseButton
  margin-left rem(24)

.FcwModal__actions
  display flex
  justify-content center
  margin-top rem(32)

  > * + *
    margin-left rem(16)

.FcwModal__transition-enter-active,
.FcwModal__transition-leave-active
  .FcwModal__overlay
    transition opacity 200ms

  .FcwModal__tile,
  .FcwModal__raw
    transition-delay 200ms
    transition transform 200ms

.FcwModal__transition-enter-from
  .FcwModal__overlay
    opacity 0.6

  .FcwModal__tile,
  .FcwModal__raw
    transform translateX(rem(-16))

.FcwModal__transition-leave-to
  .FcwModal__overlay
    opacity 0.6

  .FcwModal__tile,
  .FcwModal__raw
    transform translateX(rem(16))

.FcwModal__transition-enter-to,
.FcwModal__transition-leave-from
  .FcwModal__overlay
    opacity 1

  .FcwModal__tile,
  .FcwModal__raw
    transform translateX(0)
</style>

<script setup lang="ts">
import { genSize } from '@fifteen/design-system-vue';

export interface FcwModalProps {
  /**
   * Handles the modal visibility
   */
  modelValue?: boolean;
  /**
   * Title of the modal. Overridden by header slot is used
   */
  title?: string;
  /**
   * Display a close button on top right that closes it
   */
  closable?: boolean;
  /**
   * Classes of the modal
   */
  modalClass?: VueClasses;
  /**
   * Width of the modal
   */
  width?: string | number;
  /**
   * If true, the modal is not closed when the user clicks outside
   */
  persistent?: boolean;
  /**
   * Remove the tile display by allowing any content
   */
  raw?: boolean;
  /**
   * Icon to prepend to the title
   */
  titleIcon?: Icon;
  /**
   * Disable the Teleport usage
   */
  inline?: boolean;
}

const props = withDefaults(defineProps<FcwModalProps>(), {
  modelValue: true,
  title: '',
  closable: false,
  width: 464,
  persistent: false,
  titleIcon: undefined,
  modalClass: () => [],
  inline: false,
});

const emit = defineEmits<{
  (name: 'close'): void;
}>();

const isVisible = useVModelProxy({ props });

useEventListener(document, 'keydown', event => {
  if (!isVisible.value) return;
  event.key === 'Escape' && closeModal();
});

function closeModal(): void {
  isVisible.value = false;
}
const style = computed<Style>(
  (): Style => ({
    '--FcwModal--width': genSize(props.width),
  })
);

watch(isVisible, value => !value && emit('close'));
</script>
