import { createFlowStep } from '@/lib/helpers/flow';
import { createCwError } from '@/lib/helpers/errors';
import { BenefitStatus } from '@/fifteen-sdk/enums';

export const requestCancelSubscription = createFlowStep<BenefitFlowPayload>(
  'requestCancelSubscription',
  async payload => {
    const { userOffer } = payload;
    const api = useApi();

    // We only send cancel requests for active subscriptions
    if (userOffer.status !== BenefitStatus.Active) {
      return payload;
    }

    const { error: requestCancelError } = await api.post(
      '/user/user-offers/subscriptions/cancel-request',
      {
        body: {
          benefit_id: userOffer.id ?? userOffer.offerId,
        },
      }
    );

    if (requestCancelError.value) {
      throw createCwError(requestCancelError.value.errorCode);
    }

    return payload;
  }
);

export const cancelSubscriptionCancellationRequest =
  createFlowStep<BenefitFlowPayload>(
    'cancelSubscriptionCancellationRequest',
    async payload => {
      const api = useApi();

      const { error: requestCancelError } = await api.post(
        '/user/user-offers/subscriptions/cancel-request',
        {
          body: {
            benefit_id: payload.userOffer.id ?? payload.userOffer.offerId,
            cancel_cancellation_request: true,
          },
        }
      );

      if (requestCancelError.value) {
        throw createCwError(requestCancelError.value.errorCode);
      }

      return payload;
    }
  );
