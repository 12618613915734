import { mapCmsLocaleStatusToLocaleSettings } from '@/lib/cms-mappers';

/**
 * Returns locales data fetched from CMS (Strapi)
 */
export async function useCmsLocaleStatus(): UseCmsResponse<LocaleSettings> {
  const { data, error, pending, refresh } = await useStrapi('locale-status', {
    offlineSSRFallback: true,
  });

  const mappedData = computed(() =>
    mapCmsLocaleStatusToLocaleSettings(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
