import { CwError } from '@/lib/helpers/errors';
import { createLogger } from '@/lib/core/logger';

type FlowStep<I> = (input: I) => Promise<I>;
/**
 * Function that create a flow.
 * A flow is a combination of steps that are executed one after the other. In this case, each step use as input the output of the previous one.
 * In the present approach:
 * - all steps are sharing the same interface
 * - each step can update the payload of flow
 * @param initialPayload - Initial input of the flow
 * @returns the payload output by the last successful flow step
 */
export function createFlow<I>(
  initialPayload: I
): (...steps: FlowStep<I>[]) => Promise<{ data: I; error: CwError | null }> {
  const logger = createLogger('flow');

  logger.debug(`Starting flow`, initialPayload);

  return async function (...steps: FlowStep<I>[]) {
    let currentPayload: I = initialPayload;
    let error: CwError | null = null;

    for await (const step of steps) {
      if (error) {
        logger.debug(`- Step: ${step.name}: ⏭️`);
        return { data: currentPayload, error };
      }

      try {
        currentPayload = await step(currentPayload);
      } catch (e) {
        if (e instanceof CwError) {
          error = e;
        } else {
          throw e;
        }
      }

      logger.debug(
        `- Step: ${step.name}: ${error ? `❌ (${error.code})` : '✅'} `,
        currentPayload
      );
    }

    return { data: currentPayload, error };
  };
}

export function createFlowStep<I>(
  name: string,
  fn: (payload: I) => Promise<I>
): (payload: I) => Promise<I> {
  Object.defineProperty(fn, 'name', { value: name, writable: false });
  return fn;
}
