import piwa from 'piwa';

import { MonextErrorCode, CwAppErrorCode } from '@/models/enums';
import { monextErrorToAppError } from '@/lib/helpers/errors';
/**
 * Parse Monext `getToken` response to return the token or the error in an object
 * @param response - Response from Monext (either "data=..." or "errorCode=...")
 */
export async function parseMonextGetTokenResponse(response: Response): Promise<
  | {
      data: null;
      error: CwAppErrorCode;
    }
  | { data: string; error: null }
> {
  const { data: textData, error } = await piwa(response?.text());

  if (error) {
    return {
      data: null,
      error: CwAppErrorCode.InternalIssue,
    };
  }

  const decodedResponse = new URLSearchParams(textData);

  const data = decodedResponse.get('data');
  const errorCode = decodedResponse.get('errorCode');

  if (data) {
    return { data, error: null };
  } else {
    return {
      data: null,
      error: monextErrorToAppError(errorCode as MonextErrorCode),
    };
  }
}
