export const defaultPrimaryFontFamily = 'Greycliff, sans-serif';
export const defaultSecondaryFontFamily = 'Roboto, sans-serif';
/**
 * Master colors and font settings
 */
export const defaultTheme = {
  breakpointXxs: '360px',
  breakpointXs: '600px',
  breakpointSm: '960px',
  breakpointMd: '1264px',
  breakpointLg: '1904px',
  colorNeutralDark5: '#000',
  colorNeutralDark4: '#2A3039',
  colorNeutralDark3: '#546171',
  colorNeutralDark2: '#8091A5',
  colorNeutralDark1: '#A9B9C0',
  colorNeutral: '#B4C3CA',
  colorNeutralLight1: '#C3CFD6',
  colorNeutralLight2: '#D3DCE0',
  colorNeutralLight3: '#E5EBED',
  colorNeutralLight4: '#F8F9FA',
  colorNeutralLight5: '#FFFFFF',
  colorPrimaryDark2: '#06143C',
  colorPrimaryDark1: '#112B5A',
  colorPrimary: '#234B7E',
  colorPrimaryLight1: '#7AACD8',
  colorPrimaryLight2: '#D4EAF8',
  colorSecondaryDark2: '#3C0633',
  colorSecondaryDark1: '#5A1140',
  colorSecondary: '#7E234B',
  colorSecondaryLight1: '#D87A91',
  colorSecondaryLight2: '#F8D4D6',
  colorSuccessDark2: '#056452',
  colorSuccessDark1: '#0D9661',
  colorSuccess: '#1BD166',
  colorSuccessLight1: '#73F18E',
  colorSuccessLight2: '#D0FCD1',
  colorInfoDark2: '#756D02',
  colorInfoDark1: '#AFA507',
  colorInfo: '#F4E90E',
  colorInfoLight1: '#FBF66D',
  colorInfoLight2: '#FEFDCE',
  colorWarningDark2: '#7A0C08',
  colorWarningDark1: '#B73116',
  colorWarning: '#FF6C2D',
  colorWarningLight1: '#FFB781',
  colorWarningLight2: '#FFECD5',
  colorDangerDark2: '#7A083B',
  colorDangerDark1: '#B71641',
  colorDanger: '#FF2D3F',
  colorDangerLight1: '#FF8D81',
  colorDangerLight2: '#FFE0D5',
  transitionEaseOut: 'cubic-bezier(0.17, 0.84, 0.44, 1)',
  codeBackgroundColor: 'rgba(0, 0, 0, 0.12)',
  fluidFontBreakpointMinPx: '600',
  fluidFontBreakpointMaxPx: '1904',
  heading1LetterSpacing: '0.09375rem',
  heading1FontSizeMinPx: '48',
  heading1FontSizeMaxPx: '64',
  heading1LineHeightMinPx: '56',
  heading1LineHeightMaxPx: '72',
  heading1FontWeight: '800',
  heading1FontFamily: defaultPrimaryFontFamily,
  heading2LetterSpacing: '0.09375rem',
  heading2FontSizeMinPx: '40',
  heading2FontSizeMaxPx: '48',
  heading2LineHeightMinPx: '48',
  heading2LineHeightMaxPx: '56',
  heading2FontWeight: '800',
  heading2FontFamily: defaultPrimaryFontFamily,
  heading3LetterSpacing: '0.015625rem',
  heading3FontSizeMinPx: '32',
  heading3FontSizeMaxPx: '32',
  heading3LineHeightMinPx: '40',
  heading3LineHeightMaxPx: '40',
  heading3FontWeight: '800',
  heading3FontFamily: defaultPrimaryFontFamily,
  heading4LetterSpacing: '0.015625rem',
  heading4FontSizeMinPx: '24',
  heading4FontSizeMaxPx: '24',
  heading4LineHeightMinPx: '32',
  heading4LineHeightMaxPx: '32',
  heading4FontWeight: '800',
  heading4FontFamily: defaultPrimaryFontFamily,
  heading5LetterSpacing: '0.015625rem',
  heading5FontSizeMinPx: '20',
  heading5FontSizeMaxPx: '20',
  heading5LineHeightMinPx: '24',
  heading5LineHeightMaxPx: '24',
  heading5FontWeight: '800',
  heading5FontFamily: defaultPrimaryFontFamily,
  heading6FontSizeMinPx: '16',
  heading6FontSizeMaxPx: '16',
  heading6LineHeightMinPx: '20',
  heading6LineHeightMaxPx: '20',
  heading6FontWeight: '800',
  heading6FontFamily: defaultPrimaryFontFamily,
  heading6LetterSpacing: '0.009375rem',
  subtitle1FontSizeMinPx: '16',
  subtitle1FontSizeMaxPx: '16',
  subtitle1LineHeightMinPx: '24',
  subtitle1LineHeightMaxPx: '24',
  subtitle1FontWeight: '700',
  subtitle1FontFamily: defaultSecondaryFontFamily,
  subtitle1LetterSpacing: '0.009375rem',
  subtitle2FontSizeMinPx: '14',
  subtitle2FontSizeMaxPx: '14',
  subtitle2LineHeightMinPx: '16',
  subtitle2LineHeightMaxPx: '24',
  subtitle2FontWeight: '700',
  subtitle2FontFamily: defaultSecondaryFontFamily,
  subtitle2LetterSpacing: '0.00625rem',
  body1FontSizeMinPx: '16',
  body1FontSizeMaxPx: '16',
  body1LineHeightMinPx: '24',
  body1LineHeightMaxPx: '32',
  body1FontWeight: '500',
  body1FontFamily: defaultSecondaryFontFamily,
  body1LetterSpacing: '0.015625rem',
  body2FontSizeMinPx: '16',
  body2FontSizeMaxPx: '16',
  body2LineHeightMinPx: '24',
  body2LineHeightMaxPx: '24',
  body2FontWeight: '500',
  body2FontFamily: defaultSecondaryFontFamily,
  body2LetterSpacing: '0.03125rem',
  buttonFontSizeMinPx: '14',
  buttonFontSizeMaxPx: '14',
  buttonLineHeightMinPx: '16',
  buttonLineHeightMaxPx: '16',
  buttonFontWeight: '800',
  buttonFontFamily: defaultPrimaryFontFamily,
  buttonLetterSpacing: '0.03125rem',
  captionFontSizeMinPx: '12',
  captionFontSizeMaxPx: '12',
  captionLineHeightMinPx: '16',
  captionLineHeightMaxPx: '16',
  captionFontWeight: '500',
  captionFontFamily: defaultSecondaryFontFamily,
  captionLetterSpacing: '0.025rem',
  overlineFontSizeMinPx: '16',
  overlineFontSizeMaxPx: '16',
  overlineLineHeightMinPx: '24',
  overlineLineHeightMaxPx: '24',
  overlineFontWeight: '700',
  overlineFontFamily: defaultSecondaryFontFamily,
  overlineLetterSpacing: '0.0625rem',
} as const;

type ThemeKeys = keyof typeof defaultTheme;
export type Theme = Record<ThemeKeys, string>;
