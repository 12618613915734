import { createApp } from 'vue';
import piwa from 'piwa';

import { createCwError } from '@/lib/helpers/errors';
import { createFlowStep } from '@/lib/helpers/flow';
import { parseMonextGetTokenResponse } from '@/lib/helpers/payment-providers';
import { CwAppErrorCode } from '@/models/enums';
import { sleep } from '@/lib/utils';
import { ChallengeStatus, PaymentStatus } from '@/fifteen-sdk/enums';
import FcwModal from '@/components/molecules/FcwModal.vue';
import { MONEXT_CHALLENGE_TIMEOUT_MS } from '@/config/monext';

function renderMonextIframe(
  visible: boolean,
  challenge: MonextPaymentMethodFlowPayload['challenge']
): { unmountIframe: () => void } {
  const elExists = !!document.getElementById(
    `monext-${visible ? 'visible' : 'invisible'}`
  );
  if (!elExists) {
    const el = document.createElement('div');
    el.setAttribute('id', `monext-${visible ? 'visible' : 'invisible'}`);

    document.body.appendChild(el);
  }

  const formRef = ref<HTMLFormElement>();

  const app = createApp({
    setup() {
      onMounted(() => {
        formRef.value?.submit();
      });
    },
    render() {
      return h(
        visible ? FcwModal : 'div',
        // @ts-expect-error no overload is matching our case
        visible ? { width: '48rem', height: '48rem' } : {},
        [
          h('iframe', {
            style: visible
              ? { width: '42rem', height: '42rem', border: 'none' }
              : { display: 'none' },
            name: visible ? 'visible_iframe' : 'invisible_iframe',
          }),
          h(
            'form',
            {
              ref: formRef,
              action: challenge?.action_url,
              method: challenge?.action_method,
              target: visible ? 'visible_iframe' : 'invisible_iframe',
            },
            h('input', {
              type: 'hidden',
              name: challenge?.pareq_field_name,
              value: challenge?.pareq_field_value,
            })
          ),
        ]
      );
    },
  });

  app.mount(`#monext-${visible ? 'visible' : 'invisible'}`);
  return {
    unmountIframe: () => {
      app.unmount();
    },
  };
}

export const addMonextPaymentMethod =
  createFlowStep<MonextPaymentMethodFlowPayload>(
    'addMonextPaymentMethod',
    async payload => {
      const api = useApi();

      const { error, data } = await api.post('/user/payments/payment-methods', {
        body: {
          card_token: payload.cardToken,
        },
      });

      if (error.value) {
        throw createCwError(error.value?.errorCode);
      }

      if (data.value?.status !== PaymentStatus.PaymentNeedsAction) {
        return payload;
      }

      return {
        ...payload,
        paymentId: data.value.paymentId,
      };
    }
  );

export const getMonextChallenge = createFlowStep<
  MonextPaymentMethodFlowPayload | MonextPaymentConfirmationFlowPayload
>('addMonextPaymentMethod', async payload => {
  const api = useApi();

  const { error: actionError, data: actionData } = await api.post(
    '/user/payments/action',
    {
      body: {
        payment_id: payload.paymentId ?? '',
      },
    }
  );

  if (actionError.value) {
    throw createCwError(actionError.value?.errorCode);
  }

  return {
    ...payload,
    challenge: actionData.value?.challenge,
  };
});

export const confirmAddMonextPaymentMethod = createFlowStep<
  MonextPaymentMethodFlowPayload | MonextPaymentConfirmationFlowPayload
>('confirmAddMonextPaymentMethod', async payload => {
  const { challenge } = payload;

  if (challenge?.status !== ChallengeStatus.Waiting_3DsMethod) {
    return payload;
  }
  const api = useApi();

  const { error, data } = await api.post('/user/payments/action', {
    body: {
      payment_id: payload.paymentId ?? '',
    },
  });

  if (error.value) {
    throw createCwError(error.value?.errorCode);
  }

  return {
    ...payload,
    ...(data.value?.challenge && {
      challenge: data.value?.challenge,
    }),
  };
});

/**
 * This step is called to finish a Monext add payment method flows.
 * It's close to the`confirmAddMonextPaymentMethod` step, but also checks the done status to make sure the card is properly added.
 */
export const finishAddMonextPaymentMethod = createFlowStep<
  MonextPaymentMethodFlowPayload | MonextPaymentConfirmationFlowPayload
>('finishAddMonextPaymentMethod', async payload => {
  const { challenge } = payload;

  const api = useApi();

  if (challenge?.status === ChallengeStatus.WaitingChallenge) {
    const { error: actionError } = await api.post('/user/payments/action', {
      body: {
        payment_id: payload.paymentId ?? '',
      },
    });

    if (actionError.value) {
      throw createCwError(actionError.value?.errorCode);
    }
  }

  const { error } = await api.post('/user/payments/payment-methods', {
    body: {
      ...('cardToken' in payload && { card_token: payload.cardToken }),
      payment_id: payload.paymentId,
    },
  });

  if (error.value) {
    throw createCwError(error.value.errorCode);
  }

  return payload;
});

/**
 * Get a Monext token from the Core API. This token is used to authenticate the user on Monext's side (`getToken` call).
 */
export const getSetupToken = createFlowStep<MonextPaymentMethodFlowPayload>(
  'getSetupToken',
  async payload => {
    const api = useApi();

    const { data, error } = await api.post(
      '/user/payments/payment-methods/setup'
    );

    if (error.value) {
      throw createCwError(error.value?.errorCode);
    }

    return {
      ...payload,
      setupToken: data.value?.setup.token,
    };
  }
);

/**
 * Get Monext token from `getToken` route
 */
export const getMonextToken = createFlowStep<MonextPaymentMethodFlowPayload>(
  'getMonextToken',
  async payload => {
    const { monextPublicKey, monextTokenEndpoint } = useRuntimeConfig().public;

    const parametersToAdd = {
      accessKeyRef: monextPublicKey,
      data: payload.setupToken ?? '',
      cardNumber: payload.card?.cardNumber ?? '',
      cardExpirationDate: payload.card?.cardExp ?? '',
      cardCvx: payload.card?.cardCvc ?? '',
    };

    const urlEncoded = new URLSearchParams();

    for (const [key, value] of Object.entries(parametersToAdd)) {
      urlEncoded.append(key, value);
    }

    // We need to make a direct fetch to Monext `getToken` route instead of using our BFF
    // To ensure no sensitive data transits through our server
    const monextResponse = await fetch(monextTokenEndpoint, {
      method: 'POST',
      body: urlEncoded,
    });

    const { data: cardToken, error } =
      await parseMonextGetTokenResponse(monextResponse);

    if (error) {
      throw createCwError(error);
    }

    return {
      ...payload,
      cardToken,
    };
  }
);

interface ThreeDSChallengeMessageData {
  challengeId: string;
  status: ChallengeStatus;
  type?: '3ds-challenge';
}
type ThreeDSChallengeMessageEvent = MessageEvent<ThreeDSChallengeMessageData>;

export const renderMonextInvisibleChallengeIframe =
  createFlowStep<MonextPaymentMethodFlowPayload>(
    'renderMonextInvisibleChallengeIframe',
    async payload => {
      const { challenge } = payload;
      if (challenge?.status !== ChallengeStatus.Waiting_3DsMethod) {
        return payload;
      }

      const isIframeLoaded = ref(false);
      const isError = ref(false);

      function handle3dsChallenge(
        messageEvent: ThreeDSChallengeMessageEvent
      ): void {
        const messageData = messageEvent.data;

        if (messageData.type !== '3ds-challenge') return;

        isIframeLoaded.value = true;

        if (
          messageData.status === ChallengeStatus.Fail ||
          messageData.status === ChallengeStatus.Errored
        ) {
          isError.value = true;
        }
      }

      const { unmountIframe } = renderMonextIframe(false, challenge);
      window.addEventListener('message', handle3dsChallenge);

      const { error: timeoutError } = await piwa(
        Promise.race([
          until(isIframeLoaded).toBe(true),
          (async () => {
            await sleep(MONEXT_CHALLENGE_TIMEOUT_MS);
            throw new Error();
          })(),
        ])
      );

      if (timeoutError) {
        throw createCwError(CwAppErrorCode.PaymentMethodTimeout);
      }
      if (isError.value) {
        throw createCwError(CwAppErrorCode.PaymentMethodChallengeFailed);
      }

      unmountIframe();
      window.removeEventListener('message', handle3dsChallenge);

      return payload;
    }
  );

export const renderMonextVisibleChallengeIframe =
  createFlowStep<MonextPaymentMethodFlowPayload>(
    'renderMonextVisibleChallengeIframe',
    async payload => {
      const { challenge } = payload;
      if (challenge?.status !== ChallengeStatus.WaitingChallenge) {
        return payload;
      }

      const isIframeLoaded = ref(false);
      const isError = ref(false);

      function handle3dsChallenge(
        messageEvent: ThreeDSChallengeMessageEvent
      ): void {
        const messageData = messageEvent.data;

        if (messageData.type !== '3ds-challenge') return;

        isIframeLoaded.value = true;

        if (
          messageData.status === ChallengeStatus.Fail ||
          messageData.status === ChallengeStatus.Errored
        ) {
          isError.value = true;
        }
      }

      window.addEventListener('message', handle3dsChallenge);

      const { unmountIframe } = renderMonextIframe(true, challenge);

      await until(isIframeLoaded).toBe(true);

      unmountIframe();
      window.removeEventListener('message', handle3dsChallenge);

      if (isError.value) {
        throw createCwError(CwAppErrorCode.PaymentMethodChallengeFailed);
      }

      return payload;
    }
  );
