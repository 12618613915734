import { mapCmsProfileDataToProfileSettings } from '@/lib/cms-mappers';
import { mapCmsSeoDataToSeoMeta } from '@/lib/cms-mappers/common.mappers';

const profileSettingsPopulateFields = [
  'discover_services.steps.images',
  'seo.image',
  'featured_offer',
];

/**
 * Returns profile settings data fetched from CMS (Strapi)
 */
export async function useCmsProfileSettings(): UseCmsResponse<
  ProfileSettings,
  'seoData'
> {
  const { data, error, pending, refresh } = await useStrapi('profile', {
    offlineSSRFallback: true,
    watchLocale: true,
    queryParams: {
      populate: profileSettingsPopulateFields.join(','),
    },
  });

  const mappedData = computed(() =>
    mapCmsProfileDataToProfileSettings(data.value?.data)
  );

  const seoData = computed(() =>
    mapCmsSeoDataToSeoMeta(data.value?.data.attributes?.seo)
  );

  return { data: mappedData, seoData, error, pending, refresh };
}
