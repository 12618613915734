import { navigateTo, abortNavigation } from '@/lib/core/router';
import { createLogger } from '@/lib/core/logger';

import type { RouteLocationNormalized } from 'vue-router';

/**
 * Route access guard. Handles navigation access checks, and redirects when checks are failing
 * @param to - Requested route by the user
 * @param from - Origin route of the user
 */
export function checkProfileAccess(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized
): ReturnType<typeof navigateTo> {
  const logger = createLogger('access.profile');

  const toName = String(to.name);

  // Check access only applies to profile routes
  if (!/^profile/.test(toName)) return;

  logger.debug(`Profile route asked. Checking profile access...`);

  const authStore = useAuthStore();
  const registerStore = useProfileRegisterStore();

  // Keep the requested route only if not related login or registration
  const redirectToQuery =
    /^profile-/.test(toName) &&
    !/^profile-auth/.test(toName) &&
    !/^profile-register/.test(toName)
      ? { redirect_to: encodeURIComponent(to.path) }
      : null;

  // User is not logged in
  if (
    !authStore.isLoggedIn &&
    authStore.authType !== 'register' &&
    !/^profile-auth/.test(toName)
  ) {
    logger.debug(
      `User is not logged in. Redirecting from ${toName} to profile-auth.`
    );
    return navigateTo({
      name: 'profile-auth',
      ...(redirectToQuery && { query: { ...redirectToQuery } }),
    });
  }

  // User is logged in, but register process is not fulfilled
  if (
    authStore.isLoggedIn &&
    !registerStore.isProfileCompleted() &&
    !/^profile-register/.test(toName)
  ) {
    const nextStepName = getNextRegisterStepRoute();
    logger.debug(
      `User is logged-in. Redirecting from ${toName} to next register step ${nextStepName}.`
    );
    return navigateTo({ name: nextStepName });
  }

  // User has just logged in, and have a requested route (redirect_to)
  if (authStore.isLoggedIn) {
    if (
      authStore.authType === 'login' &&
      to.query?.redirect_to &&
      to.path !== to.query.redirect_to
    ) {
      const decodedRedirectTo = decodeURIComponent(
        String(to.query.redirect_to)
      );
      logger.debug(
        `User just logged in. Redirecting from ${toName} to ${decodedRedirectTo}.`
      );
      return navigateTo({ path: decodedRedirectTo });
    }
  }

  switch (true) {
    case toName === 'profile-auth':
      // This condition covers a use case which is not supposed to happen in real life, as 'loginId' is set only on 'profile-auth' route
      // and navigation from 'profile-auth-confirmation' to 'profile-auth' clears the 'loginId' field
      if (authStore.loginId) {
        const redirectName =
          authStore.authType === 'login'
            ? 'profile-auth-confirmation'
            : 'profile-register-confirmation';
        logger.debug(
          `User has a login id. Redirecting from ${toName} to ${redirectName}.`
        );
        return navigateTo({
          name: redirectName,
          ...(redirectToQuery &&
            authStore.authType === 'login' && {
              query: { ...redirectToQuery },
            }),
        });
      }

      if (authStore.isLoggedIn) {
        const redirectName = registerStore.isProfileCompleted()
          ? 'profile'
          : 'profile-register';
        logger.debug(
          `User is logged in. Redirecting from ${toName} to ${redirectName}.`
        );
        return navigateTo({ name: redirectName });
      }
      break;

    case toName === 'profile-auth-confirmation':
      if (!authStore.loginId) {
        logger.debug(
          `User has no login id. Redirecting from ${toName} to profile-auth.`
        );
        return navigateTo({ name: 'profile-auth' });
      }

      if (authStore.isLoggedIn) {
        const redirectName = registerStore.isProfileCompleted()
          ? 'profile'
          : 'profile-register';
        logger.debug(
          `User is logged in. Redirecting from ${toName} to ${redirectName}.`
        );
        return navigateTo({ name: redirectName });
      }
      break;

    case /profile-register.*/.test(toName):
      if (registerStore.isProfileCompleted()) {
        if (from.name === toName) {
          logger.debug(
            `User has a full register. Redirecting from ${toName} to profile.`
          );
          return navigateTo({ name: 'profile' });
        }
        logger.debug(
          `User has a full register. Aborting navigation from ${toName}.`
        );
        abortNavigation();
        return;
      }

      const toStepName = toName.match(
        /profile-register-(.*)/
      )?.[1] as RegisterStepName;
      if (!registerStore.isStepAllowed(toStepName)) {
        const nextStepName = getNextRegisterStepRoute();
        logger.debug(
          `User has not completed the step. Redirecting from ${toName} to ${nextStepName}.`
        );
        return navigateTo({ name: nextStepName });
      }
      break;

    case toName === 'profile':
      if (!registerStore.isProfileCompleted()) {
        const nextStepName = getNextRegisterStepRoute();
        logger.debug(
          `User has not completed the profile. Redirecting from ${toName} to ${nextStepName}.`
        );
        return navigateTo({ name: nextStepName });
      }

      if (registerStore.showDiscover) {
        registerStore.showDiscover = false;
        if (from.name === 'profile-discover') {
          logger.debug(
            `User will discover the service as expected. No redirection needed.`
          );
          return;
        }
        logger.debug(
          `User must discover the service. Redirecting from ${toName} to profile-discover.`
        );
        return navigateTo({ name: 'profile-discover' });
      }
      break;
  }
  logger.debug(`No redirection needed.`);
  return;
}
