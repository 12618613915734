import { mapCmsPageStatusDataToPageStatus } from '@/lib/cms-mappers';

/**
 * Returns settings data fetched from CMS (Strapi)
 */
export async function useCmsPageStatus(): UseCmsResponse<PageStatus> {
  const { data, error, pending, refresh } = await useStrapi('page-status', {
    watchLocale: true,
    offlineSSRFallback: true,
  });

  const mappedData = computed(() =>
    mapCmsPageStatusDataToPageStatus(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
