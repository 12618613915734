import { mapCmsInternalSettingsDataToInternalSettings } from '@/lib/cms-mappers';

const internalSettingsPopulateFields = [
  'modules',
  'services',
  'features',
  'font_primary',
  'font_secondary',
  'font_styles.properties',
  'colors.neutral',
  'colors.primary',
  'colors.secondary',
  'colors.success',
  'colors.info',
  'colors.warning',
  'colors.danger',
  'stations_map_config',
  'dynamic_links_configs.sharing',
  'dynamic_links_configs.leasing',
  'identity_fields',
  'opt_in_fields',
];

/**
 * * Returns internal Showcase settings data fetched from CMS (Strapi)
 */
export async function useCmsInternalSettings(): UseCmsResponse<InternalSettings> {
  const { data, error, pending, refresh } = await useStrapi(
    'internal-settings',
    {
      offlineSSRFallback: true,
      queryParams: {
        populate: [internalSettingsPopulateFields.join(',')],
      },
    }
  );

  const mappedData = computed(() =>
    mapCmsInternalSettingsDataToInternalSettings(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
