<template lang="pug">
section.FcwSection(
  :class="classes"
  :style="style"
)
  slot
</template>

<style lang="stylus">
.FcwSection
  overflow hidden
  position relative
  background var(--FcwSection--color)

  &--padded
    +media-down('md')
      padding 0 rem(48)

    +media-down('xs')
      padding 0 rem(24)

  &.FcwSection--colored
    padding rem(56) rem(48) rem(80)

    +media-down('sm')
      padding rem(32) rem(24) rem(64)

  &--rounded
    border-top-right-radius rem(16)
    border-top-left-radius rem(16)
</style>

<script setup lang="ts">
export interface FcwSectionProps {
  /**
   * Background color of the section
   */
  color?: Color;
  /**
   * Shape of the corners
   */
  corners?: 'sharp' | 'rounded';
  /**
   * Remove section's padding
   */
  noPadding?: boolean;
}

const props = withDefaults(defineProps<FcwSectionProps>(), {
  color: 'initial',
  corners: 'rounded',
  noPadding: false,
});

const style = computed<Style>(() => ({
  '--FcwSection--color': getCssColor(props.color),
}));

const classes = computed<VueClasses>(() => ({
  'FcwSection--rounded': props.corners === 'rounded',
  'FcwSection--colored': props.color !== 'initial',
  'FcwSection--padded': !props.noPadding,
}));
</script>
