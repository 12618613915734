/**
 * Set the document title by suffixing it with the client name
 */
export function useClientTitle(title: MaybeRef<string>): void {
  const { clientState } = useClientStore();

  useHead(
    computed(() => ({
      title: unref(title)
        ? `${unref(title)} - ${clientState.name}`
        : clientState.name,
    }))
  );
}
