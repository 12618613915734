import type { RouteLocationNormalized } from 'vue-router';

/**
 * Check if a route is unlocalized
 * @param route - Vue Router route
 */
export function isUnlocalizedRoute(route: RouteLocationNormalized): boolean {
  return String(route.name).startsWith('unlocalized-');
}

/**
 * Check if a route is a profile route
 * @param route - Vue Router route
 */
export function isProfileRoute(route: RouteLocationNormalized): boolean {
  return String(route.name).startsWith('profile');
}
