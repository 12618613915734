import { CwAppErrorCode } from '@/models/enums';

import type { Stripe } from '@stripe/stripe-js';

/**
 * Stripe partial instance, returning only the needed methods
 */
interface StripeReturn {
  createPaymentMethod: Stripe['createPaymentMethod'];
  paymentRequest: Stripe['paymentRequest'];
  confirmCardPayment: Stripe['confirmCardPayment'];
  confirmCardSetup: Stripe['confirmCardSetup'];
  confirmPayPalPayment: Stripe['confirmPayPalPayment'];
  confirmPayPalSetup: Stripe['confirmPayPalSetup'];
  elements: Stripe['elements'];
}

type UseStripeReturn = Ref<StripeReturn | null>;

/**
 * Returns a Stripe instance, or create it if it doesn't exists
 */
export async function useStripe(): Promise<UseStripeReturn> {
  const config = useRuntimeConfig();
  const stripe = useState<StripeReturn | null>('useStripe.stripe', () =>
    shallowRef(null)
  );

  onMounted(async () => {
    if (!stripe.value) {
      const { loadStripe } = await import('@stripe/stripe-js');

      const _stripe = await loadStripe(config.public.stripePublicKey);
      if (!_stripe) {
        throw useErrorAlert(CwAppErrorCode.StripeInstanciationFailed);
      }

      stripe.value = {
        createPaymentMethod: _stripe.createPaymentMethod,
        paymentRequest: _stripe.paymentRequest,
        confirmCardPayment: _stripe.confirmCardPayment,
        confirmCardSetup: _stripe.confirmCardSetup,
        confirmPayPalPayment: _stripe.confirmPayPalPayment,
        confirmPayPalSetup: _stripe.confirmPayPalSetup,
        elements: _stripe.elements,
      };
    }
  });

  return stripe;
}
