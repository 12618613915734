import { createConsola } from 'consola';

import type { ConsolaInstance } from 'consola';

/**
 * Universal logger, usable inside ou outside a Nuxt context
 * @param tagName - Tag name of the logger
 */
export function createLogger(tagName: string): ConsolaInstance {
  let logLevel: number;
  try {
    const config = useRuntimeConfig();
    logLevel = useState<number>(
      'createLogger.logLevel',
      () => config.logLevel
    ).value;
  } catch {
    logLevel = Number(process.env.NUXT_LOG_LEVEL);
  }

  const consola = createConsola({ level: logLevel });
  const logger = consola.withTag(tagName);

  return logger;
}
