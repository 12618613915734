<template lang="pug">
FLocaleSelect.FcwLocaleSelect(
  v-if="flagCodes.length > 1"
  v-model="langValue"
  :locales="flagCodes"
  color="transparent"
  text-color="secondary--light-1"
  focus-color="transparent"
  border-color="secondary--light-1"
  focus-border-color="secondary--light-1"
  outline-color="secondary"
  options-menu-color="neutral--light-5"
  option-text-color="secondary"
  selected-option-color="neutral--light-3"
  selected-option-text-color="secondary"
  menu-width="112"
  :size="size"
  hide-hint
  :option-label-format="formatLabelOption"
)
</template>

<script setup lang="ts">
import { getFlagFromLocale, getLocaleFromFlag } from '@/lib/helpers/locales';

import type { FLocaleSelectProps } from '@fifteen/design-system-vue';

export interface FcwLocaleSelectProps {
  /**
   * Size of the select
   */
  size?: FLocaleSelectProps['size'];
  /**
   * Lang display mode: `name` or `code`
   */
  displayMode?: 'name' | 'code';
}

const props = withDefaults(defineProps<FcwLocaleSelectProps>(), {
  size: 'medium',
  displayMode: 'code',
});

const { locale, setLocale } = useLocale();
const { clientState } = useClientStore();
const { track } = useUserTracking();

const langValue = computed<FLocaleSelectProps['modelValue']>({
  set(flagCode) {
    if (!flagCode) return;

    const newLocale = getLocaleFromFlag(flagCode);
    if (newLocale) {
      setLocale(newLocale);
      track.switchLocale({ uiLocale: newLocale });
    }
  },
  get() {
    return getFlagFromLocale(locale.value);
  },
});

const flagCodes = computed<FlagCode[]>(() =>
  clientState.locales
    .map(availableLocale => getFlagFromLocale(availableLocale) as FlagCode)
    .filter(flagCode => !!flagCode)
);

const { t } = useClientI18n();

function formatLabelOption(flagCode: string): string {
  const locale = getLocaleFromFlag(flagCode as FlagCode);
  return props.displayMode === 'name'
    ? t(`common__lang__${locale ?? ''}`)
    : locale?.toLocaleUpperCase() ?? '';
}
</script>
