import type { I18nMessages } from '../../types';

interface UseI18nMessagesReturn {
  fetchMessages(
    locale: ISOLocale,
    defaultLocale: ISOLocale
  ): Promise<I18nMessages>;
}

/**
 * Fetch translations (loco) API for a given app locale
 */
export function useI18nMessages(): UseI18nMessagesReturn {
  async function fetchMessages(
    locale: ISOLocale,
    defaultLocale: ISOLocale
  ): Promise<I18nMessages> {
    const data = await $fetch(`/api/app/translations`, {
      query: {
        locale,
        defaultLocale,
      },
    });
    return data ?? {};
  }

  return { fetchMessages };
}
