/**
 * Given a Strapi Component, return its content data as a key-value object.
 * @param component - Raw Strapi component data from the CMS
 */
export function getCmsComponentData<
  Component extends StrapiComponent | undefined
>(
  component: Component
): Component extends undefined
  ? Record<string, never>
  : Omit<Component, 'id' | '__component'> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, __component, ...content } = component ?? {};
  return content as Component extends undefined
    ? Record<string, never>
    : Omit<Component, 'id' | '__component'>;
}
