/**
 * Returns the next register step route name in the flow.
 * A next register step is a step with a status different of `completed`
 * @param stepName - Current register step name
 */
export function getNextRegisterStepRoute(stepName?: RegisterStepName): string {
  const registerStore = useProfileRegisterStore();

  const firstIncompleteStep = registerStore.steps.find(
    step => step.status === RegisterStepStatus.None
  );

  const nextStep = stepName
    ? registerStore.getNextStep(stepName)
    : firstIncompleteStep?.name;
  return nextStep
    ? `profile-register-${nextStep}`
    : registerStore.showDiscover
    ? `profile-discover`
    : `profile`;
}

/**
 * Returns the previous register step route name in the flow.
 * @param stepName - Current register step name
 */
export function getPreviousRegisterStepRoute(
  stepName?: RegisterStepName
): string {
  const registerStore = useProfileRegisterStore();
  const previousStep = stepName
    ? registerStore.getPreviousStep(stepName)
    : null;

  return previousStep ? `profile-register-${previousStep}` : `profile-auth`;
}
