enum PublicationState {
  Published = 'published',
  Preview = 'preview',
}

export function mapCmsPageStatusDataToPageStatus(
  internalSettingsRawData: StrapiResponseData<PageStatusContentType> | undefined
): PageStatus {
  const cmsData = internalSettingsRawData?.attributes;

  const singleTypesPublicationState = cmsData?.single_types;

  return {
    pages: {
      showcaseAbout:
        singleTypesPublicationState?.about === PublicationState.Published,
      showcaseBlog:
        singleTypesPublicationState?.blog === PublicationState.Published,
      showcaseHome:
        singleTypesPublicationState?.['showcase-home'] ===
        PublicationState.Published,
      showcaseSharing:
        singleTypesPublicationState?.['showcase-sharing'] ===
        PublicationState.Published,
      showcaseLeasing:
        singleTypesPublicationState?.['showcase-leasing'] ===
        PublicationState.Published,
      showcaseHelp:
        singleTypesPublicationState?.['showcase-help'] ==
        PublicationState.Published,
      showcaseMap:
        singleTypesPublicationState?.['showcase-map'] ===
        PublicationState.Published,
      referralSharing:
        singleTypesPublicationState?.['referral-sharing'] ===
        PublicationState.Published,
      referralLeasing:
        singleTypesPublicationState?.['referral-leasing'] ===
        PublicationState.Published,
    },
  };
}
