import clone from 'fast-copy';

import type { PiniaPluginContext } from 'pinia';

/**
 * Pinia plugin to reset a store, as $reset method is not available when Pinia is used with composition api
 * @param pluginContext - Pinia plugin context
 * @param pluginContext.store - Pinia store
 */
export default function resetStorePlugin({ store }: PiniaPluginContext): void {
  const initialState = clone(store.$state.initialState);
  store.$reset = () => store.$patch(clone(initialState));
}
