import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

import type { UserIdentityFields } from '@/models/app/profile';

export type RegisterStepName =
  | 'identity'
  | 'confirmation'
  | 'transport-card'
  | 'payment-method';

export enum RegisterStepStatus {
  // A Completed step status means that the step is complete
  Completed = 'completed',
  // A Finished step status means that the step is complete, but no accessible again
  Finished = 'finished',
  // A None step status means that the step is untouched
  None = '',
  // A Skipped step means that the step is explicitely skipped. It's still accessible
  Skipped = 'skipped',
}

export type RegisterStep = {
  name: RegisterStepName;
  status: RegisterStepStatus;
};

type RegisterInitialState = {
  steps: RegisterStep[];
  showDiscover: boolean;
  userIdentity: UserIdentityFields;
  googlePlacesSession?: string;
};

export const useProfileRegisterStore = defineStore('register', () => {
  // A reactive initial state it's needed and used by the reset store plugin
  const initialState = reactive<RegisterInitialState>({
    steps: [],
    showDiscover: true,
    userIdentity: {},
    googlePlacesSession: undefined,
  });

  const api = useApi();

  const steps = ref<RegisterStep[]>(initialState.steps);

  // Show discover screen when registration process is complete
  const showDiscover = ref(initialState.showDiscover);
  const userIdentity = ref(initialState.userIdentity);
  const googlePlacesSession = ref(initialState.googlePlacesSession);

  watch(showDiscover, value => !value && skipDiscover());

  /**
   * Refresh signup steps
   */
  async function loadSteps(authType: AuthType): Promise<void> {
    if (authType === 'register') {
      showDiscover.value = false;
      addStep('identity');
      addStep('confirmation');

      const { profileClientState } = useProfileStore();
      if (profileClientState.features.rfid) {
        addStep('transport-card');
      }
      addStep('payment-method');
      showDiscover.value = true;
    }
  }

  /**
   * Complete a step
   * @param stepName - Register step name
   * @param finishStep - Move the step to the "finished" state
   */
  function completeStep(stepName: RegisterStepName, finishStep = false): void {
    const step = steps.value.find(step => step.name === stepName);
    if (!step) return;

    step.status = finishStep
      ? RegisterStepStatus.Finished
      : RegisterStepStatus.Completed;
  }

  function addStep(
    stepName: RegisterStepName,
    status: RegisterStepStatus = RegisterStepStatus.None
  ): void {
    if (steps.value.find(step => step.name === stepName)) return;
    steps.value.push({ name: stepName, status });
  }

  function skipStep(stepName: RegisterStepName): void {
    const step = steps.value.find(step => step.name === stepName);
    if (!step) return;

    step.status = RegisterStepStatus.Skipped;
  }

  async function skipDiscover(): Promise<void> {
    await api.post('/user/register/skip-discover');
  }

  function getNextStep(stepName: RegisterStepName): RegisterStepName {
    const stepIndex = steps.value.findIndex(step => step.name === stepName);
    return steps.value?.[stepIndex + 1]?.name || null;
  }

  function getPreviousStep(stepName: RegisterStepName): RegisterStepName {
    const stepIndex = steps.value.findIndex(step => step.name === stepName);
    return steps.value[stepIndex - 1]?.name || null;
  }

  function isProfileCompleted(): boolean {
    return steps.value.every(step => step.status !== RegisterStepStatus.None);
  }

  function isStepValid(stepName: RegisterStepName): boolean {
    const step = steps.value.find(step => step.name === stepName);

    return (
      // We want to consider inexistant step valid by default
      // The main reason is that, in the flow, we test the status of the previous register step (required to access to the next one)
      !step ||
      [
        RegisterStepStatus.Completed,
        RegisterStepStatus.Finished,
        RegisterStepStatus.Skipped,
      ].includes(step.status)
    );
  }

  function isStepAllowed(stepName: RegisterStepName): boolean {
    const step = steps.value.find(step => step.name === stepName);
    if (!step) return false;

    const isPreviousStepValid = isStepValid(getPreviousStep(stepName));

    return (
      (isPreviousStepValid ||
        [RegisterStepStatus.Completed, RegisterStepStatus.Skipped].includes(
          step.status
        )) &&
      step.status !== RegisterStepStatus.Finished
    );
  }

  return {
    initialState,
    steps,
    isProfileCompleted,
    completeStep,
    skipStep,
    getNextStep,
    getPreviousStep,
    isStepValid,
    isStepAllowed,
    loadSteps,
    showDiscover,
    userIdentity,
    googlePlacesSession,
  };
});
