import { keysOf } from '@fifteen/shared-lib';

/** Merges two objects without mutating the original objects, skipping properties with
 * `undefined`, `null` and empty string values from the merged object.
 * @returns {T & U} - A new object that is the result of merging objA and objB
 */
export function mergeObjects<
  T extends Record<string, string>,
  U extends Record<string, string>
>(objectA: T, objectB: U): T & U {
  const result: T & U = { ...objectA } as T & U;

  for (const key in objectB) {
    if (
      objectB.hasOwnProperty(key) &&
      objectB[key] !== undefined &&
      objectB[key] !== null &&
      objectB[key] !== ''
    ) {
      result[key] = objectB[key] as (T & U)[Extract<keyof U, string>];
    }
  }

  return result;
}

/**
 * Hashes an object to a string.
 * The object's keys are sorted before being concatenated with their values.
 */
export function objectToStringSorted(obj: object): string {
  const sortedKeys = keysOf(obj).sort();

  return sortedKeys.reduce(
    (acc, key) => `${acc !== '' ? `${acc}-` : acc}${key}${obj[key]}`,
    ''
  );
}
