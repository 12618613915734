/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'fifteencustomerwebsite';

export const ApiKey: Record<Environment, string> = {
  fifteencustomerwebsite: 'e0f818b66d123ed2acd527e40de99b50',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: '2c915744-52d9-4eb2-8047-f215ef93747f',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
  serverZone: amplitude.Types.ServerZone.EU,
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & {
  client: { apiKey: string; configuration?: BrowserOptions };
};
export type LoadOptionsWithClientInstance = LoadOptionsBase & {
  client: { instance: BrowserClient };
};

export type LoadOptions =
  | LoadOptionsWithEnvironment
  | LoadOptionsWithApiKey
  | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  serviceName?: string;
  uiLocale?: string;
}

export interface ConsentToGpsDataStorageProperties {
  consentDate?: string;
  consentLastUpdate?: string;
  consentValue?: boolean;
}

export interface MoveThroughSignupStepProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | flow_startup, identity_completion, otp_confirmation, transport_card_adding, payment_method_adding |
   */
  stepNameFrom?:
    | 'flow_startup'
    | 'identity_completion'
    | 'otp_confirmation'
    | 'transport_card_adding'
    | 'payment_method_adding';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | flow_startup, identity_completion, otp_confirmation, transport_card_adding, payment_method_adding |
   */
  stepNameTo?:
    | 'flow_startup'
    | 'identity_completion'
    | 'otp_confirmation'
    | 'transport_card_adding'
    | 'payment_method_adding';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | finished, completed, skipped, back |
   */
  stepStatusFrom?: 'finished' | 'completed' | 'skipped' | 'back';
}

export interface OpenAContactLinkProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, phone |
   */
  contactMode?: 'email' | 'phone';
}

export interface ResendOtpRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | signup, login |
   */
  authMode?: 'signup' | 'login';
}

export interface SelectPhoneCountryCodeProperties {
  countryCode?: string;
}

export interface SendOtpRequestProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | signup, login |
   */
  authMode?: 'signup' | 'login';
}

export interface SubmitUserIdentityFormProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  updatedUserFields?: string[];
}

export interface SwitchLocaleProperties {
  uiLocale: string;
}

export interface ToggleServiceTypeOnMobileAppsCarouselProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sharing, leasing |
   */
  serviceType?: 'sharing' | 'leasing';
}

export interface TriggerAnAppStoreButtonProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | sharing_app_ios, sharing_app_android, leasing_app_ios, leasing_app_android |
   */
  mobileAppName?:
    | 'sharing_app_ios'
    | 'sharing_app_android'
    | 'leasing_app_ios'
    | 'leasing_app_android';
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class ConsentToGpsDataStorage implements BaseEvent {
  event_type = 'Consent to GPS data storage';

  constructor(public event_properties?: ConsentToGpsDataStorageProperties) {
    this.event_properties = event_properties;
  }
}

export class LogIn implements BaseEvent {
  event_type = 'Log in';
}

export class MoveThroughSignupStep implements BaseEvent {
  event_type = 'Move through signup step';

  constructor(public event_properties?: MoveThroughSignupStepProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenAContactLink implements BaseEvent {
  event_type = 'Open a contact link';

  constructor(public event_properties?: OpenAContactLinkProperties) {
    this.event_properties = event_properties;
  }
}

export class OpenLegalDocuments implements BaseEvent {
  event_type = 'Open legal documents';
}

export class ResendOtpRequest implements BaseEvent {
  event_type = 'Resend OTP request';

  constructor(public event_properties?: ResendOtpRequestProperties) {
    this.event_properties = event_properties;
  }
}

export class SelectPhoneCountryCode implements BaseEvent {
  event_type = 'Select phone country code';

  constructor(public event_properties?: SelectPhoneCountryCodeProperties) {
    this.event_properties = event_properties;
  }
}

export class SendOtpRequest implements BaseEvent {
  event_type = 'Send OTP request';

  constructor(public event_properties?: SendOtpRequestProperties) {
    this.event_properties = event_properties;
  }
}

export class SignUp implements BaseEvent {
  event_type = 'Sign up';
}

export class SubmitUserIdentityForm implements BaseEvent {
  event_type = 'Submit user identity form';

  constructor(public event_properties?: SubmitUserIdentityFormProperties) {
    this.event_properties = event_properties;
  }
}

export class SwitchLocale implements BaseEvent {
  event_type = 'Switch locale';

  constructor(public event_properties: SwitchLocaleProperties) {
    this.event_properties = event_properties;
  }
}

export class ToggleServiceTypeOnMobileAppsCarousel implements BaseEvent {
  event_type = 'Toggle service type on mobile apps carousel';

  constructor(
    public event_properties?: ToggleServiceTypeOnMobileAppsCarouselProperties
  ) {
    this.event_properties = event_properties;
  }
}

export class TriggerAnAppStoreButton implements BaseEvent {
  event_type = 'Trigger an app store button';

  constructor(public event_properties?: TriggerAnAppStoreButtonProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Consent to GPS data storage
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Consent%20to%20GPS%20data%20storage)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. consentDate)
   * @param options Amplitude event options.
   */
  consentToGpsDataStorage(
    properties?: ConsentToGpsDataStorageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConsentToGpsDataStorage(properties), options);
  }

  /**
   * Log in
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Log%20in)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  logIn(
    options?: EventOptions,
  ) {
    return this.track(new LogIn(), options);
  }

  /**
   * Move through signup step
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Move%20through%20signup%20step)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. stepNameFrom)
   * @param options Amplitude event options.
   */
  moveThroughSignupStep(
    properties?: MoveThroughSignupStepProperties,
    options?: EventOptions,
  ) {
    return this.track(new MoveThroughSignupStep(properties), options);
  }

  /**
   * Open a contact link
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Open%20a%20contact%20link)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. contactMode)
   * @param options Amplitude event options.
   */
  openAContactLink(
    properties?: OpenAContactLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenAContactLink(properties), options);
  }

  /**
   * Open legal documents
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Open%20legal%20documents)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  openLegalDocuments(
    options?: EventOptions,
  ) {
    return this.track(new OpenLegalDocuments(), options);
  }

  /**
   * Resend OTP request
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Resend%20OTP%20request)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. authMode)
   * @param options Amplitude event options.
   */
  resendOtpRequest(
    properties?: ResendOtpRequestProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResendOtpRequest(properties), options);
  }

  /**
   * Select phone country code
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Select%20phone%20country%20code)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. countryCode)
   * @param options Amplitude event options.
   */
  selectPhoneCountryCode(
    properties?: SelectPhoneCountryCodeProperties,
    options?: EventOptions,
  ) {
    return this.track(new SelectPhoneCountryCode(properties), options);
  }

  /**
   * Send OTP request
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Send%20OTP%20request)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. authMode)
   * @param options Amplitude event options.
   */
  sendOtpRequest(
    properties?: SendOtpRequestProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendOtpRequest(properties), options);
  }

  /**
   * Sign up
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Sign%20up)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUp(
    options?: EventOptions,
  ) {
    return this.track(new SignUp(), options);
  }

  /**
   * Submit user identity form
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Submit%20user%20identity%20form)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. updatedUserFields)
   * @param options Amplitude event options.
   */
  submitUserIdentityForm(
    properties?: SubmitUserIdentityFormProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitUserIdentityForm(properties), options);
  }

  /**
   * Switch locale
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Switch%20locale)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. uiLocale)
   * @param options Amplitude event options.
   */
  switchLocale(
    properties: SwitchLocaleProperties,
    options?: EventOptions,
  ) {
    return this.track(new SwitchLocale(properties), options);
  }

  /**
   * Toggle service type on mobile apps carousel
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Toggle%20service%20type%20on%20mobile%20apps%20carousel)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. serviceType)
   * @param options Amplitude event options.
   */
  toggleServiceTypeOnMobileAppsCarousel(
    properties?: ToggleServiceTypeOnMobileAppsCarouselProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleServiceTypeOnMobileAppsCarousel(properties), options);
  }

  /**
   * Trigger an app store button
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/fifteen/Fifteen%20-%20Customer%20Website/events/main/latest/Trigger%20an%20app%20store%20button)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. mobileAppName)
   * @param options Amplitude event options.
   */
  triggerAnAppStoreButton(
    properties?: TriggerAnAppStoreButtonProperties,
    options?: EventOptions,
  ) {
    return this.track(new TriggerAnAppStoreButton(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
