import { mapCmsShowcaseNavigationToNavigation } from '@/lib/cms-mappers/showcase/navigation.mappers';

import type { FcwNavbarProps } from '@/components/organisms/FcwNavbar.vue';

/**
 * Returns navigation data fetched from CMS (Strapi), based on its slug
 * @param slug - Navigation slug
 */
export async function useCmsNavigation(
  slug: 'showcase-navigation'
): UseCmsResponse<FcwNavbarProps['links']> {
  const { data, error, pending, refresh } = await useStrapi(
    'navigation',
    {
      watchLocale: true,
      offlineSSRFallback: true,
      queryParams: { type: 'TREE' },
    },
    slug
  );

  const mappedData = computed(() =>
    mapCmsShowcaseNavigationToNavigation(data.value)
  );

  return { data: mappedData, error, pending, refresh };
}
