import type { ComputedRef } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import type {
  NavLink,
  FcwNavbarProps,
} from '@/components/organisms/FcwNavbar.vue';

/**
 * Filter the menu items to keep only those that are enabled
 * @param menuItems - Array of nav links (NavLink)
 */
function filterMenu(menuItems?: NavLink[]): NavLink[] {
  const { homepage } = useClientStore();

  return (menuItems ?? []).reduce<NavLink[]>((acc, menuItem) => {
    if (menuItem.submenu?.length) {
      const filteredSubmenu = filterMenu(menuItem.submenu);

      // If submenu has items after filtering, we keep the menu item and the filtered submenu in the filtered menu
      if (filteredSubmenu.length) {
        acc.push({
          ...menuItem,
          submenu: filteredSubmenu,
        });
        return acc;
      }
    }

    if (
      menuItem.enabled &&
      // Extra logic to get only:
      // - routes that doesn't have a name (external route)
      (!menuItem.to?.hasOwnProperty('name') ||
        // - or any internal route (with a name), excluding the homepage (as handled with logo click)
        (menuItem.to?.hasOwnProperty('name') &&
          (menuItem.to as RouteLocationNamedRaw).name !== homepage.name))
    ) {
      acc.push(menuItem);
    }

    return acc;
  }, []);
}

/**
 * Returns FcwNavbar links, filtered on enabled services
 */
export async function useClientNavbar(
  layout: 'showcase' | 'minimal' | 'auto' = 'auto'
): Promise<ComputedRef<FcwNavbarProps['links']>> {
  const clientStore = useClientStore();
  const { clientState } = storeToRefs(clientStore);

  const { data: showcaseLinks } = await useCmsNavigation('showcase-navigation');

  const autoLayout = clientState.value.modules.showcase
    ? 'showcase'
    : 'minimal';
  const activeLayout = layout === 'auto' ? autoLayout : layout;

  const minimalLinks = computed<NavLink[]>(() => []);

  return computed(() =>
    filterMenu(
      activeLayout === 'showcase' ? showcaseLinks.value : minimalLinks.value
    )
  );
}
