import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

import { ServiceType } from '@/models/enums';

import type { SeoMetaOptions } from '@/composables/useClientSeoMeta';
import type { CurrencyCode } from '@fifteen/shared-lib';

interface ProfileDiscoverService {
  serviceType: ServiceType;
  steps: ProfileDiscoverStep[];
}
interface ProfileDiscoverStep {
  title: string;
  text: string;
  images: { src: string; alt: string }[];
}
export interface ProfileFeaturedOffer {
  name: string;
  context: ServiceType.Sharing | ServiceType.Leasing | 'other';
  plan: {
    price: number;
    interval: string;
    currency: CurrencyCode;
  };
  ruleTexts: string[];
  ctaText: string;
  ctaLink: string;
  summary: string;
}

export interface ProfileSettings {
  discoverServices: ProfileDiscoverService[];
  featuredOffer: ProfileFeaturedOffer;
}

export interface InternalProfileSettings {
  features: {
    rfid?: boolean;
    wallet?: boolean;
    zendesk_chat?: boolean;
  };
}

export interface ProfileSeo {
  seo: SeoMetaOptions;
}

export type ProfileClientState = ProfileSettings &
  InternalProfileSettings &
  ProfileSeo;

export const useProfileStore = defineStore('profile', () => {
  const profileClientState = ref<ProfileClientState>({
    discoverServices: [],
    featuredOffer: {
      name: '',
      context: 'other',
      plan: {
        price: 0,
        interval: '',
        currency: 'EUR',
      },
      ruleTexts: [],
      ctaText: '',
      ctaLink: '',
      summary: '',
    },
    features: {},
    seo: {},
  });

  async function init(): Promise<void> {
    const [
      { data: profileSettings, seoData: profileSeoData },
      { data: internalProfileSettings },
    ] = await Promise.all([
      useCmsProfileSettings(),
      useCmsInternalProfileSettings(),
    ]);

    watchImmediate(
      [profileSettings, profileSeoData, internalProfileSettings],
      () => {
        profileClientState.value = {
          ...profileSettings.value,
          ...internalProfileSettings.value,
          seo: profileSeoData.value,
        };
      }
    );

    // Attach context to Sentry
    Sentry.setContext('profile', {
      features: profileClientState.value.features,
    });
  }

  return {
    init,
    profileClientState,
  };
});
