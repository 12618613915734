<template lang="pug">
footer.FcwFooter
  .FcwFooter__mainContent
    .FcwFooter__metropolisLogoSection(v-if="metropolisLogoLight?.src")
      FImage(
        v-bind="metropolisLogoLight"
        width="124"
        loading="eager"
        corners="sharp"
      )
    .FcwFooter__sections
      .FcwFooter__section(
        v-if="practicalInfoLinks.length || address || openingHours"
      )
        FDivider.FcwFooter__sectionDivider(
          vertical
          height="100%"
          color="neutral--dark-4"
        )
        FcwFooterSection(
          :title="practicalInfoTitle"
          :items="practicalInfoLinks"
        )
          template(#prefix)
            address.FcwFooter__address(v-if="address")
              p {{ address }}
      .FcwFooter__section(
        v-if="serviceLinks.length || customerServiceLinks.length"
      )
        FDivider.FcwFooter__sectionDivider(
          vertical
          height="100%"
          color="neutral--dark-4"
        )
        FcwFooterSection(
          v-if="serviceLinks.length"
          :title="servicesTitle"
          :items="serviceLinks"
        )
          template(#suffix)
            FButton.FcwFooter__downloadAppLink(
              v-if="!isSmAndDown && (clientState.sharingAppStoreLink || clientState.sharingPlayStoreLink)"
              background="neutral--light-5"
              @click="isMobileAppStoresModalVisible = !isMobileAppStoresModalVisible"
            ) {{ t('common__download_app') }}
        FcwFooterSection(
          v-if="customerServiceLinks.length"
          :title="customerServiceTitle"
          :items="customerServiceLinks"
          @link-select="trackOpenAContactLink"
        )

      .FcwFooter__section(
        v-if="blogPostLinks.length || socialLinks.length || $slots['cta']"
      )
        FDivider.FcwFooter__sectionDivider(
          vertical
          height="100%"
          color="neutral--dark-4"
        )
        FcwFooterSection(
          v-if="blogPostLinks.length"
          :title="blogPostTitle"
          :items="blogPostLinks"
        )
        FcwFooterSection(
          v-if="socialLinks.length"
          :title="socialLinksTitle"
        )
          template(#suffix)
            .FcwFooter__socialLinks
              FcwRouterLink(
                v-for="(link, index) in socialLinks"
                :key="index"
                :location="link.location"
                color="neutral-light-5"
                hide-arrow
                target="_blank"
              )
                FIcon(:name="link.icon")

        .FcwFooter__cta(v-if="$slots['cta']")
          slot(name="cta")
      ClientOnly
        FcwLocaleSelect.FcwFooter__localeSelect--mobile(v-if="isXsAndDown")

  p.FcwFooter__bottomLine
    template(
      v-for="(link, index) in bottomLineLinks"
      :key="index"
    )
      FcwRouterLink(
        :location="link.location"
        hide-arrow
        color="neutral--light-5"
        :external="link.external"
      ) {{ link.label }}
      span(v-if="isNotLastLink(index)") -
    span(v-if="bottomLineLinks.length") |
    span {{ editorText }}
    FcwRouterLink.FcwFooter__editorLink(
      color="neutral--light-5"
      location="https://fifteen.eu"
      target="_blank"
      hide-arrow
    ) Fifteen
  ClientOnly
    FcwLocaleSelect.FcwFooter__localeSelect--desktop(v-if="!isXsAndDown")
</template>

<style lang="stylus">
.FcwFooter
  position relative
  padding rem(56) rem(80)
  background-color var(--color--neutral--dark-5)
  color var(--color--neutral--light-5)
  border-top-right-radius rem(24)
  border-top-left-radius rem(24)

  +media-down('sm')
    padding rem(56) rem(64)

  +media-down('xs')
    padding rem(40)

.FcwFooter__mainContent
  display flex
  justify-content space-between
  margin-bottom rem(40)

  &:has(.FcwFooter__sections:empty,
  .FcwFooter__metropolisLogoSection:empty)
    margin-bottom rem(0)

  +media-down('sm')
    flex-direction column
    margin-bottom 0

.FcwFooter__socialLinks
  display flex
  align-items center
  margin-bottom rem(16)
  gap rem(16)

.FcwFooter__sections
  display flex
  justify-content flex-end
  flex-grow 1
  margin-bottom rem(40)

  &:empty
    margin-bottom rem(0)

  +media-down('sm')
    justify-content flex-start
    flex-wrap wrap

    .FcwFooter__section:first-of-type
      padding-left 0

  +media-down('xs')
    flex-direction column

.FcwFooter__section
  position relative
  padding 0 rem(48)

  +media-down('sm')
    &:nth-child(odd)
      padding-left 0

  .FcwFooter__section:nth-child(n+1)
    margin-top rem(40)

  &:first-of-type
    .FcwFooter__sectionDivider
      display none

  .FcwFooterSection:nth-child(n+3)
    margin-top rem(40)

  .FcwFooterSection
    > *:last-child
      margin-bottom 0

  +media-down('sm')
    flex 0 0 50%
    padding rem(40) rem(40) 0

    &:nth-child(odd)
      .FcwFooter__sectionDivider
        display none

  +media-down('xs')
    padding 0

    .FcwFooter__sectionDivider
      display none

.FcwFooterSection
  +media-down('sm')
    padding-left 0

  +media-down('xs')
    margin-top rem(40)

.FcwFooter__sectionDivider
  position absolute
  margin-bottom rem(16)
  left 0

  +media-down('xs')
    display none

.FcwFooter__bottomLine
  text-align center

  > * + *
    margin-left rem(8)

.FcwFooter__address
  font-style normal
  white-space pre-line
  margin-bottom rem(24)

.FcwFooter__cta:nth-child(n+3)
  margin-top rem(40)

.FcwFooter__downloadAppLink
  margin-top rem(24)

.FcwFooter__editorLink
  font-weight 600
  margin-left 0

  &::before
    content '\00a0'

.FcwFooter__localeSelect
  &--desktop
    position absolute
    left rem(64)
    bottom rem(48)

    +media-down('sm')
      left rem(48)
      bottom rem(16)

  &--mobile
    width rem(128)
    margin rem(32) auto 0
</style>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import type { FImageProps } from '@fifteen/design-system-vue';

export interface FooterLink {
  /**
   * Displayed label of the link
   */
  label: string;
  /**
   * Link location
   */
  location: RouteLocationRaw;
  /**
   * Whether the link is available
   */
  enabled?: boolean;
  /**
   * Whether the link should be opened in a new tab
   */
  external?: boolean;
  /**
   * Link type if specific
   */
  type?: 'mailto' | 'tel';
}

export interface FooterLinkWithIcon extends FooterLink {
  /**
   * Link icon
   */
  icon?: Icon;
}

export type FooterIconLink = Omit<FooterLinkWithIcon, 'label'>;

export interface FcwFooterProps {
  /**
   * Practical info section title
   */
  practicalInfoTitle?: string;
  /**
   * Practical info array of links
   */
  practicalInfoLinks?: FooterLink[];
  /**
   * Services section title
   */
  servicesTitle?: string;
  /**
   * Services section array of links
   */
  serviceLinks?: FooterLink[];
  /**
   * Blog post section title
   */
  blogPostTitle?: string;
  /**
   * Blog post section array of links
   */
  blogPostLinks?: FooterLink[];
  /**
   * Customer service section title
   */
  customerServiceTitle?: string;
  /**
   * Customer service section array of links
   */
  customerServiceLinks?: FooterLinkWithIcon[];
  /**
   * Social section title
   */
  socialLinksTitle?: string;
  /**
   * Social section array of links
   */
  socialLinks?: FooterIconLink[];
  /**
   * Links on the footer bottom
   */
  bottomLineLinks?: FooterLink[];
  /**
   * Address as a multiline text
   */
  address?: string;
  /**
   * Opening hours as a multiline text
   */
  openingHours?: string;
  /**
   * Editor text prefix
   */
  editorText?: string;
  /**
   * Metropolis logo
   */
  metropolisLogoLight?: Pick<FImageProps, 'src' | 'alt'> | null;
}

const props = withDefaults(defineProps<FcwFooterProps>(), {
  bottomLineLinks: () => [],
  practicalInfoTitle: '',
  practicalInfoLinks: () => [],
  serviceLinks: () => [],
  servicesTitle: '',
  blogPostTitle: '',
  blogPostLinks: () => [],
  customerServiceTitle: '',
  customerServiceLinks: () => [],
  socialLinksTitle: '',
  socialLinks: () => [],
  address: '',
  openingHours: '',
  editorText: '',
  metropolisLogoLight: null,
});

const { isXsAndDown, isSmAndDown } = useFBreakpoints();
const { t } = useClientI18n();
const { clientState } = storeToRefs(useClientStore());
const { isMobileAppStoresModalVisible } = storeToRefs(useGlobalStore());
const { track } = useUserTracking();

function isNotLastLink(index: number): boolean {
  return index < props.bottomLineLinks.length - 1;
}

function trackOpenAContactLink(link?: FooterLink): void {
  if (!link?.type) return;

  switch (link.type) {
    case 'tel':
      track.openAContactLink({
        contactMode: 'phone',
      });
      break;
    case 'mailto':
      track.openAContactLink({
        contactMode: 'email',
      });
      break;
  }
}
</script>
