<template lang="pug">
nav.FcwFooterSection
  h5.FcwFooterSection__title {{ title }}
  template(v-if="$slots['prefix']")
    slot(name="prefix")
  ul.FcwFooterSection__items(v-if="items.length")
    template(
      v-for="(item, index) in items"
      :key="index"
    )
      li.FcwFooterSection__item
        FcwRouterLink(
          v-if="item.location"
          :location="item.location"
          :external="item.external"
          color="neutral--light-5"
          hide-arrow
          @click="emit('link-select', item)"
        ) 
          FIcon.FcwFooterSection__itemIcon(
            v-if="'icon' in item"
            :name="item.icon"
            color="primary--light-1"
          )
          span.FcwFooterSection__itemLabel {{ item.label }}
        template(v-else)
          FIcon.FcwFooterSection__itemIcon(
            v-if="'icon' in item"
            :name="item.icon"
            color="primary--light-1"
          )
          span.FcwFooterSection__itemLabel {{ item.label }}
  template(v-if="$slots['suffix']")
    slot(name="suffix")
</template>

<style lang="stylus">
.FcwFooterSection
  position relative
  max-width rem(280)

.FcwFooterSection__title
  color var(--color--primary--light-1)
  font-weight 400
  margin-bottom rem(16)

.FcwFooterSection__items
  display flex
  flex-direction column
  gap rem(8)

.FcwFooterSection__item
  display flex
  align-items flex-start
  margin-top rem(8)

.FcwFooterSection__itemIcon
  margin-right rem(8)

.FcwFooterSection__itemLabel
  white-space pre-line
</style>

<script setup lang="ts">
import type {
  FooterLink,
  FooterLinkWithIcon,
} from '@/components/organisms/FcwFooter.vue';

export type FooterItem = Omit<FooterLink, 'link'>;

export interface FcwFooterSectionProps {
  /**
   * Title of the footer section
   */
  title?: string;
  /**
   * Items of the footer section
   */
  items?: (FooterLink | FooterLinkWithIcon | FooterItem)[];
}

withDefaults(defineProps<FcwFooterSectionProps>(), {
  title: '',
  items: () => [],
});

const emit = defineEmits<{
  (name: 'link-select', link?: FooterLink): void;
}>();
</script>
