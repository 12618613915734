import {
  mapCmsImageToImageProps,
  mapCmsSeoDataToSeoMeta,
} from './common.mappers';

import { getCmsComponentData } from '@/lib/utils';
import { ServiceType } from '@/models/enums';

export function mapCmsSettingsDataToSettings(
  settingsRawData: StrapiResponseData<SettingsContentType> | undefined
): ClientSettings {
  const cmsData = settingsRawData?.attributes;

  return {
    name: cmsData?.name ?? '',
    preferredServiceType:
      cmsData?.preferred_service_type ?? ServiceType.Sharing,
    logoSrc: mapCmsImageToImageProps(cmsData?.logo, 'small').src,
    logoLightSrc: mapCmsImageToImageProps(cmsData?.logo_light, 'small').src,
    metropolisLogoSrc: mapCmsImageToImageProps(
      cmsData?.metropolis_logo,
      'small'
    ).src,
    metropolisLogoLightSrc: mapCmsImageToImageProps(
      cmsData?.metropolis_logo_light,
      'small'
    ).src,
    faviconSrc: mapCmsImageToImageProps(cmsData?.favicon, 'small').src,
    sharingAppDeviceMockupSrc: mapCmsImageToImageProps(
      cmsData?.sharing_app_device_mockup
    ).src,
    leasingAppDeviceMockupSrc: mapCmsImageToImageProps(
      cmsData?.leasing_app_device_mockup
    ).src,
    address: cmsData?.address ?? '',
    openingHours: cmsData?.opening_hours ?? '',
    email: cmsData?.email ?? '',
    phoneNumber: cmsData?.phone_number ?? '',
    socialNetworks: (cmsData?.social_networks ?? []).map(getCmsComponentData),
    defaultSeo: mapCmsSeoDataToSeoMeta(cmsData?.seo),
    helpCenterLinks: (cmsData?.help_center_links ?? [])
      .map(({ key, link }) => ({
        key,
        link: link ?? '',
      }))
      .filter(({ link }) => link !== ''),
    mobileAppsOverview: {
      sharing: mapCmsMobileAppOverviewToMobileAppoverview(
        cmsData?.mobile_apps_overview?.sharing
      ),
      leasing: mapCmsMobileAppOverviewToMobileAppoverview(
        cmsData?.mobile_apps_overview?.leasing
      ),
    },
  };
}

function mapCmsMobileAppOverviewToMobileAppoverview(
  mobileAppOverview?: MobileAppOverview
): ClientMobileAppOverview | undefined {
  if (!mobileAppOverview) return;

  return {
    features: (mobileAppOverview.features ?? [])
      .map(feature => feature.text ?? '')
      .filter(feature => feature !== ''),
    device_mockup: mapCmsImageToImageProps(mobileAppOverview.device_mockup),
    carousel_items: (mobileAppOverview.carousel_items ?? []).map(item => ({
      text: item.text ?? '',
      image: mapCmsImageToImageProps(item.image),
    })),
  };
}
