import type { MaybeRef } from '@vueuse/core';
import type { Ref } from 'vue';

// 1 year expiration
const expires = new Date();
expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);

// We need this to be global, as it must be set once on client side
// The caveat is that ref is shared across all clients on server side, but it's not a problem here.
const isWatchSet = ref(false);

/**
 * Reactive cookie composable
 * @param name - Name of the cookie
 * @returns Cookie value ref
 */
export function useStatefulCookie<T>(
  name: string,
  defaultValue: MaybeRef<T>
): Ref<T> {
  const cookie = useCookie<T>(name, {
    expires,
    sameSite: 'lax',
    secure: true,
  });

  const state = useState(
    `useStatefulCookie.${name}.state`,
    () => cookie.value ?? unref(defaultValue)
  );

  if (!isWatchSet.value) {
    watch(state, () => {
      cookie.value = state.value;
    });
    isWatchSet.value = true;
  }

  return state;
}
