import { notNullish } from '@fifteen/shared-lib';

import type { I18nData } from '../types';

/**
 * Handle pluralization from message, as in vue-i18n
 * @param message - The message to parse
 * @param quantity - The quantity to use to pluralize the message
 */
export function pluralize(message: string, quantity: number): string {
  const options = message.split(/\s*\|\s*/);
  const normalizedQuantity = Math.abs(quantity);
  if (options.length >= 3) return options[Math.min(normalizedQuantity, 2)];
  if (options.length === 2) return options[normalizedQuantity === 1 ? 0 : 1];
  return options[0];
}

/**
 * Interpolate variables in the message, handling literals as in vue-i18n
 * @param message - The message to parse
 * @param data - The data to use to interpolate the message
 */
export function interpolate(message: string, data: I18nData): string {
  return message.replace(/{(.*?)}/g, (match, p1) => {
    return notNullish(data[p1])
      ? String(data[p1])
      : isLiteral(p1)
      ? getLiteral(p1)
      : match;
  });
}

/**
 * Check if a string is a literal expression of a string or number
 * @param value
 */
export function isLiteral(value: string): boolean {
  return /^(['"`]).*\1$/.test(value) || !Number.isNaN(Number(value));
}

/**
 * Get the value of a literal expression of string or number
 * @param value
 */
export function getLiteral(value: string): string {
  const match = /^(['"`])(.*)\1$/.exec(value);
  return match ? match[2] : value;
}

/**
 * Format into the final message given the message, the data and the quantity
 * @param message - The i18n message to parse
 * @param i18nData - The data to use to interpolate the message
 * @param quantity - The quantity to use to pluralize the message
 */
export function formatMessage(
  message: string,
  i18nData: I18nData,
  quantity?: number
): string {
  if (quantity === undefined) return interpolate(message, i18nData);
  return pluralize(interpolate(message, i18nData), quantity);
}
