<template lang="pug">
.FcwClientLogo(:style="style")
  FImage.FcwClientLogo__logo(
    v-if="!!logoSrc"
    :src="logoSrc"
    :alt="`${clientState.name} logo`"
    :height="smAndDown(40, 56)"
    loading="eager"
    corners="sharp"
  )
  h2.FcwClientLogo__clientName(v-else) {{ clientState.name }}
</template>

<style lang="stylus">
.FcwClientLogo__logo
  max-width rem(136)

.FcwClientLogo__clientName
  use-font('heading-5')
  color var(--FcwClientLogo--color)

  +media-down('sm')
    use-font('heading-5')
    color var(--FcwClientLogo--color)
</style>

<script setup lang="ts">
export interface FcwClientLogoProps {
  /**
   * Fallback text color
   */
  color?: Color;
  /**
   * Variant of the logo
   */
  variant?: 'default' | 'light';
}

const props = withDefaults(defineProps<FcwClientLogoProps>(), {
  variant: 'default',
  color: 'primary',
});

const clientStore = useClientStore();
const { clientState } = storeToRefs(clientStore);

const logoSrc = computed(() =>
  props.variant === 'light'
    ? clientState.value.logoLightSrc
    : clientState.value.logoSrc
);

const { smAndDown } = useFBreakpoints();

const style = computed<Style>(() => ({
  '--FcwClientLogo--color': getCssColor(props.color),
}));
</script>
