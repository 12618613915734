export default {
  "account-settings-auth": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/account-settings-auth.vue").then(m => m.default || m),
  "account-settings": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/account-settings.vue").then(m => m.default || m),
  "communication-preferences": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/communication-preferences.vue").then(m => m.default || m),
  embed: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/embed.vue").then(m => m.default || m),
  empty: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/empty.vue").then(m => m.default || m),
  "profile-auth": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-auth.vue").then(m => m.default || m),
  "profile-discover": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-discover.vue").then(m => m.default || m),
  "profile-register": () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile-register.vue").then(m => m.default || m),
  profile: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/profile.vue").then(m => m.default || m),
  showcase: () => import("/tmp/build/ad8838f6/customer-website-pr/layouts/showcase.vue").then(m => m.default || m)
}