import type { Ref } from 'vue';

/**
 * Returns the user locale if it does exist on Strapi, or the Strapi default locale
 * @param localesSubset - Limit the response to a given subset of locales
 */
export async function useStrapiLocale(
  localesSubset?: string[]
): Promise<Ref<StrapiLocaleCode | null>> {
  const { locale } = useLocale();

  const { clientState } = useClientStore();
  const strapiLocale = computed<StrapiLocaleCode | null>(() => {
    const restrictedLocales = localesSubset
      ? clientState.locales.filter(locale => localesSubset.includes(locale))
      : clientState.locales;

    return restrictedLocales.includes(locale.value)
      ? locale.value
      : clientState.defaultLocale;
  });

  return strapiLocale;
}
