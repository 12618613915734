import { mapCmsInternalShowcaseDataToInternalShowcaseSettings } from '@/lib/cms-mappers';

/**
 * Returns settings data fetched from CMS (Strapi)
 */
export async function useCmsInternalShowcaseSettings(): UseCmsResponse<InternalShowcaseSettings> {
  const { data, error, pending, refresh } = await useStrapi(
    'internal-showcase',
    {
      offlineSSRFallback: true,
      queryParams: {
        populate: ['features'],
      },
    }
  );

  const mappedData = computed(() =>
    mapCmsInternalShowcaseDataToInternalShowcaseSettings(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
