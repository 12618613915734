import { isNullish } from '@fifteen/shared-lib';

import { isExternalUrl } from '@/lib/helpers/urls';
import { isUnlocalizedRoute } from '@/lib/helpers/routes';
import { createLogger } from '@/lib/core/logger';

import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteLocation,
  RouteLocationNormalizedGeneric,
} from 'vue-router';

const logger = createLogger('useLocalizedRoute');

interface UseLocalizedRouteReturn {
  /**
   * Returns localized route if the route is registered in Vue Router
   * @param route - Route to resolve and localize
   */
  resolveLocalizedRoute(
    route: RouteLocationRaw | string | null | undefined
  ): RouteLocationNormalized | string;
}

export function useLocalizedRoute(): UseLocalizedRouteReturn {
  const router = useRouter();
  const { locale } = useLocale();

  /**
   * From a resolved route, build a new one with the localized route name by removing 'unlocalized' prefix,
   * and with he current locale forwarded as param to the route link path if it is not an unlocalized route
   */
  function normalizeLocalizedRoute(
    resolvedRoute: RouteLocation
  ): RouteLocationNormalized {
    return {
      ...resolvedRoute,
      name:
        ((resolvedRoute.name as string) ?? '').replace('unlocalized-', '') ??
        '',
      params: {
        ...resolvedRoute.params,
        ...(resolvedRoute &&
          isUnlocalizedRoute(
            resolvedRoute as RouteLocationNormalizedGeneric
          ) && { locale: locale.value }),
      },
    };
  }

  function resolveLocalizedRoute(
    route: RouteLocationRaw | string | undefined
  ): RouteLocationNormalized | string {
    if (isNullish(route))
      return router.resolve('/') as RouteLocationNormalizedGeneric;

    if (isExternalUrl(String(route))) return String(route);

    // In case input 'route' is a string (i.e. a path) router.resolve will not throw.
    if (typeof route === 'string') {
      if (route.startsWith('#')) {
        return normalizeLocalizedRoute(router.resolve({ hash: route }));
      }
      return normalizeLocalizedRoute(router.resolve(route));
    }

    try {
      // Yet, router.resolve can throw if a named route is not registered in Vue Router
      return normalizeLocalizedRoute(router.resolve(route));
    } catch {
      // If it has thrown, it may be because no locale is set in params while required
      try {
        // So we try again with the current locale
        return normalizeLocalizedRoute(
          router.resolve({
            ...route,
            params: {
              locale: locale.value,
              ...('params' in route ? route?.params : {}),
            },
          } as RouteLocationRaw)
        );
      } catch (error) {
        logger.warn((error as Error).message, '\n → resolving to path "/"');
        return router.resolve({ path: '/' }) as RouteLocationNormalizedGeneric;
      }
    }
  }

  return { resolveLocalizedRoute };
}
