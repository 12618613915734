import { hexToRgbString } from '@fifteen/shared-lib';

import { defaultPrimaryFontFamily, defaultSecondaryFontFamily } from '@/config';

/**
 * Apply the client theme into a :root pseudo-class in document head
 */
export function useClientTheme(): void {
  const { strapiBaseUrl } = useRuntimeConfig().public;
  const clientStore = storeToRefs(useClientStore());

  const { theme, faviconSrc } = unref(clientStore.clientState);

  const themeAsObject = {
    '--breakpoint--xxs': theme.breakpointXxs,
    '--breakpoint--xs': theme.breakpointXs,
    '--breakpoint--sm': theme.breakpointSm,
    '--breakpoint--md': theme.breakpointMd,
    '--breakpoint--lg': theme.breakpointLg,
    '--color--neutral--dark-5': theme.colorNeutralDark5,
    '--color--neutral--dark-5--rgb': hexToRgbString(theme.colorNeutralDark5),
    '--color--neutral--dark-4': theme.colorNeutralDark4,
    '--color--neutral--dark-4--rgb': hexToRgbString(theme.colorNeutralDark4),
    '--color--neutral--dark-3': theme.colorNeutralDark3,
    '--color--neutral--dark-3--rgb': hexToRgbString(theme.colorNeutralDark3),
    '--color--neutral--dark-2': theme.colorNeutralDark2,
    '--color--neutral--dark-2--rgb': hexToRgbString(theme.colorNeutralDark2),
    '--color--neutral--dark-1': theme.colorNeutralDark1,
    '--color--neutral--dark-1--rgb': hexToRgbString(theme.colorNeutralDark1),
    '--color--neutral': theme.colorNeutral,
    '--color--neutral--rgb': hexToRgbString(theme.colorNeutral),
    '--color--neutral--light-1': theme.colorNeutralLight1,
    '--color--neutral--light-1--rgb': hexToRgbString(theme.colorNeutralLight1),
    '--color--neutral--light-2': theme.colorNeutralLight2,
    '--color--neutral--light-2--rgb': hexToRgbString(theme.colorNeutralLight2),
    '--color--neutral--light-3': theme.colorNeutralLight3,
    '--color--neutral--light-3--rgb': hexToRgbString(theme.colorNeutralLight3),
    '--color--neutral--light-4': theme.colorNeutralLight4,
    '--color--neutral--light-4--rgb': hexToRgbString(theme.colorNeutralLight4),
    '--color--neutral--light-5': theme.colorNeutralLight5,
    '--color--neutral--light-5--rgb': hexToRgbString(theme.colorNeutralLight5),
    '--color--primary--dark-2': theme.colorPrimaryDark2,
    '--color--primary--dark-2--rgb': hexToRgbString(theme.colorPrimaryDark2),
    '--color--primary--dark-1': theme.colorPrimaryDark1,
    '--color--primary--dark-1--rgb': hexToRgbString(theme.colorPrimaryDark1),
    '--color--primary': theme.colorPrimary,
    '--color--primary--rgb': hexToRgbString(theme.colorPrimary),
    '--color--primary--light-1': theme.colorPrimaryLight1,
    '--color--primary--light-1--rgb': hexToRgbString(theme.colorPrimaryLight1),
    '--color--primary--light-2': theme.colorPrimaryLight2,
    '--color--primary--light-2--rgb': hexToRgbString(theme.colorPrimaryLight2),
    '--color--secondary--dark-2': theme.colorSecondaryDark2,
    '--color--secondary--dark-2--rgb': hexToRgbString(
      theme.colorSecondaryDark2
    ),
    '--color--secondary--dark-1': theme.colorSecondaryDark1,
    '--color--secondary--dark-1--rgb': hexToRgbString(
      theme.colorSecondaryDark1
    ),
    '--color--secondary': theme.colorSecondary,
    '--color--secondary--rgb': hexToRgbString(theme.colorSecondary),
    '--color--secondary--light-1': theme.colorSecondaryLight1,
    '--color--secondary--light-1--rgb': hexToRgbString(
      theme.colorSecondaryLight1
    ),
    '--color--secondary--light-2': theme.colorSecondaryLight2,
    '--color--secondary--light-2--rgb': hexToRgbString(
      theme.colorSecondaryLight2
    ),
    '--color--success--dark-2': theme.colorSuccessDark2,
    '--color--success--dark-2--rgb': hexToRgbString(theme.colorSuccessDark2),
    '--color--success--dark-1': theme.colorSuccessDark1,
    '--color--success--dark-1--rgb': hexToRgbString(theme.colorSuccessDark1),
    '--color--success': theme.colorSuccess,
    '--color--success--rgb': hexToRgbString(theme.colorSuccess),
    '--color--success--light-1': theme.colorSuccessLight1,
    '--color--success--light-1--rgb': hexToRgbString(theme.colorSuccessLight1),
    '--color--success--light-2': theme.colorSuccessLight2,
    '--color--success--light-2--rgb': hexToRgbString(theme.colorSuccessLight2),
    '--color--info--dark-2': theme.colorInfoDark2,
    '--color--info--dark-2--rgb': hexToRgbString(theme.colorInfoDark2),
    '--color--info--dark-1': theme.colorInfoDark1,
    '--color--info--dark-1--rgb': hexToRgbString(theme.colorInfoDark1),
    '--color--info': theme.colorInfo,
    '--color--info--rgb': hexToRgbString(theme.colorInfo),
    '--color--info--light-1': theme.colorInfoLight1,
    '--color--info--light-1--rgb': hexToRgbString(theme.colorInfoLight1),
    '--color--info--light-2': theme.colorInfoLight2,
    '--color--info--light-2--rgb': hexToRgbString(theme.colorInfoLight2),
    '--color--warning--dark-2': theme.colorWarningDark2,
    '--color--warning--dark-2--rgb': hexToRgbString(theme.colorWarningDark2),
    '--color--warning--dark-1': theme.colorWarningDark1,
    '--color--warning--dark-1--rgb': hexToRgbString(theme.colorWarningDark1),
    '--color--warning': theme.colorWarning,
    '--color--warning--rgb': hexToRgbString(theme.colorWarning),
    '--color--warning--light-1': theme.colorWarningLight1,
    '--color--warning--light-1--rgb': hexToRgbString(theme.colorWarningLight1),
    '--color--warning--light-2': theme.colorWarningLight2,
    '--color--warning--light-2--rgb': hexToRgbString(theme.colorWarningLight2),
    '--color--danger--dark-2': theme.colorDangerDark2,
    '--color--danger--dark-2--rgb': hexToRgbString(theme.colorDangerDark2),
    '--color--danger--dark-1': theme.colorDangerDark1,
    '--color--danger--dark-1--rgb': hexToRgbString(theme.colorDangerDark1),
    '--color--danger': theme.colorDanger,
    '--color--danger--rgb': hexToRgbString(theme.colorDanger),
    '--color--danger--light-1': theme.colorDangerLight1,
    '--color--danger--light-1--rgb': hexToRgbString(theme.colorDangerLight1),
    '--color--danger--light-2': theme.colorDangerLight2,
    '--color--danger--light-2--rgb': hexToRgbString(theme.colorDangerLight2),
    '--transition--ease-out': theme.transitionEaseOut,
    '--code--background-color': theme.codeBackgroundColor,
    '--fluid-font--breakpoint-min-px': theme.fluidFontBreakpointMinPx,
    '--fluid-font--breakpoint-max-px': theme.fluidFontBreakpointMaxPx,
    '--heading-1--font-size-min-px': theme.heading1FontSizeMinPx,
    '--heading-1--font-size-max-px': theme.heading1FontSizeMaxPx,
    '--heading-1--line-height-min-px': theme.heading1LineHeightMinPx,
    '--heading-1--line-height-max-px': theme.heading1LineHeightMaxPx,
    '--heading-1--font-weight': theme.heading1FontWeight,
    '--heading-1--font-family': theme.heading1FontFamily,
    '--heading-1--letter-spacing': theme.heading1LetterSpacing,
    '--heading-2--font-size-min-px': theme.heading2FontSizeMinPx,
    '--heading-2--font-size-max-px': theme.heading2FontSizeMaxPx,
    '--heading-2--line-height-min-px': theme.heading2LineHeightMinPx,
    '--heading-2--line-height-max-px': theme.heading2LineHeightMaxPx,
    '--heading-2--font-weight': theme.heading2FontWeight,
    '--heading-2--font-family': theme.heading2FontFamily,
    '--heading-2--letter-spacing': theme.heading2LetterSpacing,
    '--heading-3--font-size-min-px': theme.heading3FontSizeMinPx,
    '--heading-3--font-size-max-px': theme.heading3FontSizeMaxPx,
    '--heading-3--line-height-min-px': theme.heading3LineHeightMinPx,
    '--heading-3--line-height-max-px': theme.heading3LineHeightMaxPx,
    '--heading-3--font-weight': theme.heading3FontWeight,
    '--heading-3--font-family': theme.heading3FontFamily,
    '--heading-4--font-size-min-px': theme.heading4FontSizeMinPx,
    '--heading-4--font-size-max-px': theme.heading4FontSizeMaxPx,
    '--heading-4--line-height-min-px': theme.heading4LineHeightMinPx,
    '--heading-4--line-height-max-px': theme.heading4LineHeightMaxPx,
    '--heading-4--letter-spacing': theme.heading4LetterSpacing,
    '--heading-4--font-weight': theme.heading4FontWeight,
    '--heading-4--font-family': theme.heading4FontFamily,
    '--heading-5--font-size-min-px': theme.heading5FontSizeMinPx,
    '--heading-5--font-size-max-px': theme.heading5FontSizeMaxPx,
    '--heading-5--line-height-min-px': theme.heading5LineHeightMinPx,
    '--heading-5--line-height-max-px': theme.heading5LineHeightMaxPx,
    '--heading-5--font-weight': theme.heading5FontWeight,
    '--heading-5--font-family': theme.heading5FontFamily,
    '--heading-6--font-size-min-px': theme.heading6FontSizeMinPx,
    '--heading-6--font-size-max-px': theme.heading6FontSizeMaxPx,
    '--heading-6--line-height-min-px': theme.heading6LineHeightMinPx,
    '--heading-6--line-height-max-px': theme.heading6LineHeightMaxPx,
    '--heading-6--font-weight': theme.heading6FontWeight,
    '--heading-6--font-family': theme.heading6FontFamily,
    '--heading-6--letter-spacing': theme.heading6LetterSpacing,
    '--subtitle-1--font-size-min-px': theme.subtitle1FontSizeMinPx,
    '--subtitle-1--font-size-max-px': theme.subtitle1FontSizeMaxPx,
    '--subtitle-1--line-height-min-px': theme.subtitle1LineHeightMinPx,
    '--subtitle-1--line-height-max-px': theme.subtitle1LineHeightMaxPx,
    '--subtitle-1--font-weight': theme.subtitle1FontWeight,
    '--subtitle-1--font-family': theme.subtitle1FontFamily,
    '--subtitle-1--letter-spacing': theme.subtitle1LetterSpacing,
    '--subtitle-2--font-size-min-px': theme.subtitle2FontSizeMinPx,
    '--subtitle-2--font-size-max-px': theme.subtitle2FontSizeMaxPx,
    '--subtitle-2--line-height-min-px': theme.subtitle2LineHeightMinPx,
    '--subtitle-2--line-height-max-px': theme.subtitle2LineHeightMaxPx,
    '--subtitle-2--font-weight': theme.subtitle2FontWeight,
    '--subtitle-2--font-family': theme.subtitle2FontFamily,
    '--subtitle-2--letter-spacing': theme.subtitle2LetterSpacing,
    '--body-1--font-size-min-px': theme.body1FontSizeMinPx,
    '--body-1--font-size-max-px': theme.body1FontSizeMaxPx,
    '--body-1--line-height-min-px': theme.body1LineHeightMinPx,
    '--body-1--line-height-max-px': theme.body1LineHeightMaxPx,
    '--body-1--font-weight': theme.body1FontWeight,
    '--body-1--font-family': theme.body1FontFamily,
    '--body-1--letter-spacing': theme.body1LetterSpacing,
    '--body-2--font-size-min-px': theme.body2FontSizeMinPx,
    '--body-2--font-size-max-px': theme.body2FontSizeMaxPx,
    '--body-2--line-height-min-px': theme.body2LineHeightMinPx,
    '--body-2--line-height-max-px': theme.body2LineHeightMaxPx,
    '--body-2--font-weight': theme.body2FontWeight,
    '--body-2--font-family': theme.body2FontFamily,
    '--body-2--letter-spacing': theme.body2LetterSpacing,
    '--button--font-size-min-px': theme.buttonFontSizeMinPx,
    '--button--font-size-max-px': theme.buttonFontSizeMaxPx,
    '--button--line-height-min-px': theme.buttonLineHeightMinPx,
    '--button--line-height-max-px': theme.buttonLineHeightMaxPx,
    '--button--font-weight': theme.buttonFontWeight,
    '--button--font-family': theme.buttonFontFamily,
    '--button--letter-spacing': theme.buttonLetterSpacing,
    '--caption--font-size-min-px': theme.captionFontSizeMinPx,
    '--caption--font-size-max-px': theme.captionFontSizeMaxPx,
    '--caption--line-height-min-px': theme.captionLineHeightMinPx,
    '--caption--line-height-max-px': theme.captionLineHeightMaxPx,
    '--caption--font-weight': theme.captionFontWeight,
    '--caption--font-family': theme.captionFontFamily,
    '--caption--letter-spacing': theme.captionLetterSpacing,
    '--overline--font-size-min-px': theme.overlineFontSizeMinPx,
    '--overline--font-size-max-px': theme.overlineFontSizeMaxPx,
    '--overline--line-height-min-px': theme.overlineLineHeightMinPx,
    '--overline--line-height-max-px': theme.overlineLineHeightMaxPx,
    '--overline--font-weight': theme.overlineFontWeight,
    '--overline--font-family': theme.overlineFontFamily,
    '--overline--letter-spacing': theme.overlineLetterSpacing,
  };

  const themeAsCss = Object.keys(themeAsObject)
    .map(key => `${key}: ${themeAsObject[key as keyof typeof themeAsObject]};`)
    .join('\n');

  /**
   * Check if the theme contains the default font
   * Used to load the default font (from a URL or local file) only if it's not overridden by a client one
   */
  function containsDefaultFont(fontType: 'primary' | 'secondary'): boolean {
    const fontFamilyToCheck =
      fontType === 'primary'
        ? defaultPrimaryFontFamily
        : defaultSecondaryFontFamily;

    for (const key in themeAsObject) {
      if (
        themeAsObject.hasOwnProperty(key) &&
        themeAsObject[key as keyof typeof themeAsObject].startsWith(
          fontFamilyToCheck
        )
      ) {
        return true;
      }
    }
    return false;
  }

  const fontsUrl = `${strapiBaseUrl}/fonts`;

  useHead({
    style: [
      {
        children: `:root { ${themeAsCss} }`,
      },
      ...(containsDefaultFont('primary')
        ? [
            {
              children: `@font-face { font-family: ${defaultPrimaryFontFamily}; weight: 800; src: url('/fonts/GreycliffCF-ExtraBold.woff2'); }`,
            },
          ]
        : []),
    ],
    link: [
      {
        rel: 'icon',
        href: faviconSrc,
      },
      {
        rel: 'stylesheet',
        href: fontsUrl,
      },
      ...(containsDefaultFont('secondary')
        ? [
            {
              rel: 'stylesheet',
              href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@500;700',
            },
          ]
        : []),
    ],
  });
}
