import Cookies from 'js-cookie';
import { defineStore } from 'pinia';

import { useApi } from '@/composables/useApi';

export type AuthType = 'login' | 'register' | null;

export const useAuthStore = defineStore('auth', () => {
  // A reactive initial state it's needed and used by the reset store plugin
  const initialState = reactive({
    isLoggedIn: false,
    loginId: '',
    authType: null,
  });

  const isLoggedIn = ref(initialState.isLoggedIn);
  const loginId = ref(initialState.loginId);

  const authStore = useAuthStore();
  const registerStore = useProfileRegisterStore();
  const userStore = useUserStore();

  const _authType = ref<AuthType>(initialState.authType);
  const authType = computed<AuthType>(() => _authType.value);

  const api = useApi();

  /**
   * Fetch and set auth status
   */
  async function init(): Promise<void> {
    const { data } = await api.get('/user/auth/check');
    if (data?.value?.connected) {
      isLoggedIn.value = true;
      registerStore.showDiscover = data?.value?.showDiscover;
    }
  }

  async function logout(): Promise<void> {
    authStore.$reset();
    registerStore.$reset();
    userStore.$reset();
    // try to logout server-side (this will never throw)
    await api.post('/client/auth/logout');
    // and we remove client-side cookie anyway
    if (process.client) Cookies.remove('session');
  }

  async function setAuthType(authType: AuthType): Promise<void> {
    _authType.value = authType;
    return registerStore.loadSteps(_authType.value);
  }

  return {
    initialState,
    init,
    isLoggedIn,
    loginId,
    authType,
    setAuthType,
    logout,
  };
});
