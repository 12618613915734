<template lang="pug">
.FcwContentTemplate(:class="classes")
  FcwSection
    FcwSectionContent
      h1(v-html="title")
      p.FcwContentTemplate__lastUpdate(v-if="lastUpdate") {{ lastUpdateFull }}
      FTextContent(:source="content")
      slot
</template>

<style lang="stylus">
.FcwContentTemplate
  margin-top rem(32)
  padding rem(120) 0 rem(96)
  overflow hidden
  // make sure footer is always at the bottom
  min-height 'calc(100vh - %s)' % rem(260)

  &--frameless
    margin rem(64)
    padding 0

    +media-down('md')
      margin rem(32)

    +media-down('xs')
      margin rem(16)

  .FcwSectionContent
    max-width rem(768)

    > h1:first-of-type
      color var(--color--primary)

    li
      list-style initial

  &__lastUpdate
    font-style italic
    margin-bottom rem(48)

  .FSection
    margin-top 0
</style>

<script lang="ts" setup>
export interface FcwContentTemplateProps {
  /**
   * A title that describes the content
   */
  title: string;
  /**
   * Show last update date of the content
   */
  lastUpdate?: Date | string | number;
  /**
   * Markdown content passed a string.
   */
  content: string;
  /**
   * Adjust margins and padding to fit the content in a frameless page
   */
  frameless?: boolean;
}

const props = withDefaults(defineProps<FcwContentTemplateProps>(), {
  lastUpdate: undefined,
  frameless: false,
});

const { t } = useClientI18n();

const classes = computed<VueClasses>(() => ({
  'FcwContentTemplate--frameless': props.frameless,
}));

const { formatDate } = useLocaleFormat();
const lastUpdateFull = computed(() => {
  return `${t('common__last_update__prefix')} ${formatDate(props.lastUpdate, {
    format: 'PPP',
  })}`;
});
</script>
