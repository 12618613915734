/**
 * Lock the body scroll, and unlock it when the component is unmounted
 * @param lock - Whether to lock the body scroll
 */
export function useBodyScrollLock(lock?: Ref<boolean>): void {
  const bodyAttrs = ref({});

  function lockBodyScroll(): void {
    bodyAttrs.value = {
      style: `overflow: hidden;`,
    };
  }

  function unlockBodyScroll(): void {
    bodyAttrs.value = {};
  }

  useHead(
    computed(() => ({
      bodyAttrs: bodyAttrs.value,
    }))
  );

  if (lock) {
    watchImmediate(lock, value =>
      value ? lockBodyScroll() : unlockBodyScroll()
    );
  } else {
    onMounted(lockBodyScroll);
  }

  onUnmounted(unlockBodyScroll);
}
