import { defaultLocale as configDefaultLocale } from '@/config/locales';
import { isUnlocalizedRoute, isProfileRoute } from '@/lib/helpers/routes';
import { createLogger } from '@/lib/core/logger';

import type { Ref } from 'vue';
import type { MaybeRef } from '@vueuse/core';
import type { RouteLocationRaw } from 'vue-router';

export interface UseLocaleReturn {
  /**
   * App current locale. Value is readonly
   */
  locale: Ref<ISOLocale>;
  /**
   * Set a new locale
   */
  setLocale(locale: ISOLocale): Promise<void>;
  /**
   * Init the composable
   */
  init(locale: UseLocaleInitOptions): void;
}

export interface UseLocaleInitOptions {
  /**
   * List of available locales
   */
  locales?: MaybeRef<ISOLocale[]>;
  /**
   * Initial locale to set
   */
  locale?: MaybeRef<ISOLocale>;
  /**
   * Default locale among available ones
   */
  defaultLocale?: MaybeRef<ISOLocale>;
}

/**
 * Get and set the current app locale
 */
export function useLocale(): UseLocaleReturn {
  const logger = createLogger('modules.i18n.useLocale');

  const locales = useState<ISOLocale[]>('useLocale.locales', () => []);
  const route = useRoute();
  // Get the stored cookie value (as useStatefulCookie only set cookie when value change)
  const cookieLocale = useCookie<ISOLocale>('locale');

  const {
    locale: i18nLocale,
    hasLocaleMessages,
    setLocaleMessages,
  } = useI18n();
  const { fetchMessages } = useI18nMessages();

  const defaultLocale = ref<ISOLocale>(configDefaultLocale);

  const locale = useStatefulCookie<ISOLocale>('locale', defaultLocale);

  async function syncRouteLocale(): Promise<void> {
    // Ignore unlocalized routes & profile routes, as they do not expect a locale param
    // Ignore route without locale param (unlocalized OR unresolved route)
    if (
      isUnlocalizedRoute(route) ||
      isProfileRoute(route) ||
      !route.params.locale
    ) {
      logger.debug(
        `No need to sync '${route.path}'. Route is unlocalized, profile, or does not have a locale param (unresolved).`
      );
      return;
    }

    if (!locales.value.includes(route.params.locale as StrapiLocaleCode)) {
      logger.debug(
        `Route locale '${route.params.locale}' is unknown. Assuming an unlocalized path is requested, and prepending current locale '${locale.value}'...`
      );
      await navigateTo(`/${locale.value}${route.path}`, {
        replace: true,
      });
      return;
    }

    if (locale.value !== route.params.locale) {
      logger.debug(
        `Route locale '${route.params.locale}' is different from current locale '${locale.value}'. Syncing route locale...`
      );
      await navigateTo(
        {
          ...route,
          params: { ...route.params, locale: locale.value },
        } as RouteLocationRaw,
        { replace: true }
      );
    }
  }

  async function setLocale(newLocale: MaybeRef<ISOLocale>): Promise<void> {
    const _newLocale = getAllowedLocale(locales.value, [unref(newLocale)]);

    // Set locale value and sync route locale
    locale.value = _newLocale;
    await syncRouteLocale();

    // Set messages if it hasn't be done before, then update i18n locale
    if (!hasLocaleMessages(_newLocale)) {
      const messages = await fetchMessages(_newLocale, defaultLocale.value);
      await setLocaleMessages(_newLocale, messages);
    }
    i18nLocale.value = _newLocale;
  }

  async function init(options: UseLocaleInitOptions): Promise<void> {
    if (options.locales) {
      locales.value = unref(options.locales) ?? [];
    }
    if (options.defaultLocale) {
      defaultLocale.value = unref(options.defaultLocale);
    }

    // Get the locale from the route params, or from the route path
    const [_, maybeRouteLocale] = route.path.split('/');
    const routeLocale = (route.params.locale ?? maybeRouteLocale) as ISOLocale;

    locale.value = getAllowedLocale(locales.value, [
      unref(options.locale),
      routeLocale,
      cookieLocale.value,
      getBrowserLang(),
      defaultLocale.value,
    ]);

    await setLocale(locale.value);
  }

  return {
    locale: computed(() => locale.value),
    setLocale,
    init,
  };
}

/**
 * Isomorphic browser lang getter
 */
function getBrowserLang(): ISOLocale {
  const browserLocale =
    (process.server
      ? useRequestHeaders()['accept-language']?.split(',')[0]
      : navigator.language) ?? '';
  return browserLocale.split('-')[0] as ISOLocale;
}

/**
 * Returns an allowed locale, among a list of available ones
 * @param availableLocales - List of available locales
 * @param requestedLocales - Array of requested locales, ordered by priority
 */
function getAllowedLocale(
  availableLocales: (ISOLocale | null | undefined)[],
  requestedLocales: (ISOLocale | null | undefined)[]
): ISOLocale {
  return (
    requestedLocales.find(locale => availableLocales.includes(locale)) ??
    configDefaultLocale
  );
}
