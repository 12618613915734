import { getSafeImageSize } from '@/lib/utils';

import type { FcwHeroProps } from '@/components/organisms/FcwHero.vue';
import type { FImageProps } from '@fifteen/design-system-vue';
import type { SeoMetaOptions } from '@/composables/useClientSeoMeta';

export function mapCmsImageToImageProps(
  rawImageData?: StrapiImage,
  size?: StrapiImageSize
): FImageProps {
  const rawImageAttrs = rawImageData?.data?.attributes;

  // Filter on .jpeg image only as Strapi seems to handle badly the compression of .png
  const isJpegImage = ['.jpeg', '.jpg'].includes(rawImageAttrs?.ext ?? '');

  const imageSize = getSafeImageSize(size, rawImageAttrs?.formats ?? {});
  // Try to get placeholder format. If it does not exist, fallback to the closest format (thumbnail)
  const imagePlaceholder = getSafeImageSize(
    'placeholder',
    rawImageAttrs?.formats ?? {}
  );

  const imageSource =
    isJpegImage && imageSize
      ? rawImageAttrs?.formats[imageSize]
      : rawImageAttrs ?? { url: '', width: 0, height: 0 };

  return {
    src: imageSource?.url ?? '',
    alt: rawImageAttrs?.alternativeText ?? '',
    placeholder: imagePlaceholder
      ? rawImageAttrs?.formats?.[imagePlaceholder]?.url
      : '',
    width: imageSource?.width,
    height: imageSource?.height,
  };
}

export function mapCmsImageToHeroImagesProps(
  rawImageData?: StrapiImage
): FcwHeroProps['images'] | null {
  // As mapCmsImageToImageProps always fallback, a src should be returned if an image is present
  const isImageValid = Boolean(mapCmsImageToImageProps(rawImageData)?.src);

  return isImageValid
    ? {
        lg: mapCmsImageToImageProps(rawImageData, 'hero'),
        md: mapCmsImageToImageProps(rawImageData, 'large'),
        sm: mapCmsImageToImageProps(rawImageData, 'large'),
        xs: mapCmsImageToImageProps(rawImageData, 'medium'),
      }
    : null;
}

export function mapCmsSeoDataToSeoMeta(
  seoData: CommonSeo | undefined
): SeoMetaOptions {
  return {
    title: seoData?.title ?? '',
    description: seoData?.description ?? '',
    imageUrl: mapCmsImageToImageProps(seoData?.image, 'small').src,
  };
}

export function mapCmsHeroDataToSeoMeta(
  heroData: CommonHero | undefined
): SeoMetaOptions {
  return {
    title: heroData?.title ?? '',
    description: heroData?.subtitle ?? '',
    imageUrl: mapCmsImageToImageProps(heroData?.image, 'small').src,
  };
}
