import { storeToRefs } from 'pinia';

import { createLogger } from '@/lib/core/logger';
import { checkProfileAccess } from '@/lib/core/access/profile';
import { checkAccountSettingsAccess } from '@/lib/core/access/account-settings';
import { isUnlocalizedRoute } from '@/lib/helpers/routes';

import type { RouteLocationRaw } from 'vue-router';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const logger = createLogger('access.middleware');

  logger.debug(
    `❓ Middleware check. Asking navigation from ${from.path} to ${to.path}...`
  );
  const clientStore = useClientStore();
  const { homepage, isLoading } = storeToRefs(clientStore);

  // Wait until the client state data is ready
  await until(isLoading).toBe(false);

  const { locale } = useLocale();

  const { isPreview } = useGlobalStore();
  // When preview mode, skip all redirection checks
  if (isPreview) {
    logger.debug('Preview mode enabled. Middleware check skipped ✅');
    return;
  }

  const toPathWithoutLocale = to.path.replace(`/${locale.value}`, '');

  // When navigating to root, navigates to custom homepage (can be the showcase-index or a custom one)
  if (toPathWithoutLocale === '/' && to.name !== homepage.value.name) {
    logger.debug(
      `Redirects to homepage. From ${from.params.name} to ${String(
        homepage.value.name
      )}`
    );
    return navigateTo(
      {
        ...homepage.value,
        params: { ...to.params, locale: locale.value },
        query: to.query,
        hash: to.hash,
      } as RouteLocationRaw,
      { replace: true }
    );
  }

  logger.debug(`Checking redirections...`);
  const { redirects } = useGlobalStore();
  const redirect = redirects.find(
    ({ from }) => from === toPathWithoutLocale || from === to.path
  );
  if (redirect) {
    logger.debug(`Redirects to route from ${redirect.from} to ${redirect.to}`);
    return navigateTo(
      {
        path: redirect.to,
        params: to.params,
        query: to.query,
        hash: to.hash,
      } as RouteLocationRaw,
      { replace: true, redirectCode: redirect.statusCode }
    );
  }
  logger.debug(`No redirection needed.`);

  // Redirect unlocalized routes to localized ones (`/:locale/<route>)
  logger.debug(`Checking route localization...`);
  if (isUnlocalizedRoute(to)) {
    logger.debug(`Redirects unlocalized route to localized route.`);
    return navigateTo(
      {
        name: (to.name as string)?.replace('unlocalized-', ''),
        params: { ...to.params, locale: locale.value },
        query: to.query,
        hash: to.hash,
      },
      { replace: true }
    );
  }
  logger.debug(`No route localization redirection needed.`);

  // Account settings access guard
  const accountSettingsRedirectRoute = checkAccountSettingsAccess(to);
  if (accountSettingsRedirectRoute) return accountSettingsRedirectRoute;

  // Profile access guard
  const profileRedirectRoute = checkProfileAccess(to, from);
  if (profileRedirectRoute) return profileRedirectRoute;

  logger.debug(
    `✅ Middleware check OK. Navigating from ${from.path} to ${to.path}.`
  );
});
