import { getCmsComponentData } from '@/lib/utils';

export function mapCmsInternalProfileDataToInternalProfileSettings(
  internalProfileRawData:
    | StrapiResponseData<InternalProfileContentType>
    | undefined
): InternalProfileSettings {
  const cmsData = internalProfileRawData?.attributes;

  return {
    features: getCmsComponentData(cmsData?.features),
  };
}
