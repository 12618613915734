import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';

import { useApi } from '@/composables/useApi';

import type { ApiSchema } from '@fifteen/sdk';

export const useUserStore = defineStore('user', () => {
  // A reactive initial state it's needed and used by the reset store plugin
  const initialState = reactive({
    user: null,
  });

  const user = ref<ApiSchema['user.User'] | null>(initialState.user);

  const authStore = useAuthStore();

  const api = useApi();

  /**
   * Fetch and set user if he or she is connected
   */
  async function init(): Promise<void> {
    if (authStore.isLoggedIn) {
      await fetchUser();
    }
  }

  /**
   * Fetch the user data and stores it to the store
   */
  async function fetchUser(): Promise<void> {
    const { data, error } = await api.get('/user');

    // TODO: make logout smarter
    // Check 401 instead
    if (error.value) authStore.logout();
    user.value = data.value ?? null;
  }

  return {
    initialState,
    init,
    user,
    fetchUser,
  };
});
