import type { FcwAlertProps } from '@/components/molecules/FcwAlert.vue';

export type UseAlertOptions = FcwAlertProps | string;

/**
 * Display the FcwAlert component with the props passed as arguments
 * A FcwAlert component must be put in a parent component in order to work properly
 * @param options - FcwAlert props, or a string
 */
export function useAlert(options: UseAlertOptions): void {
  const _options = typeof options === 'string' ? { text: options } : options;

  const defaultAlertOptions: FcwAlertProps = {
    text: '',
    icon: 'checkCircle',
    type: 'success',
    closable: true,
    duration: 5000,
  };

  const bus = useEventBus<FcwAlertProps>('FcwAlert');
  bus.emit({ ...defaultAlertOptions, ..._options });
}
