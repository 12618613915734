function mapRedirectTypeToStatusCode(type: RedirectType): RedirectStatusCode {
  switch (type) {
    default:
    case 'permanent':
      return 301;
    case 'temporary':
      return 302;
  }
}

export function mapCmsRedirectsDataToRedirects(
  redirectsRawData: StrapiResponseData<RedirectsContentType>[] | undefined
): Redirect[] {
  return (redirectsRawData ?? []).map(rawData => {
    const { from, to, type } = rawData.attributes;
    return {
      from,
      to,
      statusCode: mapRedirectTypeToStatusCode(type),
    };
  });
}
