import { defineStore } from 'pinia';
export interface ShowcaseSettings {
  sharingFaqItemIds: string[];
  leasingFaqItemIds: string[];
}

export interface InternalShowcaseSettings {
  features: {
    zendesk_chat?: boolean;
  };
}

export type ShowcaseClientState = ShowcaseSettings & InternalShowcaseSettings;

export const useShowcaseStore = defineStore('showcase', () => {
  const showcaseClientState = ref<ShowcaseClientState>({
    sharingFaqItemIds: [],
    leasingFaqItemIds: [],
    features: {
      zendesk_chat: false,
    },
  });

  async function init(): Promise<void> {
    const [{ data: showcaseSettings }, { data: internalShowcaseSettings }] =
      await Promise.all([
        useCmsShowcaseSettings(),
        useCmsInternalShowcaseSettings(),
      ]);

    showcaseClientState.value = {
      ...showcaseSettings.value,
      ...internalShowcaseSettings.value,
    };
  }

  return {
    init,
    showcaseClientState,
  };
});
