import type { FontStyle } from '@/lib/cms-mappers/internal-settings.mappers';

/**
 * Returns the default font for a specific font style (e.g. 'font_primary' for 'heading-1')
 * @param fontStyle - Value of enum FontStyle (e.g. 'heading-1')
 */
export function getDefaultFont(
  fontStyle: FontStyle
): 'font_primary' | 'font_secondary' {
  switch (fontStyle) {
    case 'heading-1':
    case 'heading-2':
    case 'heading-3':
    case 'heading-4':
    case 'heading-5':
    case 'heading-6':
    case 'button':
      return 'font_primary';
    case 'subtitle-1':
    case 'subtitle-2':
    case 'body-1':
    case 'body-2':
    case 'caption':
    case 'overline':
      return 'font_secondary';
    default:
      return 'font_primary';
  }
}
