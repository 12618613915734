<template lang="pug">
FRouterLink(
  v-bind="$props"
  :location="resolveLocalizedRoute(location)"
)
  slot
</template>

<script setup lang="ts">
import type { FRouterLinkProps } from '@fifteen/design-system-vue/router';

export type FcwRouterLinkProps = FRouterLinkProps;

defineProps<FcwRouterLinkProps>();

const { resolveLocalizedRoute } = useLocalizedRoute();
</script>
