<template lang="pug">
FCard.FcwCard(
  :class="classes"
  :color="color"
  :text-color="textColor"
  :width="width"
)
  slot
</template>

<style lang="stylus">
.FcwCard
  elevation-transition()

for $z in 'inset' 0 1 2 3 4 5 6
  .FcwCard--elevation--{$z}
    elevation-light($z)
</style>

<script setup lang="ts">
export interface FcwCardProps {
  /**
   * Background color of the card
   */
  color?: Color;
  /**
   * Text color of the card
   */
  textColor?: Color;
  /**
   * Width of the card
   */
  width?: string | number;
  /**
   * Elevation of the card
   */
  elevation?: string | number;
}

const props = withDefaults(defineProps<FcwCardProps>(), {
  color: 'neutral--light-5',
  textColor: 'neutral--dark-4',
  width: 'auto',
  elevation: 3,
});

const classes = computed<VueClasses>(() => ({
  [`FcwCard--elevation--${props.elevation}`]: true,
}));
</script>
