import { defineStore } from 'pinia';
import { useRouteQuery } from '@vueuse/router';

import { createLogger } from '@/lib/core/logger';

const logger = createLogger('global.store');

export const useGlobalStore = defineStore('global', () => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const route = useRoute();

  const isPreview = useState<boolean>('useGlobalStore.isPreview', () =>
    route.query.secret
      ? route.query.secret === config.strapiPreviewSecret
      : false
  );

  const redirects = ref<Redirect[]>([]);

  // We make sure the VueUse composable has the correct Nuxt Vue Router instances (otherwise, some SSR errors occured in Nuxt 3.8)
  const appQueryParam = useRouteQuery('app', '', { router, route });

  const isMobileAppStoresModalVisible = computed({
    get() {
      return Boolean(appQueryParam.value);
    },
    set(value) {
      if (value) {
        return router.push({ ...route, query: { ...route.query, app: 1 } });
      }

      const { app: _, ...query } = route.query;
      router.push({ ...route, query });
    },
  });

  async function init(): Promise<void> {
    // Fetch Strapi’s redirects in best effort (only warn if it fails)
    const { error, data } = await useCmsRedirects();
    if (error?.value) {
      logger.warn(
        `Could not fetch redirects from Strapi. Error: ${error.value.message}`
      );
      return;
    }
    redirects.value = data.value;
  }

  return { init, isPreview, isMobileAppStoresModalVisible, redirects };
});
