import { notNullish } from '@fifteen/shared-lib';

/**
 * Plugin that removes routes corresponding to disabled features from Vue Router
 */
export default defineNuxtPlugin({
  name: 'routes',
  dependsOn: ['store'],
  parallel: true,
  async setup() {
    const router = useRouter();
    const { clientState } = useClientStore();

    const { isPreview } = useGlobalStore();
    if (isPreview) return;

    const disabledModuleRoutes = [
      !clientState.modules.embedMap ? 'embed-map' : null,
      !clientState.modules.profile ? 'profile' : null,
      !clientState.modules.showcase ? 'showcase' : null,
      !clientState.modules.accountSettings ? 'account-settings' : null,
      !clientState.modules.communicationPreferences
        ? 'communication-preferences'
        : null,
    ];

    const disabledRoutes = [
      ...disabledModuleRoutes,
      !clientState.features.referral ? 'referral' : null,
    ].filter(notNullish);

    // No disabled routes, so don't delete any of them
    if (disabledRoutes.length === 0) return;

    // Build a regexp that catches all routes starting by the feature route name (to match sub-routes)
    const disabledRoutesRegex = new RegExp(`^(${disabledRoutes.join('|')})`);

    const routesToDelete = router.getRoutes().reduce((routeNames, route) => {
      const routeName = route.name as string;
      return routeNames.concat(
        disabledRoutesRegex.test(routeName) ? routeName : []
      );
    }, [] as string[]);

    routesToDelete.forEach(
      routeName => routeName && router.removeRoute(routeName)
    );
  },
});
