import type { I18nData } from '@/modules/i18n/types';

type I18nT = (key: string, i18nData?: I18nData, quantity?: number) => string;

interface UseClientI18nReturn {
  t: I18nT;
}

/**
 * useI18n wrapper which set default values based on client config for string interpolation
 */
export function useClientI18n(): UseClientI18nReturn {
  const { t: _t } = useI18n();
  const clientStore = useClientStore();
  const { clientState } = storeToRefs(clientStore);
  const t: I18nT = (key, i18nData, quantity) =>
    _t(
      key,
      {
        client_name: clientState.value.name,
        terms_and_conditions: '/terms-and-conditions',
        privacy_policy: '/privacy-policy',
        ...i18nData,
      },
      quantity ?? 0
    );
  return { t };
}
