<template lang="pug">
FcwCard.FcwTile(
  :color="color"
  :text-color="textColor"
  :width="width"
  :elevation="1"
  :style="style"
)
  slot(name="header")
    h4.FcwTile__title(v-if="title") {{ title }}
  .FcwTile__contentWrapper(ref="contentWrapperRef")
    .FcwTile__content
      slot
  FDivider.FcwTile__divider(
    v-if="$slots['footer']"
    :margins="false"
    color="neutral--light-3"
  )
  .FcwTile__footer
    slot(name="footer")
</template>

<style lang="stylus">
.FcwTile
  padding rem(24)
  display flex
  flex-direction column

.FcwTile__title
  color var(--FcwTile--titleColor)

.FcwTile__contentWrapper
  margin rem(16) rem(-16) rem(-8)
  padding 0 rem(16)
  overflow auto

  &::before
    content ''
    display block
    height rem(8)
    background transparent
    width 100%

.FcwTile__content
  padding-bottom rem(8)

.FcwTile__divider
  margin-top rem(24)
  margin-bottom rem(16)

.FcwTile__footer
  display flex
  justify-content center
</style>

<script setup lang="ts">
import type { Ref } from 'vue';

export interface FcwTileProps {
  /**
   * Title of the tile
   */
  title?: string;
  /**
   * Color of the title
   */
  titleColor?: Color;
  /**
   * Background color of the tile
   */
  color?: Color;
  /**
   * Text color of the tile
   */
  textColor?: Color;
  /**
   * Width of the tile
   */
  width?: string | number;
}

const props = withDefaults(defineProps<FcwTileProps>(), {
  title: '',
  color: 'neutral--light-5',
  titleColor: 'primary',
  textColor: 'neutral--dark-5',
  width: 'auto',
});

const contentWrapperRef = ref<HTMLElement>();

defineExpose<{
  contentWrapperRef: Ref<HTMLElement | undefined>;
}>({
  contentWrapperRef,
});

const style = computed<Style>(() => ({
  '--FcwTile--titleColor': getCssColor(props.titleColor),
}));
</script>
