import type { ApiSchema } from '@/fifteen-sdk/types';

/**
 * Injects the Zendesk Web Widget script into the page
 * and configures it
 */
export async function useZendeskWebWidget(): Promise<void> {
  const api = useApi();
  const config = useRuntimeConfig();

  const zendeskChatWidgetKey = config.public.zendeskChatWidgetKey;
  if (!zendeskChatWidgetKey) return;

  const { showcaseClientState } = storeToRefs(useShowcaseStore());
  const { profileClientState } = storeToRefs(useProfileStore());
  const { user } = storeToRefs(useUserStore());

  const webWidgetRouteRules = computed(() => ({
    '^profile(-.*)?$': profileClientState.value.features.zendesk_chat,
    '^showcase(-.*)?$': showcaseClientState.value.features.zendesk_chat,
  }));

  const route = useRoute();

  const showWidget = computed(() => {
    const routeName = String(route.name);
    const routeRules = Object.keys(
      webWidgetRouteRules.value
    ) as (keyof typeof webWidgetRouteRules.value)[];

    const routeRule = routeRules.find(rule => routeName.match(rule));

    if (!routeRule) return false;

    const routeRuleValue = webWidgetRouteRules.value[routeRule];
    return routeRuleValue;
  });

  watch(showWidget, value => {
    if (!window.zE) return;
    window.zE('webWidget', value ? 'show' : 'hide');
  });

  async function authenticate(): Promise<string | undefined> {
    if (!user.value || !user.value.email) return;
    const { data } = await api.post('/client/support/authenticate', {
      body: {
        name: `${user.value.firstname} ${user.value.lastname}`,
        email: user.value.email,
      },
    });
    return data.value?.jwt;
  }

  watch(user, async value => {
    if (!value) {
      window.zE('webWidget', 'logout');
      return;
    }

    await initWebWidget(value);
  });

  const { theme } = useClientStore().clientState;

  const isMounted = useMounted();
  useHead(
    computed(() => ({
      script: isMounted.value
        ? [
            {
              src: `https://static.zdassets.com/ekr/snippet.js?key=${zendeskChatWidgetKey}`,
              id: 'ze-snippet',
              onload() {
                window.zESettings = {
                  webWidget: {
                    color: { theme: theme.colorPrimary },
                  },
                };

                window.zE('webWidget', showWidget.value ? 'show' : 'hide');
              },
            },
          ]
        : [],
    }))
  );

  onMounted(async () => {
    if (user.value) {
      await initWebWidget(user.value);
    }
  });

  async function initWebWidget(value: ApiSchema['user.User']): Promise<void> {
    if (!window.zE || !value) return;
    const jwt = await authenticate();
    if (!jwt) return;

    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: (callback: (jwt: string) => void) => callback(jwt),
          },
        },
      },
    });
    window.zE('webWidget', 'chat:reauthenticate');
  }
}
