import { mapCmsInternalProfileDataToInternalProfileSettings } from '@/lib/cms-mappers';

/**
 * Returns settings data fetched from CMS (Strapi)
 */
export async function useCmsInternalProfileSettings(): UseCmsResponse<InternalProfileSettings> {
  const { data, error, pending, refresh } = await useStrapi(
    'internal-profile',
    {
      offlineSSRFallback: true,
      queryParams: {
        populate: ['features'],
      },
    }
  );

  const mappedData = computed(() =>
    mapCmsInternalProfileDataToInternalProfileSettings(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
