import { PAGE_SIZE } from '@/config/strapi-content';

import type { AsyncData } from '#app';

interface UseCmsBlogPostsOptions {
  /**
   * Blog posts page of paginated results
   */
  page?: number;
  /**
   * Blog posts page size
   */
  pageSize?: number;
  /**
   * Blog post fields to fetch
   */
  fields?: (keyof BlogPostContentType)[];
  /**
   * Blog post fields to populate
   */
  populateFields?: string[];
  /**
   * Blog posts sorting rules
   */
  sort?: string | string[] | Record<string, unknown>;
}

/**
 * Returns raw blog posts data fetched from CMS (Strapi)
 */
export async function useCmsBlogPosts(
  options: UseCmsBlogPostsOptions
): Promise<
  AsyncData<StrapiResponse<BlogPostContentType[]> | null, Error | null>
> {
  return useStrapi('blog-posts', {
    watchLocale: true,
    queryParams: {
      ...(options.fields && { fields: options.fields }),
      populate: options.populateFields ?? [],
      sort: options.sort ?? { publishedAt: 'desc' },
      pagination: {
        page: options.page ?? 0,
        pageSize: options.pageSize ?? PAGE_SIZE,
      },
    },
  });
}
