import { joinURL, withQuery } from 'ufo';

import { isExternalUrl } from '@/lib/helpers/urls';
import { version } from '@/package.json';

import type { MaybeRef } from '@vueuse/core';

export interface SeoMetaOptions {
  /**
   * SEO meta title
   */
  title?: string;
  /**
   * SEO meta description
   */
  description?: string;
  /**
   * SEO image URL
   */
  imageUrl?: string;
}

/**
 * Composable that initilize generic SEO meta tags using useHead
 * @param options - UseSeoMeta options
 * @param options.title - SEO Title
 * @param options.description - SEO description
 * @param options.imageUrl - SEO image URL. Can be a static asset, starting with `/` or an external URL, starting with `http(s)://`
 */
export function useClientSeoMeta(options: MaybeRef<SeoMetaOptions>): void {
  // Get url and origin
  let url = '';
  let origin = '';
  if (process.server) {
    const headers = useRequestHeaders();
    const { ssrContext } = useNuxtApp();
    origin = 'https://' + headers.host;
    url = origin + ssrContext?.url;
  }
  if (process.client) {
    origin = window.location.origin;
    url = origin + window.location.pathname;
  }

  const { locale } = useLocale();
  const { clientState } = useClientStore();

  const {
    title: defaultTitle,
    description: defaultDescription,
    imageUrl: defaultImageUrl,
  } = clientState.defaultSeo;

  const seoTitle = computed(() => (unref(options).title || defaultTitle) ?? '');
  const seoDescription = computed(
    () => (unref(options).description || defaultDescription) ?? ''
  );
  const seoImageUrl = computed(
    () => (unref(options).imageUrl || defaultImageUrl) ?? ''
  );

  const xNetwork = clientState.socialNetworks.find(
    socialNetwork =>
      socialNetwork.name === 'twitter' || socialNetwork.name === 'x'
  );

  const xUsername = xNetwork?.url?.split('/').pop();

  useClientTitle(seoTitle);

  useHead({
    meta: [
      { name: 'description', content: seoDescription },
      // open graph protocol
      { property: 'og:title', content: seoTitle },
      { property: 'og:description', content: seoDescription },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: url },
      { property: 'og:locale', content: locale.value },
      // i18n og metas
      ...clientState.locales
        .filter(clientLocale => clientLocale !== locale.value)
        .map(locale => ({
          property: 'og:locale:alternate',
          content: locale,
          // These guys need an id otherwise they cannot be multiple
          id: 'og-alt-' + locale,
        })),
      // Twitter
      { name: 'twitter:card', content: 'summary_large_image' },
      ...(xUsername
        ? [{ name: 'twitter:site', content: `@${xUsername}` }]
        : []),
      { name: 'twitter:title', content: seoTitle },
      { name: 'twitter:description', content: seoDescription },
    ],
  });

  if (seoImageUrl.value) {
    const absoluteImageUrl = computed(() =>
      isExternalUrl(seoImageUrl.value)
        ? seoImageUrl.value
        : withQuery(joinURL(origin, seoImageUrl.value), { v: version })
    );

    useHead(
      computed(() => ({
        meta: [
          { property: 'og:image', content: absoluteImageUrl.value },
          { name: 'twitter:image:src', content: absoluteImageUrl.value },
        ],
      }))
    );
  }
}
