import { navigateTo } from '@/lib/core/router';
import { createLogger } from '@/lib/core/logger';

import type { RouteLocationNormalized } from 'vue-router';

/**
 * Route access guard. Handles navigation access checks, and redirects when checks are failing
 * @param to - Requested route by the user
 * @param from - Origin route of the user
 */
export function checkAccountSettingsAccess(
  to: RouteLocationNormalized
): ReturnType<typeof navigateTo> {
  const logger = createLogger('access.account-settings');
  const toName = String(to.name);

  // Check access only applies to account settings routes
  if (!/^account-settings/.test(toName)) return;

  logger.debug(
    `Account settings route asked. Checking account settings access...`
  );

  const authStore = useAuthStore();

  // User is not logged in
  if (!authStore.isLoggedIn && !/^account-settings-auth/.test(toName)) {
    logger.debug(
      `User is not logged in. Redirecting from ${toName} to account-settings-auth.`
    );
    return navigateTo({
      name: 'account-settings-auth',
    });
  }

  switch (true) {
    case toName === 'account-settings-auth':
      // This condition covers a use case which is not supposed to happen in real life, as 'loginId' is set only on 'account-settings-auth' route
      // and navigation from 'account-settings-auth-confirmation' to 'account-settings-auth' clears the 'loginId' field
      if (authStore.loginId) {
        logger.debug(
          `User has a login id. Redirecting from ${toName} to account-settings-auth-confirmation.`
        );
        return navigateTo({
          name: 'account-settings-auth-confirmation',
        });
      }

      if (authStore.isLoggedIn) {
        logger.debug(
          `User is logged in. Redirecting from ${toName} to account-settings.`
        );
        return navigateTo({ name: 'account-settings' });
      }
      break;

    case toName === 'account-settings-auth-confirmation':
      if (!authStore.loginId) {
        logger.debug(
          `User has no login id. Redirecting from ${toName} to account-settings-auth.`
        );
        return navigateTo({ name: 'account-settings-auth' });
      }

      if (authStore.isLoggedIn) {
        logger.debug(
          `User is logged in. Redirecting from ${toName} to account-settings.`
        );
        return navigateTo({ name: 'account-settings' });
      }
      break;
  }
  logger.debug(`No redirection needed.`);
  return;
}
